//==========================================================================================
//
//  Navigation Search
//
//==========================================================================================

//==========================================================================================
//  Base Styles
//==========================================================================================
.main-navbar__search {
    width: 40px;
    height: 40px;
    margin-right: 5px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    cursor: pointer;
    position: relative;
    order: -1;
}

.main-navbar__search-input {
    @include typography(typo-28, bold, $root-font-family-serif);
    color: color(axa-blue);
    position: absolute;
    top: 0;
    right: 50px;
    height: 100%;
    width: 0;
    border-style: none;
    max-width: 768px;
    padding: 0;
    outline: none;
    transition: 0.3s width ease;
}

.main-navbar__search-trigger {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    // margin-top: -5px;
}

.main-navbar__search-icon {
    color: color(burnt-sienna);
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin: 0 auto;
    display: block;
    opacity: 1;
}

.main-navbar__search-icon-close {
    position: relative;
    width: 25px;
    height: 25px;
    cursor: pointer;
    margin: 0 auto;
    display: block;
    position: absolute;
    opacity: 0;
    pointer-events: none;

    &:before, &:after {
        position: absolute;
        content: "";
        display: block;
        background:color(burnt-sienna);
        width: 100%;
        height: 2px;
        transition: transform 0.3s ease;
    }

    &:before {
        transform: rotate3d(0, 0, 1, 45deg) translateY(-50%);
        transform-origin: 50% 50%;
        top: 50%;
    }
    &:after {
        transform: rotate3d(0, 0, 1, -45deg) translateY(-50%);
        box-shadow: 0 0 0 2px #ffffff;
        transform-origin: 50% 50%;
        top: 50%;
    }
}


//==========================================================================================
//  Modifiers
//==========================================================================================
//==========================================================================================
// Search Box is open
// applies styles to support the open search box
//==========================================================================================
.main-navbar__search-input[aria-hidden="true"] {
    /* Used to override default display none on aria hidden
    as breaks animation */
    display: block !important;
}

.main-navbar__search-input[aria-hidden="false"] {
    width: calc(100vw - 90px);
    max-width: 1140px;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 10px;
    border: solid 1px #ccc;
}

//==========================================================================================
// Search Box is open
// updates menu styling when search is open
//==========================================================================================
[data-menu-search-open] {
    .mega-menu__list {
        opacity: 0;
        pointer-events: none;
    }

    .mega-menu__active {
        /* Uses important to override js opacity style */
        opacity: 0 !important;
    }

    .main-navbar__logo {
        opacity: 0;
    }

    .main-navbar__search {
        position: absolute;
        top: 15px;
        right: 0;
    }

    .main-navbar__search-trigger {
        position: absolute;
        right: 13px;
        top:0;
    }

    .main-navbar__mobile-trigger {
        display: none;
    }

    .main-navbar__spacer {
        opacity: 0;
    }

    .main-navbar__search-icon {
        display: none;
    }

    .main-navbar__search-icon-close {
        position: relative;
        top: 0;
        opacity: 1;
        pointer-events: auto;
    }

    .mega-menu__tracker {
        /* Force hidden tracker element to fix overflow issue */
        opacity: 0 !important;
    }
}

@include mq($from: screen480) {
    .main-navbar__search-input[aria-hidden="false"] {
        width: calc(100vw - 100px);
    }
}

/* Media Queries */
@include mq($from: screen1140) {
    .main-navbar__search {
        order: 0;
    }

    .main-navbar__search-trigger {
        margin-top: -5px;
    }

    [data-menu-search-open] {
        .main-navbar__logo {
            opacity: 1;
        }

        .main-navbar__search {
            position: relative;
            top: 0;
        }

        .main-navbar__search-trigger {
            position: relative;
            right: 0;
        }
    }

    .main-navbar__search-input[aria-hidden="false"] {
        width: 60vw;
        max-width: 550px;
        margin-right: 0;
    }
}