//==========================================================================================
//
//  Hero Banner
//
//==========================================================================================

//==========================================================================================
//  Base Styles
//==========================================================================================

.hero-banner {
    width: 100%;
    height: auto;
    min-height: $hero-banner-min-height;
    background-position: center;
    background-size: cover;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative
}

.hero-banner__mask {
    background-color: rgba(0,0,0,.2);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    min-height: $hero-banner-min-height;
    z-index: 1
}

.hero-banner__img {
    min-height: $hero-banner-min-height;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    position: absolute;
    font-family: 'object-fit: cover;'
}

.hero-banner__container {
    width: 100%;
    height: auto;
    max-width: 1200px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.hero-banner__content {
    width: 100%;
    height: auto;
    margin: 80px 0;
    padding: 20px 40px;
    max-width: $content-max-width-960;
    text-align: center
}

.hero-banner__btn-wrapper {
    margin-top: 30px;
    text-align: inherit;
    width: 100%;
    height: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.hero-banner__btn-wrapper a {
    font-size: 16px;
    font-weight: 700
}

.hero-banner__btn {
    margin: 10px 10px;
}

//==========================================================================================
//  Modifiers
//==========================================================================================
//==========================================================================================
// Letterbox
// Applies a letterbox style to the hero
//==========================================================================================
.hero-banner--letterbox {
    min-height: $hero-banner-letterbox-min-height;

    .hero-banner__mask {
        min-height: $hero-banner-letterbox-min-height; // IE11 vertical stretch fix
    }
}
//==========================================================================================
// Left
// Applies a left aligned style to the hero
//==========================================================================================
.hero-banner--left {
    /* Remaining Code Defined In Media Query */
}
//==========================================================================================
// Right
// Applies a right aligned style to the hero
//==========================================================================================
.hero-banner--right {
    /* Remaining Code Defined In Media Query */
}
//==========================================================================================
// Slim
// Applies a slim style to the hero
//==========================================================================================
.hero-banner--slim {
    min-height: $hero-banner-slim-min-height;

    .hero-banner__mask {
        min-height: $hero-banner-slim-min-height; // IE11 vertical stretch fix
    }

    .hero-banner__btn-wrapper {
        margin-top: 0;
    }
}
//==========================================================================================
// Large
// Only applied to the hero carousel
//==========================================================================================
.hero-banner--large {
    min-height: 0;

    .hero-banner__mask {
        min-height: 0; // IE11 vertical stretch fix
    }

    .hero-banner__container {
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
}
//==========================================================================================
//  Media Queries
//==========================================================================================
@include mq($from: screen768) {

    .hero-banner__text {
        max-width: 100%;
    }

    .hero-banner--large {
        min-height: $hero-banner-large-min-height;

        .hero-banner__mask {
            min-height: $hero-banner-large-min-height; // IE11 vertical stretch fix
        }

        .hero-banner__container {
            padding-top: 150px;
        }
    }
    /* Left align modifier */
    .hero-banner--left {

        .hero-banner__container {
            -webkit-box-pack: start;
            -webkit-justify-content: flex-start;
            -ms-flex-pack: start;
            justify-content: flex-start;
        }

        .hero-banner__content {
            text-align: left;
            max-width: $content-max-width-500;
        }

        .hero-banner__btn-wrapper {
            -webkit-box-pack: start;
            -webkit-justify-content: flex-start;
            -ms-flex-pack: start;
            justify-content: flex-start;
        }

        .hero-banner__btn, .btn {
            margin: 0 20px 0 0;
        }

        .hero-banner__subtitle {
            &:after {
                margin-left: 0;
            }
        }
    }
    /* Right align modifier */
    .hero-banner--right {
        .hero-banner__container {
            -webkit-box-pack: end;
            -webkit-justify-content: flex-end;
            -ms-flex-pack: end;
            justify-content: flex-end;
        }

        .hero-banner__content {
            text-align: left;
            max-width: $content-max-width-500;
        }

        .hero-banner__btn-wrapper {
            -webkit-box-pack: start;
            -webkit-justify-content: flex-start;
            -ms-flex-pack: start;
            justify-content: flex-start;
        }

        .hero-banner__btn {
            margin: 0 20px 0 0;
        }

        .hero-banner__subtitle {
            &:after {
                margin-left: 0;
            }
        }
    }
}