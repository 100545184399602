//==========================================================================================
//
//  Accordion
//
//==========================================================================================

//==========================================================================================
//  Base Styles
//==========================================================================================
.accordion-trigger{
    margin: 0;
    padding: 0;
    border-top: 1px solid color(axa-silver);
    &:first-of-type{
        border-top: none;
    }
}

.accordion-trigger__icon{
    // Remove all button styles
    margin: 0;
    padding: 0;
    min-height: auto;
    text-transform: none;
    background: none;
    color: initial;
    &:hover, &:focus{
        color: initial;
    }
    &:before, &:after{
        display: none;
    }

    // IE11 button push fix:
    // Add position relative to all the immediate children
    & > * {
        position: relative;
    }
}
.accordion-trigger__label{
    padding:20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;


}

.accordion-trigger__label:hover{
    cursor:pointer;
}


.accordion-trigger__icon{
    height:25px;
    width: 25px;
    margin-right: 2px; //outline space
    overflow: hidden;
}

.accordion-trigger__icon-image{
    transition: transform 0.3s ease-in-out;
    width: 100%;
    height: 100%;
    transform: rotate(180deg);
}

.accordion-trigger__label[aria-expanded="true"]{
    .accordion-trigger__icon-image{
        transform: rotate(0deg);
    }
}

.accordion-view{
    padding: 0;
    margin:0;

    //Accordion Animations
    transition: max-height 1.2s ease-in-out, visibility 0s ease-in-out;
    overflow: hidden;

    *:not(:first-child){
        margin-top: 30px ;
    }
}
.accordion-view[aria-hidden="true"]{
    max-height: 0;
    visibility: hidden;
    transition: max-height 1.2s cubic-bezier(0, 1, 0.3, 1), visibility 0s ease-in-out 1.2s; //faster transition on the close
}

.accordion-view[aria-hidden="false"]{
    max-height: 9999px;
    visibility: visible;
}

.accordion-view__cta{
    margin-top: 20px;
    margin-bottom: 30px;
}

//==========================================================================================
//  Media Queries
//==========================================================================================
@include mq($from: screen768) {
    .accordion-view{

        *:not(:first-child){
            margin-top: 40px ;
        }

    }

    .accordion-view__cta{
        margin-bottom: 40px;
    }

    .accordion-trigger__icon{
        height:30px;
        width: 30px;
    }
}


@include mq($from: screen992) {
    .accordion-trigger__label{
        padding-top: 30px;
        padding-bottom: 30px;
    }
}