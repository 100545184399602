//==========================================================================================
//
//  Article Blocks
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================
.article-blocks {
    padding: $component-padding-sm;
    position: relative;
}

.article-blocks__container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: $standard-max-width;
    margin: 0 auto;
}

.article-blocks__card-wrapper{
    display: flex;
    margin-bottom: 30px;
}

.article-blocks__full-click {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 0;
}

.article-blocks__card {
    border-style:solid;
    border-width:3px;
    padding:60px 30px;
    width:100%;
}

.article-blocks__card-tag {
    @include typography(typo-13, 600, $root-font-family);
    display:inline-block;
    border:solid;
    border-width:0 0 2px 0;
    padding-bottom:5px;
    margin-bottom:30px;
}

.article-blocks__card-title {
    margin-bottom:20px;
}

.article-blocks__card-product {
    margin:45px 0 15px 0;
}

.article-blocks__card-desc {
    margin-bottom:10px;
}

.article-blocks__inner{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.article-blocks__campaign{
    width:100%;
    position:relative;
}

.article-blocks__campaign-copy{
    padding:20px;
}

.article-blocks__results {
    @include typography(typo-14, 600, $root-font-family);
    display:block;
    width:100%;
    max-width: $standard-max-width;
    margin: 0 auto 30px auto;

    span {
        display:inline-block;

        & + span {
            &:before{
                margin:0 10px;
                content:"|";
            }
        }
    }
}

.article-blocks__more{
    display:block;
    margin-top:25px;
    text-align:center;
    width:100%;
}

.article-blocks__cta-wrapper {
    margin-top:30px;
}

//==========================================================================================
// Modifiers
//==========================================================================================
//==========================================================================================
// Small Column
// Sets width of a small column
//==========================================================================================
.article-blocks__card-wrapper--small {
    width:100%;
}

//==========================================================================================
// Large Column
// Sets width of a large column
//==========================================================================================
.article-blocks__card-wrapper--small {
    width:100%;
    border-style:none;
}

//==========================================================================================
// Article
// Sets border colour of article cards as well as the colour of the tag heading
//==========================================================================================
.article-blocks__card--article {
    border-color:color(burnt-sienna);

    .article-blocks__card-tag {
        color:color(burnt-sienna);
    }
}

//==========================================================================================
// Background
// Class for changing the background image and maintaining aspect ratio
//==========================================================================================
.article-blocks__campaign--background{
    background-size:cover;
    background-position: center;
    // On mobile we need to maintain an aspect ratio
    padding-top:52.56%;
}

//==========================================================================================
// Factsheets
// Sets border colour of factsheet cards as well as the colour of the tag heading
//==========================================================================================
.article-blocks__card--factsheet {
    border-color:color(logan);

    .article-blocks__card-tag {
        color:color(logan);
    }
}

//==========================================================================================
// Case Studies
// Sets border colour of case study cards as well as the colour of the tag heading
//==========================================================================================
.article-blocks__card--case {
    border-color:color(pacific);

    .article-blocks__card-tag {
        color:color(pacific);
    }
}

//==========================================================================================
// Recipe
// Sets border colour of recipe cards as well as the colour of the tag heading
//==========================================================================================
.article-blocks__card--recipe {
    border-color:color(apache);

    .article-blocks__card-tag {
        color:color(apache);
    }
}

//==========================================================================================
// Expert Help
// Sets border colour of Expert help cards as well as the colour of the tag heading
//==========================================================================================
.article-blocks__card--help {
    border-color:color(axa-teal);

    .article-blocks__card-tag {
        color:color(axa-teal);
    }
}

//==========================================================================================
// Media Queries
//==========================================================================================
@include mq($from: screen768) {
    .article-blocks__card-wrapper--small {
        width:calc(50% - 10px);

    }

    .article-blocks__card-wrapper--large {
        width:100%;

    }


    .article-blocks__campaign-copy{
        padding: 35px 60px;
    }

    .article-blocks__inner {
        display: flex;
        flex-direction: row;
    }

    .article-blocks__card-desc {
        margin-bottom:25px;
    }


    .article-blocks__campaign--background{
        background-size:cover;
        background-position: center;
        // On mobile we need to maintain an aspect ratio
        padding-top:0;
    }
}

@include mq($from: screen960) {
    .article-blocks {
        padding: $component-padding-lg;
        padding-top: 40px;
    }

    .article-blocks__card-wrapper--small {
        width:calc(33.3333% - 20px);

    }
}