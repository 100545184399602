//==========================================================================================
//
//  Product List
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================

/* Main wrapper for product list and static promo components */
.product-wrapper{
    padding: $component-padding-sm;
}

.product-wrapper__row{
    display: flex;
    flex-direction: column;
    max-width: $standard-max-width;
    margin: 0 auto;

}

.product-wrapper__col-large{
    width:100%;
}

.product-wrapper__col-small{
    width:100%;
}
/* / Main wrapper for product list and static promo components */

.product-list {
    margin: 30px auto;

    .anchor {
        margin: 30px 0;
    }
}

.product-list__row{
    display: flex;
    flex-direction: column;
    padding-bottom:20px;
}

.product-list__col-small{
    width: 100%;
    display:inherit;
    align-items: flex-start;
}

.product-list__col-large{
    width: 100%;
}

.product-list__icon{
    width: 60px;
    margin-bottom: 30px;
}

.product-list__item-desc {
    padding:0;
}


//==========================================================================================
//  Media Queries
//==========================================================================================
@include mq($from: screen600) {

    .product-list {
        padding: 0 0;
    }

    .product-list__row{
        flex-direction: row;
    }

    .product-list__col-small{
        width:15%;
    }

    .product-list__col-large{
        width:85%;
    }

    .product-list__item-desc {
        padding:0 30px;
    }

    .product-list__icon{
        width: 100%;
        margin-bottom: 0;
    }


}

@include mq($from: screen768) {

    /* Main wrapper for product list and static promo components */
    .product-list{
        padding: 30px 0;

        margin: 0 auto;

        &:first-child{
            padding-top: 0;
            margin-top:0;
        }
    }

    .product-list__row{
        display: flex;
        flex-direction: row;
        padding-bottom:0;
    }

    .product-list__col-small{
        width:15%;
        display:inherit;
        align-items: flex-start;
    }

    .product-list__col-large{
        width:85%;
    }

    .product-list__item-desc{
        padding:0 30px;

    }
}

@include mq($from: screen960) {
    .product-wrapper{
        padding: $component-padding-lg;
    }
}

@include mq($from: screen1000) {
    .product-wrapper__row{
        flex-direction: row;
    }

    .product-wrapper__col-large{
        width:65%;
        padding-right:60px;
    }

    .product-wrapper__col-small{
        width:35%;
    }
}
