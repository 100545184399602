//==========================================================================================
//
//  Accordion Group
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================
.accordion-group{
    padding: $component-padding-sm;

    &:not(:last-of-type){
        padding-bottom: 0;
    }
}

.accordion-group__container{
    max-width: $standard-max-width;
    margin: 0 auto;
}

.accordion-group__title{
    padding-bottom: 0;
    margin: 0;
}

.accordion-group__search {
    margin: 10px 0;
}

.accordion-group__search-input {
    border: solid 1px #ccc;
    padding: 5px;
    width:100%;
}

.accordion-group__search-highlight {
    background-color:yellow;
}

    .accordion-group__search-item-hide {
        display: none;
    }

    .accordion-group__search-item-show {
        display: block;
    }
    //==========================================================================================
    //  Media Queries
    //==========================================================================================
    @include mq($from: screen960) {
        .accordion-group {
            padding: $component-padding-lg;
        }

        .accordion-group__search-input {
            width: 40%;
        }
    }