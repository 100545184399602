//==========================================================================================
//
//  Search Reference
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================
.search-reference{
    display:block;
    padding:0 20px;
    margin: 40px auto 0 auto;
    width: 100%;
    height: auto;
}

.search-reference__container {
    width: 100%;
    height: auto;
    margin: 0 auto;
    max-width: $standard-max-width;
}

//==========================================================================================
//  Media Queries
//==========================================================================================
@include mq($from: screen960) {
    .search-reference{
        margin: 40px auto 0 auto;
        padding: 0 40px;
    }
}