//==========================================================================================
//
//  Hero Carousel Component
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================

.hero-carousel {
    width: 100%;
    height: auto;
    position:relative;
    display: block;
    overflow: hidden;

    .hero-banner__content {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

.hero-carousel__wrapper {
    width: 100%;
    height: auto;
    display: block;
}

.hero-carousel__container{
    width:100%;
    display: block;
    height: auto;
    overflow: hidden !important;

    &:after {
        display: block;
        content: "";
        clear: both;
    }
}

.hero-carousel__item {
    width: 100%;
    height: auto;
    display: block;

    .hero-banner {
        min-height: $hero-banner-carousel-item-min-height;
        height: 100%;
    }

    .hero-banner__mask {
        min-height: $hero-banner-carousel-item-min-height; // IE11 vertical stretch fix
    }

    .hero-banner__content {
        margin: 0 0 80px 0;
    }

}

/* Pagination */
.hero-carousel__pagination{
    position:absolute;
    bottom:0;
    left: 0;
    width:100%;
    padding:20px 40px;
    background:linear-gradient(
    rgba(0,0,0,0),
    rgba(0,0,0,0.057)19%,
    rgba(0,0,0,0.102)34%,
    rgba(0,0,0,0.141)47%,
    rgba(0,0,0,0.1695)56.5%,
    rgba(0,0,0,0.195)65%,
    rgba(0,0,0,0.219)73%,
    rgba(0,0,0,0.2406)80.2%,
    rgba(0,0,0,0.2583)86.1%,
    rgba(0,0,0,0.273)91%,
    rgba(0,0,0,0.2856)95.2%,
    rgba(0,0,0,0.2946)98.2%,
    rgba(0,0,0,0.3));
    display: block;
}

.hero-carousel__pagination-wrapper{
    width: 100%;
    height: auto;
    max-width: $standard-max-width;
    margin: 0 auto;
    display:flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
}

.hero-carousel__pagination-item{
    display:flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    width:calc(33% - 15px);
    padding:10px 0;

    &:hover{
        cursor:pointer;
    }
}

.hero-carousel__pagination-image-container{
    width:40%;
    display:none;
    background-size:cover;
    background-position: center center;
}

.hero-carousel__pagination-image{
    background-size:cover;
    background-position: center center;
    &:after{
        content:"";
        display: block;
        height:auto;
        padding-top:56.25%;
    }
}

.hero-carousel__pagination-summary-wrapper{
    width:100%;
    display:block;
}

.hero-carousel__pagination-summary{
    position:relative;
    width:100%;
    height:100%;
}

.hero-carousel__pagination-progress-container{
    background:rgba(255, 255, 255, 0.5);
    display:block;
    height:2px;
    margin-bottom:10px;
    border-radius:3px;
    position: absolute;
    bottom:-10px;
    width:100%;
}

.hero-carousel__pagination-progress{
    display:block;
    width:0;
    height:2px;
    background:color(plain-white);
    border-radius:3px;
}

.hero-carousel__pagination-title{
    color: color(plain-white);
    @include typography(typo-18, normal, $root-font-family);
    margin-bottom:0;
    display:none;
}

.hero-carousel__pagination-mobile-title{
    color: color(plain-white);
    @include typography(typo-16, normal, $root-font-family);
    margin-bottom:10px;
    display:block;
}

//==========================================================================================
//  Media Queries
//==========================================================================================
@include mq($from: screen1000) {
    .hero-carousel__pagination-title{
        display:block;
    }

    .hero-carousel__pagination-mobile-title{
        display:none;
    }

    .hero-carousel__pagination-image-container{
        width:40%;
        display:block;
    }

    .hero-carousel__pagination-summary-wrapper{
        width:60%;
    }

    .hero-carousel__pagination-summary{
        padding-left:20px;
    }


    .hero-carousel__pagination-item{
        width:calc(33% - 20px);
    }

    .hero-carousel__pagination-progress-container{
        position: initial;
        margin-top:10px;
    }

    .hero-carousel__pagination{
        padding-bottom:10px;
    }
}

@include mq($from: screen1200){
    .hero-carousel__pagination-item{
        width:calc(33% - 50px);
    }

    .hero-carousel__pagination{
        padding-bottom:20px;
    }
}