﻿//==========================================================================================
//
//  Resets
//
//==========================================================================================

*,
*:before,
*:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    -webkit-text-size-adjust: none;
    text-rendering: optimizeLegibility;
    text-shadow: 0 0 1px rgba(0,0,0,0.1);
    color: #333;
}

img,
iframe {
    max-width: 100%;
}

svg {
    display: inline-block;
    font-size: 0;
    line-height: 0;
    pointer-events: none;
}

use {
    pointer-events: none;
}

button {
    cursor: pointer;
    appearance: none;
    border: none;
    border-radius: 0;
    background: none;
}

figure {
    margin: 0;
}

input, select {
    appearance: none;
}

/* Remove controls from Firefox */
input[type=number] {
    appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  appearance: none;
  margin: 0; 
}

select {
    background-color: transparent;
    border-radius: 0;
}

select::-ms-expand {
    display: none;
  }

input::-ms-clear {
    display: none;
}