//==========================================================================================
//
//  news landing
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================

.search-filter {
    padding: 20px 24px 36px 24px;
    /*margin: 0 0 30px 0;*/
    height: fit-content;
    background-color: color(mercury);
    color: color(color-black);

    ul {
        list-style-type: none;
    }

    input[type="checkbox"] {
        appearance: checkbox;
        -webkit-appearance: checkbox;
    }


    input[type=checkbox].css-checkbox {
        position: absolute;
        z-index: -1000;
        left: -1000px;
        margin: -1px;
        overflow: hidden;
        clip: rect(0 0 0 0);
        height: 0;
        width: 0;
        padding: 0;
        border: 0;
        display: none;
    }

    .news-chk-label {
        margin-left: 5px;
    }

    input[type="checkbox"], label.news-chk-label {
        cursor: pointer;
    }

    .news-level1-label {
        font-weight: 700;
    }
}

.news-bx-shadow {
    box-shadow: 0 2px 5px 0 RGB((191 191 191) / 40%);
    transition: all .2s;
}

    .news-article-panel {
        width: 100%;
        height: auto;
        clear: both;
        position: relative;
        overflow: hidden;
        /*padding-left:20px;
    padding-right:20px;
    padding-bottom: 20px;
    margin-bottom: 20px;*/
        padding: 20px;
    }

.news-article-panel p {
    padding: 5px 0px;
}

    .news-article-panel a {
        text-decoration: none;
    }


    .news-article-panel-image {
        width: 100%;
        height: auto;
        display: block;
        margin-bottom: 20px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        &:after {
            display: block;
            padding-top: 56.25%;
            content: "";
        }
    }


.news-article-panel-content {
    width: 100%;
    height: auto;
    display: block;
    max-width: 100%;
    color: color(dark-grey);
    padding-bottom: 20px;
}

.news-article-panel-category {
    @include typography(typo-14, normal, $root-font-family);
}

.news-article-panel-title {
    /*color: color(dark-grey);*/
    padding: 5px 0px;
}

    .news-article-panel-link {
        @include typography(anchor, bold, $root-font-family);
        display: inline-block;
        text-decoration: none;
        transition: color 0.3s;
        text-transform: uppercase;
        color: color(dark-grey);
        padding-top:25px;

        &:hover, &:focus {
            color: color(axa-grey);
        }
    }

    .article-results-list__pagination__container {
        display: flex;
        margin-bottom: 20px;
    }

    .article-results-list__pagination {
        margin: auto;
        max-width: 100%;
    }

    .news-article-clear-filters {
        @include typography(anchor, bold, $root-font-family);
        text-transform: uppercase;
        color: color(axa-blue);
        cursor: pointer;
        margin-left: 20px;

        &:hover, &:focus {
            color: color(dark-filter-axa-blue);
        }
    }

    //==========================================================================================
    //  Media Queries
    //==========================================================================================
    @include mq($from: screen720) {
    }

    @include mq($from: screen960) {
    }

    @include mq($from: screen992) {

        .news-article-panel {
            padding-top: 20px;
            padding-left: 20px;
            padding-right: 0px;
            padding-bottom: 0px;
            margin-bottom: 20px;
        }

        .news-article-panel-content {
            color: color(dark-grey);
            padding-right: 60px;
        }

        .news-article-panel-title {
            /*color: color(dark-grey);*/
        }

        .news-article-panel-link {
            color: color(dark-grey);
        }

        .search-filter {
            max-width: 25%;
        }

        .search-filter li {
            display: flex;
            flex-direction: row;
        }

        .search-filter {
            input[type="checkbox"] {
                margin-right: 0.3em;
                margin-top: 0.4em;
            }
        }
    }