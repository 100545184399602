//==========================================================================================
//
//  Login Overlay
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================
.login-overlay {
    width:100%;
    position: relative;
    height:auto;
    display:block;
    background: color(plain-white);
    z-index: 0;
}

.login-overlay__container {
    overflow: hidden;
    position: relative;
    margin: 0 auto;
}

.login-overlay[aria-expanded="false"] {
    pointer-events: none;

    .login-overlay__container {
        max-height: 0;
        transition: max-height 1.2s cubic-bezier(0, 1, 0.3, 1); //faster transition on the close
    }
}

.login-overlay[aria-expanded="true"] {
    pointer-events: all;
    border-bottom: 1px color(mercury) solid;
    border-top: 1px color(mercury) solid;

    .login-overlay__container{
        max-height: 9999px;
        transition: max-height 1.2s ease-in-out;
    }
}

.login-overlay__close {
    // Remove all button styles
    margin: 0;
    padding: 0;
    min-width: 0;
    min-height: auto;
    text-transform: none;
    background: none;
    color: initial;
    &:hover, &:focus {
        color: initial;
    }
    &:before, &:after {
        display: none;
    }

    position:absolute;
    right: 45px;
    top: 55px;
    height: 16px;
    width: 16px;
    font-size: 10px;

    .login-overlay__close-icon {
        width: 100%;
    }
}

//==========================================================================================
//  Media Queries
//==========================================================================================
@include mq($from: screen768) {
    .login-overlay__close {
        top:85px;
    }
}

@include mq($from: screen1200) {
    .login-overlay__container {
        max-width: $standard-max-width;
        margin: 0 auto;
    }
}