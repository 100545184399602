//==========================================================================================
//
//  Search Bar
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================
.search-bar{
    margin: 0 auto;
    position: relative;
    max-width: $content-max-width-450;
    padding-top:10px;
    padding-bottom:10px;
}

.search-bar__label{
    // Only show label to screenreaders
    display: block;
    max-height: 0;
    overflow: hidden;
}

.search-bar__container{
    display: flex;
    flex-direction: row;
    width: 100%;
    border: 1px solid color(axa-silver);
    background: color(plain-white);
    overflow: hidden;
}

.search-bar__input{
    @include typography(typo-18, normal, $root-font-family);
    border: 0;
    min-width: 10px;
    padding: 15px 10px;
    flex-grow: 1;
    display: block;

    &:focus{
        outline: none;
    }

}

.search-bar__submit{
    background:white;
    width: 40px;
    min-width: 40px;
    padding: 15px 10px;

    &:hover, &:focus{
        outline: none;

        .search-bar__submit-icon{
            opacity: 1;
        }

    }
}

.search-bar__submit-icon{
    opacity: 0.6;
    transition: opacity 0.3s ease-in-out;
    height: 20px;
    width: 20px;
    fill: color(medium-grey);
    color: color(medium-grey);
}

.search-bar__autocomplete-suggestions{
    display: block;
    background: white;
    width: 100%;
    text-align: left;
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    left: 0;
    top: 53px; //exact pixels from top for IE
    overflow: hidden;
    border: 1px solid color(axa-silver);
    border-top: none;
}

.search-bar__autocomplete-suggestions[aria-hidden="true"]{
    display: none;
}
.search-bar__autocomplete-suggestions[aria-hidden="false"]{
    display: block;
}

.search-bar__autocomplete-suggestion{
    @include typography(typo-18, normal, $root-font-family);
    padding: 15px;
    margin: 0;
    padding-left: 50px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover, &:focus{
        outline: none;
        background: color(mercury);
    }
}
//==========================================================================================
//  Media Queries
//==========================================================================================
@include mq($from: screen768) {
    .search-bar{
        padding-left: 40px;
        padding-right: 40px;
        max-width: $content-max-width-600;
    }

    .search-bar__submit{
        width: 60px;
        min-width: 60px;
        padding: 15px;
    }

    .search-bar__input{
        padding: 15px;
    }

    .search-bar__autocomplete-suggestions{
        width: calc(100% - 80px); //100% of the container width minus 40px either side to account for parent's padding
        margin-left: 40px;
        top: 62px; //exact pixels from top for IE
    }

    .search-bar__autocomplete-suggestion{
        padding-left: 75px;
    }

    .search-bar__submit-icon{
        height: 25px;
        width: 25px;
    }

}