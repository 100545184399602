//==========================================================================================
//
//  Icon List Component
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================

.icon-list {
    display:block;
    margin:0 auto;
    overflow: hidden;
    padding: $section-vpadding-sm;
}

.icon-list__wrapper {
    width: 100%;
    height: auto;
    display: block;
    margin: 20px 0;
    padding: 0 20px;
}

.icon-list__container {
    width: 100%;
    margin:0 auto;
    height: auto;
    display: block;
    position: relative;
    max-width: $standard-max-width;
}


.icon-list__item {
    display: block;
    padding: 0 40px;
    margin: 20px 0 0 0;
}

.icon-list__item-image {
    display:block;
    margin:0 auto 20px auto;
    width:100px;
    height:100px;
}

.icon-list__cta {
    display:block;
    text-align:center;
    margin: auto;
    margin-top:40px;
    max-width: $standard-max-width;

    button{
        display: inline-block;
        margin: $btn-margins;
    }
}

.icon-list__pagination{
    text-align:center;
    margin:20px 0;
    padding-top:40px;
}

.icon-list__pagination-item{

    &:first-child, &:last-child {
        display: none;
    }
}




//==========================================================================================
//  Media Queries
//==========================================================================================

@include mq($from: screen600) {
    .icon-list__wrapper {
        padding: 0 40px;
    }
    .icon-list__container {
        overflow: visible !important;
    }


    .icon-list__cta+.icon-list__cta {
        margin-left: $btn-margins;
    }

}

@include mq($from: screen768) {
    .icon-list {
        display: block;
        padding: $component-padding-lg;
    }

    .icon-list__wrapper {
        justify-content: space-between;
        width: 100%;
    }

    .icon-list__item {
        padding: 0 10px 0 20px;
    }
}

@include mq($from: screen960) {

    .icon-list__container {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: flex-start;
    }
    .icon-list__item {
        width:33.3%;
        padding:20px 35px;
    }

    .icon-list__pagination {
        display: none;
    }

    .icon-list__cta {
        margin-top:30px;
    }
}
