//==========================================================================================
//
//  Document Library
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================
.document-library-block {
    padding: 40px 20px;
}

.document-library-block__container {
    max-width: 1200px;
    margin: 0 auto;
}

.document-library-block__header {
    text-align: center;
    padding-bottom: 20px;
    max-width: 768px;
    margin: 0 auto;
}

.document-library-block-heading {
    margin-left: 40px;
}

.document-library-block-header__title {
    margin: 0;
}

.document-library-block-header__content {
    padding-top: 10px;
}

.document-library-block-list {
    padding: 0;
    margin: 0;
}

.document-library-block-list__item {
    list-style: none;
    margin: 0;
    border-bottom: 1px solid color(mercury);
}

.document-library-block-list__item:last-of-type {
    border: 0;
}

.document-library-block__footer {
    padding-top: 20px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.document-library-block__cta {
    margin: 0 auto;
}

.document-library-block__pagination {
    margin: auto;
    max-width: 100%;
}

.document-library-block-download-icon {
    fill: color(axa-blue);
    height: 18px;
    width: 18px;
    position: absolute;
    margin-top: 0;
    margin-left: 5px;
}

.document-library-block-summary {
	-webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

.document-library-block-summary:focus, .document-library-block-summary:hover {
    background-color: rgba(95,95,95,.15);
    outline: 0;
}

.document-library-block-summary__container {
	width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    min-height: 110px;
    padding: 20px 40px;
}

.document-library-block-summary__date {
    font-family: "SourceSansPro",Arial,sans-serif;
    font-weight: 400;
    font-size: .75rem;
    line-height: 1.5em;
    letter-spacing: .02em;
    text-transform: uppercase;
}

@media (min-width:48em) {
    .document-library-block-summary__date {
        font-size: .875rem;
        line-height: 1.21em;
        letter-spacing: .02em;
    }
}

@media (min-width:62em) {
    .document-library-block-summary__date {
        font-size: .875rem;
        line-height: 1.21em;
        letter-spacing: .02em;
    }
}

@media (min-width:75em) {
    .document-library-block-summary__date {
        font-size: .875rem;
        line-height: 1.21em;
        letter-spacing: .02em;
    }
}

.document-library-block-summary__time {
    font-family: "SourceSansPro",Arial,sans-serif;
    font-weight: 400;
    font-size: .75rem;
    line-height: 1.5em;
    letter-spacing: .02em;
    text-transform: uppercase;
}

@media (min-width:48em) {
    .document-library-block-summary__time {
        font-size: .875rem;
        line-height: 1.21em;
        letter-spacing: .02em;
    }
}

@media (min-width:62em) {
    .document-library-block-summary__time {
        font-size: .875rem;
        line-height: 1.21em;
        letter-spacing: .02em;
    }
}

@media (min-width:75em) {
    .document-library-block-summary__time {
        font-size: .875rem;
        line-height: 1.21em;
        letter-spacing: .02em;
    }
}

.document-library-block-summary__title {
    margin-bottom: 5px;
}

.document-library-block-summary__description {
    font-family: "SourceSansPro",Arial,sans-serif;
    font-weight: 400;
    font-size: .75rem;
    line-height: 1.5em;
    letter-spacing: .02em;
}

@media (min-width:48em) {
    .document-library-block-summary__description {
        font-size: 1rem;
        line-height: 1.5em;
        letter-spacing: .02em;
    }
}

@media (min-width:62em) {
    .document-library-block-summary__description {
        font-size: 1rem;
        line-height: 1.5em;
        letter-spacing: .02em;
    }
}

@media (min-width:75em) {
    .document-library-block-summary__description {
        font-size: 1rem;
        line-height: 1.5em;
        letter-spacing: .02em;
    }
}

.document-library-block-summary__link {
    font-family: "SourceSansPro",Arial,sans-serif;
    font-weight: 400;
    font-size: .75rem;
    line-height: 1.5em;
    letter-spacing: .02em;
    color: color(axa-blue);
    min-width: 105%;
    font-size: 18px !important;
}

@media (min-width:48em) {
    .document-library-block-summary__link {
        font-size: 1rem;
        line-height: 1.5em;
        letter-spacing: .02em;
    }
}

@media (min-width:62em) {
    .document-library-block-summary__link {
        font-size: 1rem;
        line-height: 1.5em;
        letter-spacing: .02em;
    }
}

@media (min-width:75em) {
    .document-library-block-summary__link {
        font-size: 1rem;
        line-height: 1.5em;
        letter-spacing: .02em;
    }
}

.document-library-block-summary__content a p {
    margin-top: 10px !important;
}

@media (min-width:48em) {
    .document-library-block__cta {
        margin: auto 0 auto auto;
    }

    .document-library-block__header {
        padding-bottom: 40px;
    }

    .document-library-block__footer {
        padding-top: 40px;
    }

    .document-library-block-summary__container {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .document-library-block-summary__date {
        font-family: "SourceSansPro",Arial,sans-serif;
        font-weight: 400;
        font-size: .75rem;
        line-height: 1.5em;
        letter-spacing: .02em;
        width: 20%;
        min-width: 200px;
        padding: 0 30px;
        margin-top: 5px;
        text-transform: none;
    }
}

@media (min-width:48em) and (min-width:48em) {
    .document-library-block-summary__date {
        font-size: 1rem;
        line-height: 1.5em;
        letter-spacing: .02em;
    }
}

@media (min-width:48em) and (min-width:62em) {
    .document-library-block-summary__date {
        font-size: 1rem;
        line-height: 1.5em;
        letter-spacing: .02em;
    }
}

@media (min-width:48em) and (min-width:75em) {
    .document-library-block-summary__date {
        font-size: 1rem;
        line-height: 1.5em;
        letter-spacing: .02em;
    }
}

@media (min-width:48em) {
    .document-library-block-summary__title {
        margin-bottom: 15px;
    }

    .document-library-block-summary__description {
        font-family: "SourceSansPro",Arial,sans-serif;
        font-weight: 400;
        font-size: .75rem;
        line-height: 1.5em;
        letter-spacing: .02em;
    }
}

@media (min-width:48em) and (min-width:48em) {
    .document-library-block-summary__description {
        font-size: .875rem;
        line-height: 1.21em;
        letter-spacing: .02em;
    }
}

@media (min-width:48em) and (min-width:62em) {
    .document-library-block-summary__description {
        font-size: .875rem;
        line-height: 1.21em;
        letter-spacing: .02em;
    }
}

@media (min-width:48em) and (min-width:75em) {
    .document-library-block-summary__description {
        font-size: .875rem;
        line-height: 1.21em;
        letter-spacing: .02em;
    }
}

@media (min-width:48em) {
    .document-library-block-summary__link {
        font-family: "SourceSansPro",Arial,sans-serif;
        font-weight: 400;
        font-size: .75rem;
        line-height: 1.5em;
        letter-spacing: .02em;
    }
}

@media (min-width:48em) and (min-width:48em) {
    .document-library-block-summary__link {
        font-size: .875rem;
        line-height: 1.21em;
        letter-spacing: .02em;
    }
}

@media (min-width:48em) and (min-width:62em) {
    .document-library-block-summary__link {
        font-size: .875rem;
        line-height: 1.21em;
        letter-spacing: .02em;
    }
}

@media (min-width:48em) and (min-width:75em) {
    .document-library-block-summary__link {
        font-size: .875rem;
        line-height: 1.21em;
        letter-spacing: .02em;
    }
}

@media (min-width:48em) {
    .document-library-block-summary:focus .document-library-block-summary__container,
	.document-library-block-summary:hover .document-library-block-summary__container {
        padding-left: 70px;
    }
}

@media (min-width:48em) and (min-width:60em) {
    .document-library-block {
        padding: 80px 40px;
    }
}

.document-library-block-summary__content a {
    text-decoration: none;
}

p.document-library-block-summary__link {
    margin-bottom: 15px;
}