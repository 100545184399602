//==========================================================================================
//
//  Content Blocks Component
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================
.content-blocks {
    width: 100%;
    margin: 0 auto;
    height: auto;
    display: block;
    overflow: hidden;
    padding: $component-padding-sm;
}

.content-blocks__wrapper {
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top:40px;
    max-width: $standard-max-width;
}

.content-blocks__wrapper--tablet-peak {
    @extend .content-blocks__wrapper;
}

.content-blocks__container {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;

    &:after {
        content: "";
        display: block;
        clear: both;
    }
}

.content-blocks__container--no-carousel {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.content-blocks__item--no-carousel {
    width: 100%;
    padding: 20px 0;
    position: relative;
    display: block;
}

.content-blocks__item {
    width: 100%;
    padding: 15px 0;
    position: relative;
    display: block;
}

.content-blocks__item-wrapper {
    width: 100%;
    height: auto;
    display: block;
    border: 4px transparent solid;
    transition: 0.3s border-color ease;

    &:hover, &:focus {
        border-color: color(axa-blue);
    }
}

.content-blocks__item-container {
    width: 100%;
    height: auto;
    display: block;
    position: relative;
    overflow: hidden;
}

.content-blocks__image {
    width: 100%;
    height: 0;
    padding-top: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.content-blocks__content {
    width: 100%;
    height: auto;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 20px 20px;
    background-color: color(color-black, .5);
}

.content-blocks__title {
    margin-bottom: 5px;
    color: color(plain-white);
}

.content-blocks__text {
    @include typography(typo-18, normal, $root-font-family);
    color: color(plain-white);
}

.content-blocks__btn-wrapper {
    margin: auto;
    margin-top: 30px;
    text-align: inherit;
    width: 100%;
    max-width: $standard-max-width;
    height: auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.content-block-pagination-item{

    &:first-child, &:last-child {
        display: none;
    }
}


.content-block-pagination {
    margin-top: 30px;
}


//==========================================================================================
//  Modifiers
//==========================================================================================


//==========================================================================================
//  Media Queries
//==========================================================================================
@include mq($from: screen600) {
    .content-blocks__container {
        overflow: visible !important;
    }

    .content-blocks__item {
        padding:15px;
    }

    .content-blocks__wrapper--tablet-peak {
        padding: $section-hpadding-sm;
    }
}

@include mq($from: screen768) {
    .content-blocks__container--no-carousel {
        max-width: 100%;
        padding: 0;
        display: flex;
        flex-flow: row wrap;
        justify-content: left;
        align-items: center;
    }

    .content-blocks__item--no-carousel {
        padding: 15px 15px;
        width:50%;

        &:nth-child(odd){
            padding-left:0;
        }

        &:nth-child(even){
            padding-right:0;
        }
    }
}

@include mq($from: screen960) {
    .content-blocks {
        padding: $component-padding-lg;
    }

    .content-blocks__wrapper {
        padding: 0;
        margin-top:40px;
    }

    .content-blocks__container {
        max-width: 100%;
        padding: 0;
        display: flex;
        flex-flow: row wrap;
        justify-content: left;
        align-items: center;
    }

    .content-blocks__item {
        width: 33.33333%;
        padding: 15px 0;

        &:nth-child(3n + 1) {
            padding-left:0;
            padding-right:20px;
        }

        &:nth-child(3n + 2) {
            padding-left:10px;
            padding-right:10px;
        }

        &:nth-child(3n + 3) {
            padding-left:20px;
            padding-right:0;
        }
    }

    .content-blocks__item--no-carousel {
        width: 33.3333%;

        //Reset odd and even from tablet media query
        &:nth-child(odd){
            padding-left:15px;
        }

        &:nth-child(even){
            padding-right:15px;
        }

        //Evenly distribute padding so that widths are consistent. Items on the left and right of the screen should have left/right padding of 0
        &:nth-child(3n + 1) {
            padding-left:0;
            padding-right:20px;
        }

        &:nth-child(3n + 2) {
            padding-left:10px;
            padding-right:10px;
        }

        &:nth-child(3n + 3) {
            padding-right:0;
            padding-left:20px;
        }
    }

    .content-block-pagination {
        display: none;
    }
}

@include mq($from: screen1200) {

    .content-blocks__container {
        max-width: $standard-max-width;
    }

    .content-blocks__item--no-carousel {
        width: 25%;

        //Evenly distribute padding so that widths are consistent. Items on the left and right of the screen should have left/right padding of 0
        //4 in a row will have 28px between them instead of 30px (needs to be multiple of 4)
        &:nth-child(4n + 1) {
            padding-left:0;
            padding-right:21px;
        }

        &:nth-child(4n + 2) {
            padding-left:7px;
            padding-right:14px;
        }

        &:nth-child(4n + 3) {
            padding-left:14px;
            padding-right:7px;
        }

        &:nth-child(4n + 4) {
            padding-right:0;
            padding-left:21px;
        }
    }
}