//==========================================================================================
//
//  Login Panel Item
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================
.login-panel-item{
    display:block;
    text-decoration: none;
    width:100%;
    box-sizing: border-box;
    overflow: hidden;
    overflow-wrap: break-word;
    word-wrap: break-word;
    padding-right: 25px;
    padding-left: 25px;
    padding-top: 30px;
    padding-bottom: 30px;
    background: color(axa-grey);
    margin-bottom: 10px;
    border-radius: 1%; //slightly rounded corners
    box-shadow: 0 -1px 6px rgba(0, 0, 0, 0.05), //top
                1px 0 6px rgba(0, 0, 0, 0.05),  //left
                0 1px 6px rgba(0, 0, 0, 0.05),  //bottom
                -1px 0 6px rgba(0, 0, 0, 0.05);  //right
    transition: background-color 0.3s ease-in-out;

    &:hover, &:focus{
       outline: none;
    }

}

.login-panel-item__container{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.login-panel-item__title{
    color: color(plain-white);
    margin-bottom:10px;
}

.login-panel-item__description{
    @include typography(typo-16, normal, $root-font-family);
    color: color(plain-white);
    margin-bottom: 30px;
}

.login-panel-item-wide{
    box-sizing: border-box;
    width:100%;
    overflow: hidden;
    overflow-wrap: break-word;
    word-wrap: break-word;
    padding-right: 25px;
    padding-left: 25px;
    padding-top: 40px;
    padding-bottom: 40px;
}

.login-panel-item-wide__title{
    margin: 0;
    margin-bottom: 20px;
}

.login-panel-item-wide__description{
   margin-bottom: 30px;
}

.login-panel-item__link, .login-panel-item-wide__link {
    margin: 0;
    margin-top:auto;
}

//==========================================================================================
// Modifiers
//==========================================================================================
.login-panel-item--blue{
    background-color: color(axa-blue);

    &:hover, &:focus{
        background-color: color(dark-filter-axa-blue);
    }
}
//==========================================================================================
//  Media Queries
//==========================================================================================
@include mq($from: screen768) {
    .login-panel-item {
        width: calc(33.3333% - 10px);
        padding-top: 45px;
        padding-bottom: 45px;
        margin-bottom: 20px;
        box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.05), //top
        2px 0 10px rgba(0, 0, 0, 0.05), //left
        0 2px 10px rgba(0, 0, 0, 0.05), //bottom
        -2px 0 10px rgba(0, 0, 0, 0.05); //right
    }

    .login-panel-item-wide {
        width: calc(33.3333% - 10px); //50% width - 14px margin on one side
        padding-left: 0px;
    }

    .login-panel-item__title {
        margin-bottom: 25px;
    }
}

@include mq($from: screen1200) {
    .login-panel-item{
        width: calc(25% - 20px); //25% width - 20px margin on one side

    }

    .login-panel-item-wide{
        width: calc(75% - 14px); //50% width - 14px margin on one side
    }
}