//==========================================================================================
//
//  Content Link List
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================
.content-link-list-wrapper {
    width: 100%;
    height: auto;
    display: block;
    overflow: hidden;
    padding: 0 40px;
    margin: 0 auto 60px auto;
}

.content-link-list__container {
    width: 100%;
    height: auto;
    // padding: 0 40px;
    /* Wider than 768px to account for item padding of 15px on each side */
    max-width: 768px;
    margin: 0 auto;
}

.content-link-list {
    display: flex;
    margin: 0 auto;
    flex-flow: row wrap;
    list-style: none;
    justify-content: center;
    position: relative;
    padding: 20px 0 10px 0;

    &:after {
        width: 100%;
        height: 2px;
        background-color: color(axa-silver, 0.6);
        position: absolute;
        content: "";
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }
}

.content-link-list__item {
    @include typography(typo-18, 600, $root-font-family);
    margin-left: 15px;
    margin-right: 15px;
}
//==========================================================================================
//  Modifiers
//==========================================================================================

//==========================================================================================
//  Media Queries
//==========================================================================================
@include mq($from: screen720) {
    .content-link-list__container {
        padding-left: 40px;
        padding-right: 40px;
    }
}