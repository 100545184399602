@import "../list-component/_list-component.scss";

.richtext {
	//===========================================================================================
	// Serif
	//===========================================================================================
	.typo-78-serif {
		@include typography(h1, 700, $root-font-family-serif);
		margin-bottom: 21px;

		// Tablet
		@include mq($from: screen768) {
			margin-bottom: 28px;
		}
	}

	.typo-62-serif {
		@include typography(h2, 700, $root-font-family-serif);
		margin-bottom: 16px;

		// Tablet
		@include mq($from: screen768) {
			margin-bottom: 26px;
		}
	}

	.typo-48-serif {
		@include typography(h3, 700, $root-font-family-serif);
		margin-bottom: 13px;

		// Tablet
		@include mq($from: screen768) {
			margin-bottom: 18px;
		}
	}

	.typo-36-serif {
		@include typography(h4, 700, $root-font-family-serif);
		margin-bottom: 11px;

		// Tablet
		@include mq($from: screen768) {
			margin-bottom: 17px;
		}
	}

	.typo-28-serif {
		@include typography(h5, 700, $root-font-family-serif);
		margin-bottom: 16px;

		// Tablet
		@include mq($from: screen768) {
			margin-bottom: 13px;
		}
	}

	//===========================================================================================
	// Sans Serif
	//===========================================================================================
	.typo-78 {
		@include typography(typo-78, 700, $root-font-family);
		margin-bottom: 21px;

		// Tablet
		@include mq($from: screen768) {
			margin-bottom: 28px;
		}
	}

	.typo-62 {
		@include typography(typo-62, 700, $root-font-family);
		margin-bottom: 16px;

		// Tablet
		@include mq($from: screen768) {
			margin-bottom: 26px;
		}
	}

	.typo-48 {
		@include typography(typo-48, 700, $root-font-family);
		margin-bottom: 13px;

		// Tablet
		@include mq($from: screen768) {
			margin-bottom: 18px;
		}
	}

	.typo-36 {
		@include typography(typo-36, 700, $root-font-family);
		margin-bottom: 11px;

		// Tablet
		@include mq($from: screen768) {
			margin-bottom: 17px;
		}
	}

	.typo-28 {
		@include typography(typo-28, 700, $root-font-family);
		margin-bottom: 16px;

		// Tablet
		@include mq($from: screen768) {
			margin-bottom: 13px;
		}
	}

	.typo-24 {
		@include typography(typo-24, 700, $root-font-family);
		margin-bottom: 11px;

		// Tablet
		@include mq($from: screen768) {
			margin-bottom: 13px;
		}
	}

	.typo-20 {
		@include typography(typo-20, 400, $root-font-family);
		margin-bottom: 12px;

		// Tablet
		@include mq($from: screen768) {
			margin-bottom: 16px;
		}
	}

	.typo-18 {
		@include typography(typo-18, 400, $root-font-family);
		margin-bottom: 10px;

		// Tablet
		@include mq($from: screen768) {
			margin-bottom: 15px;
		}
	}

	.typo-16 {
		@include typography(typo-16, 400, $root-font-family);
		margin-bottom: 10px;

		// Tablet
		@include mq($from: screen768) {
			margin-bottom: 11px;
		}
	}

	.typo-14,
	small {
		@include typography(typo-14, 400, $root-font-family);
		margin-bottom: 10px;

		// Tablet
		@include mq($from: screen768) {
			margin-bottom: 8px;
		}
	}

	.typo-13 {
		@include typography(typo-13, 400, $root-font-family);
		margin-bottom: 10px;

		// Tablet
		@include mq($from: screen768) {
			margin-bottom: 9px;
		}
	}

	//===========================================================================================
	// Widths Resetting
	//===========================================================================================
	p,
	li {
		width: 100%;
	}

	//===========================================================================================
	// Sibling element spacing
	//===========================================================================================
	p + a,
	p + button {
		margin-top: 30px;
		margin-left: 0;
	}

	h4 + h1 {
		margin-top: 0;
	}

	p + h1,
	p + h2,
	p + h3,
	p + h4,
	p + h5,
	p + h6 {
		margin-top: 20px;
	}

	//===========================================================================================
	//
	// Ordered and unordered list spacing
	//
	//===========================================================================================
	ol {
		@extend .list-component__list;
		@extend .list-component__list--number;
		li {
			padding-bottom: 5px;
		}
		li:last-child() {
			padding-bottom: 0;
		}
	}

	ul {
		@extend .list-component__list;
		@extend .list-component__list--bullet;
		li {
			padding-bottom: 5px;
		}
		li:last-child() {
			padding-bottom: 0;
		}
	}


	//===========================================================================================
	//
	// Richtext Font Family
	//
	//===========================================================================================

	//===========================================================================================
	// Sans Serif
	//===========================================================================================
	h2,
	h3,
	h4,
	h5 {
		font-family: $root-font-family;
	}

	//===========================================================================================
	// Serif
	//===========================================================================================
	h2.serif,
	h3.serif,
	h4.serif,
	h5.serif {
		font-family: $root-font-family-serif;
	}


	//===========================================================================================
	//
	//  Richtext editor heading Sizes
	//
	//  Comment:
	//  Styles for 'class-less' heading tags to be used in RTE as per client's request
	//  to bump size down in richtext components.
	//
	//  Adding typography classes (eg. 'typo-78') to headings will override these sizes and
	//  apply the typography specific style
	//
	//===========================================================================================


	//===========================================================================================
	// H2
	//===========================================================================================
	h2 {
		@include typography(typo-36, 700, $root-font-family);
		line-height: 1.4em;
	}

	//===========================================================================================
	// H3
	//===========================================================================================

	h3 {
		@include typography(typo-28, 700, $root-font-family);
	}

	//===========================================================================================
	// H4
	//===========================================================================================
	h4 {
		@include typography(typo-24, 700, $root-font-family);
		font-size: rem(18);
	}

	//===========================================================================================
	// H5
	//===========================================================================================
	h5 {
		@include typography(typo-20, 700, $root-font-family);
	}

	//===========================================================================================
	//
	//  Component specific spacing overrides
	//
	//  Comment:
	//  Adds custom top and bottom margins in headings for:
	//    - .article-content__content
	//    - .text-component__container
	//
	//===========================================================================================

	//===========================================================================================
	// H2 - H5
	//===========================================================================================
	&.article-content__content h2,
	&.article-content__content h3,
	&.article-content__content h4,
	&.article-content__content h5,
	&.text-component__container h2,
	&.text-component__container h3,
	&.text-component__container h4,
	&.text-component__container h5 {
		margin: 1rem 0 0.4rem;

		// Tablet
		@include mq($from: screen768) {
			margin: 1.2rem 0 0.6rem;
		}
		// Small Desktop
		@include mq($from: screen992) {
			margin: 1.4rem 0 0.7rem;
		}
		// Large Desktop
		@include mq($from: screen1200) {
			margin: 1.5rem 0 0.75rem;
		}
	}
}


//===========================================================================================
//
// Modifiers
//
//===========================================================================================
.richtext--center {
	text-align: center;
}

.richtext--white {
	color: color(plain-white);
}

//===========================================================================================
//
// Media Queries
//
//===========================================================================================
@include mq($from: screen768) {
	.richtext {
		ol,
		ul {
			column-count: 1;
			column-gap: 0;
			column-fill: auto;
		}
	}
}

@include mq($from: screen960) {
	.richtext {
		p + a,
		p + button {
			margin-top: 40px;
		}
	}
}
