//==========================================================================================
//
//  Photo Reel
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================

.fifty-fifty-in-carousel {
    width: 100%;
    height: auto;
    display: block;
    overflow: hidden;
    padding: $section-vpadding-sm;

    & .split-image-text-panel__content {
        //remove min-height to enable control over vertical spacing between carousel and navigation dots
        min-height:unset;
    }
}

.fifty-fifty-in-carousel__container {
    max-width: $standard-max-width;
    margin: 0 auto;
    overflow: visible;
    display: block;
    width: 100%;
    height: auto;
}

.fifty-fifty-in-carousel__carousel {
    width: 100%;
    padding: 0;
    display: block;
    overflow: visible !important;

    &:after {
        content: "";
        display: block;
        clear: both;
    }
}


.fifty-fifty-in-carousel__item {
    width: 100%;
    position: relative;
    display: block;
    padding: $section-hpadding-sm;
}


.fifty-fifty-in-carousel__controls{
    position:relative;
}

.fifty-fifty-in-carousel__control{
    top:20%;
    position: absolute;
    z-index:50;
    min-width:10px;
    opacity:0.15;

    &:hover{
        opacity:1;
    }
}

.fifty-fifty-in-carousel__control--prev{
    left:-10%;
}

.fifty-fifty-in-carousel__control--next{
    right:-10%;
}

.fifty-fifty-in-carousel__paginate-container {
    padding-top: 50px;
}

//==========================================================================================
//  Media Queries
//==========================================================================================
@include mq($from: screen600) {
    .fifty-fifty-in-carousel {
        padding: $component-padding-sm;
    }

    .fifty-fifty-in-carousel__item {
        padding: 0 15px;
    }

    .fifty-fifty-in-carousel__container{
        padding: 0 15px;
        overflow: visible !important;
    }
}

@include mq($from: screen1000) {
    .fifty-fifty-in-carousel {
        padding: $component-padding-lg;
        overflow: hidden;
    }

    .fifty-fifty-in-carousel__carousel {
        width:100%;
        overflow: hidden !important;
    }

    .fifty-fifty-in-carousel__container {
        padding: 0;
        width: 100%;
    }


    .fifty-fifty-in-carousel__item{
        display:flex;
        flex-direction: row;
    }

    .fifty-fifty-in-carousel__control{
        top:30%;
    }

    .fifty-fifty-in-carousel__control--prev{
        left:0;
    }

    .fifty-fifty-in-carousel__control--next{
        right:0;
    }

}