//==========================================================================================
//
//  50 / 50 Background Image Panel
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================

.bg-image-panel{
    max-width:100%;
    position:relative;
    min-height: 400px;
}

.bg-image-panel__wrapper {
    width: 100%;
    height: auto;
    max-width: $standard-max-width;
    margin:0px auto;
    position: relative;
    z-index: 1;
    padding: $section-hpadding-sm;
}

.bg-image-panel__image{
    height:100%;
    background-size:cover;
    background-position: center;
    background-repeat:no-repeat;
    // Padding top is to maintain aspect ratio
    padding-top:52.65%;
}

.bg-image-panel__copy {
    padding: $section-vpadding-sm;
    //Padding top sets the distance to the image in mobile, but want to set the padding bottom from variable
    padding-top: 40px;
    color: color(dark-grey);
}

//==========================================================================================
//  Modifiers
//==========================================================================================
//==========================================================================================
//  Align Right
//  This will align the content to the right hand side of the container
//==========================================================================================
.bg-image-panel__copy--right{
    margin-left:auto;
}
//==========================================================================================
//  Media Queries
//==========================================================================================
@include mq($from: screen768) {

    .bg-image-panel {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .bg-image-panel__image{
        position:absolute;
        top:0;
        left:0;
        width:100%;
        padding-top:0;
    }

    .bg-image-panel__copy{
        color:color(plain-white);
        width:50%;
    }

}

@include mq($from: screen960) {
    .bg-image-panel {
        padding:$section-hpadding-lg;
    }

    .bg-image-panel__wrapper {
        padding:0;
    }
}