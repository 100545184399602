/***************************************table button CSS***************************/
.btn-table {
    font-family: "SourceSansPro",Arial,sans-serif;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 2px;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    width: auto;
    padding: 10px 10px;
    min-height: 30px;
    text-transform: uppercase;
    margin-bottom: 8px;
    overflow: hidden;
    z-index: 0;
    cursor: pointer;
    text-decoration: none;
    text-align: center
}

.btn-table {
    background-color: color(axa-blue);
    color: color(plain-white);
    border-style: none
}

.btn-table:focus, .btn-table:hover, button:focus, button:hover {
    color: color(plain-white);
}

.btn-table:after {
    content: "";
    width: 0;
    height: 1000%;
    position: absolute;
    display: block;
    opacity: 0;
    top: 50%;
    left: 50%;
    -webkit-transform: translate3d(-50%,-50%,0) rotate(45deg);
    transform: translate3d(-50%,-50%,0) rotate(45deg);
    -webkit-transition: width .3s,opacity .3s;
    transition: width .3s,opacity .3s;
    background-color: color(dark-filter-axa-blue);
    color: color(plain-white);
    z-index: -1
}

.btn-table:hover:after {
    width: 100%;
    opacity: 1;
}

.anchor--small {
    font-family: "SourceSansPro",Arial,sans-serif;
    font-weight: 700;
    font-size: 11px;
    letter-spacing: .02em;
    display: none;
    text-decoration: none;
    -webkit-transition: color .3s;
    transition: color .3s;
    text-transform: uppercase;
    cursor: pointer;
    color: color(axa-blue);
}

@media (min-width:48em) {
    .anchor--small {
        display: inline-block;
    }
}

@media (min-width:62em) {
    .anchor--small {
        display: inline-block;
    }
}

@media (min-width:75em) {
    .anchor--small {
        display: inline-block;
    }
}
/***************************************table button CSS***************************/
/***************************TABLE CSS STARTS **************************/

.k-ihp-details-table {
    width: 100%;
    padding: 40px 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.no-border {
    border-left: none !important;
}

.table-around {
    border: 1px solid color(axa-silver);
}

@media (min-width:40em) {
    .table-around {
        border: 1px solid color(axa-silver);
    }

    .ihp-details-table__header--prestige-plus2 {
        background-color: color(viridian-green);
        color: color(plain-white);
        padding: 20px 0 !important
    }

    .k-ihp-details-table__table td {
        border: 1px solid color(axa-silver);
        width: 14%;
        text-align: center
    }

    .k-ihp-details-table__table td:first-child {
        border-left: none;
        text-align: left
    }

    .k-ihp-details-table__table td:last-child {
        border-right: none
    }

    .k-ihp-details-table__table tr:last-child td {
        border-bottom: none
    }

    .k-ihp-details-table__table th {
        text-align: center !important
    }

    .k-ihp-details-table__table th:first-child {
        border-bottom: 1px solid color(axa-silver)
    }
}
/***************************TABLE CSS ENDS **************************/
