//==========================================================================================
//
//  3 Block Carousel
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================
.three-block-carousel-navigation{
    padding: $component-padding-sm;
    overflow:hidden;
}

.three-block-carousel-navigation__container{
    max-width:$standard-max-width;
    margin: 0 auto;
    overflow:visible;
}

.three-block-carousel {
    max-width:$standard-max-width;
    width: 100%;
    margin: 0 auto;
    overflow: visible;
    position:relative;
    padding-left:10px;
    padding-right:10px;
}


.three-block-carousel-navigation__link-wrapper{
    display:none;
}

.three-block-carousel__wrapper{
    margin-top: 40px;
    margin-bottom:50px;
    display: block;
    width: 100%;
    overflow: visible !important;

    &:after {
        content: "";
        display: block;
        clear: both;
    }

    // Hack:
    // The following selector is required to target the div
    // generated by the Siema carousel JS.
    // It is used to extend the content area of
    // carousel items.
    // The div has no class so can't target by
    // class. Adding a class is not possible in JS
    // afaik.
    // So while this is not optimal code, it's
    // necessary.
    & > div {
        display: flex;
    }
}

.three-block-carousel__item-wrapper{
    padding:0 10px;
    height: 100%;
}

.three-block-carousel__item{
    width:100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    &--with-border {
        border: 3px solid color(axa-blue);
    }
}

.three-block-carousel__tag {
    @include typography(typo-14, 'normal', $root-font-family);
    display: inline-block;
    border-bottom: 1px solid color(dark-grey);
    padding-bottom: 10px;
    margin-bottom: 15px;
}

.three-block-carousel__copy{
    padding:30px 30px 40px 30px;
    background-color:color(mercury);
    color: color(dark-grey);
    position:relative;
    flex-grow: 1;
}
.three-block-carousel__item--with-border .three-block-carousel__copy {
    background-color:color(plain-white);
}


.three-block-carousel__item-sub-header{
    @include typography(typo-14, normal, $root-font-family);
    margin-bottom:10px;
    text-transform: uppercase;
    letter-spacing: 0.04em;
}


.three-block-carousel__cta{
    display:table;
    width:auto;
    text-align:center;
    margin: 30px auto 0 auto;
}

.three-block-carousel__image{
    display:block;
    width: 100%;
    height: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    &:after {
        display: block;
        padding-top: 56.25%;
        content: "";
    }
}



//==========================================================================================
//  Media Queries
//==========================================================================================
@include mq($from: screen768) {

    .three-block-carousel__cta{
        text-align:right;
        margin-top:0;
        padding-right:25px;
        position: absolute;
        right:0;
        bottom:0;
    }
}

@include mq($from: screen960){
    .three-block-carousel-navigation{
        padding: $component-padding-lg;
    }
    .three-block-carousel{
        margin:0;
        padding:0;
    }

    .three-block-carousel__item-sub-header{
        margin-bottom:20px;
    }

    .three-block-carousel__item-copy{
        @include typography(typo-16, normal, $root-font-family);
        margin-bottom:20px;
    }

    .three-block-carousel-navigation__container{
        max-width:1280px;
        display:flex;
        flex-direction: row;
        align-items: center;
    }

    .three-block-carousel{
        overflow:hidden;
    }

    .three-block-carousel__wrapper{
        overflow: hidden !important;
    }

    .three-block-carousel-navigation__link-wrapper{
        width:40px;
        display:inherit;
    }

    .three-block-carousel-navigation__next-link{
        display:block;
        width:35px;
        margin-left:5px;
    }

    .three-block-carousel-navigation__prev-link{
        display:block;
        width:35px;
        margin-right:5px;
    }

    .three-block-carousel-navigation__image{
        width:35px;
        height:35px;
    }
}

@include mq($from: screen1200){


    .three-block-carousel-navigation__container{
        max-width:1330px;
        display:flex;
        flex-direction: row;
        align-items: center;
    }

    .three-block-carousel-navigation__link-wrapper{
        width:65px;
    }

    .three-block-carousel-navigation__next-link{
        margin-left:30px;
    }

    .three-block-carousel-navigation__prev-link{
        margin-right:30px;
    }
}