//==========================================================================================
//
//  Branch Hero Banner
//
//==========================================================================================

.hero-banner__content-new {
    width: 80%;
    height: auto;
    margin: 80px 0;
    padding: 20px 40px;
    max-width: 960px;
    background-color: rgba(255,255,255,.925);
}

    @media (min-width:48em) {
        .hero-banner__content-new {
            text-align: left;
            max-width: 400px;
        }

        .hero-banner__content-new {
            margin-left: 40px;
            margin-right: 20px;
        }
    }

    .hero-banner__content-new {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .richtext--grey {
        color: color(dark-grey);
    }

    .contact-copy {
        text-transform: uppercase;
        margin-bottom: 20px;
        line-height: 1.5em;
        letter-spacing: .02em;
    }

    .contact-copy-two {
        margin-top: 20px;
        line-height: 1.5em;
        letter-spacing: .02em;
    }

    .blue-separator {
        background-color: color(axa-blue);
        height: 3px;
        width: 35px;
        margin-top: 10px;
        margin-bottom: 15px;
        margin: 0;
    }

    @media (min-width:48em) {
        .blue-separator {
            text-align: center;
            margin: 0;
        }
    }

    @media (min-width:62em) {
        .blue-separator {
            text-align: center;
            margin: 0;
        }
    }

    @media (min-width:75em) {
        .blue-separator {
            text-align: center;
            margin: 0;
        }

        .hero-banner__content-new {
            margin-right: 0;
        }
    }