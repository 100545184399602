//==========================================================================================
//
//  News Landing Article Component
//
//==========================================================================================

//==========================================================================================
//  Base Styles
//==========================================================================================
.news-landing-articles {
    width: 100%;
    margin: 0 auto;
    height: auto;
    display: block;
    overflow: hidden;
    /*padding: $component-padding-sm;*/
}

.news-landing-articles__wrapper {
    width: 100%;
    display: block;
    overflow: hidden;
    /*margin-top: 50px;*/
}

.news-landing-articles__container {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;

    &:after {
        content: "";
        display: block;
        clear: both;
    }
}

.news-landing-articles__intro {
    text-align: center;
}

.news-landing-articles__row {
    max-width: $standard-max-width;
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;

    &:after {
        content: "";
        display: block;
        clear: both;
    }
}

.news-landing-articles__panel {
    width: 100%;
    padding: 0;
    position: relative;
    padding-bottom: 20px;
}

.panel-magazine {

    a {
        color: color(plain-white);
        text-decoration: none;
    }
}

.panel-white {

    a {
        color: color(dark-grey);
        text-decoration: none;
    }
}

.news-landing-articles__content {
    padding: 0 20px;
}

.news-landing-articles__panel-title {
    margin: 25px 0 10px 0;
}

.news-landing-articles__panel-desc {
    margin-bottom: 15px;
}

.news-landing-articles__show-cta {
    display: block;
    margin-top: 50px;
    width: 100%;
    text-align: center;
}

.news-landing-articles__pagination-container {
    margin-top: 30px;
}

.news-landing-articles__pagination-item {
    width: 100%;
    display: block;

    &:first-child, &:last-child {
        display: none;
    }
}

.news-landing-articles__panel-image {
    margin-bottom: 20px;
}

//==========================================================================================
//  Media Queries
//==========================================================================================

@include mq($from: screen600) {
    .news-landing-articles__show-mobile {
        display: none;
    }

    .news-landing-articles__container {
        overflow: visible !important;
    }
}

@include mq($from: screen960) {
    .news-landing-articles {
        /*padding: $component-padding-lg;*/
    }

    .news-landing-articles__panel {
        width: 33.3333%;
        padding: 0px 15px;
        padding-bottom:40px;

        &:nth-child(3n + 1) {
            padding-left: 0;
            padding-right: 20px;
        }

        &:nth-child(3n + 2) {
            padding-left: 10px;
            padding-right: 10px;
        }

        &:nth-child(3n + 3) {
            padding-left: 20px;
            padding-right: 0;
        }


    }

    .news-landing-articles__content {
        padding: 0px;
    }

    .news-landing-articles__container {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        margin: 0 auto;
        align-items: start;
    }

    .news-landing-articles {
        .news-landing-articles__pagination-container {
            display: none;
        }
    }
}

@include mq($from: screen1200) {

    .news-landing-articles__container {
        max-width: $standard-max-width;
    }
}
