//==========================================================================================
//
//  Rich Text
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================


    .magazine-article-content {
        width: 100%;
        height: auto;
        clear: both;
        position: relative;
        overflow: hidden;

        &:after {
            clear: both;
            content: "";
            display: block;
        }

        & + &,
        & + .magazine-article-content__container {
            margin-top: 1.5em;
        }

        & + .full-width-cta {
            margin-top: 2rem;
        }
    }

.magazine-article-content-image {
    display:none;
    margin-bottom: 20px;
}
.magazine-article-content__container {
    width: 100%;
    height: auto;
    max-width: 100%;
    position: relative;
    display: flex;
}

    .magazine-article-content__content {
        width: 100%;
        height: auto;
        display: block;
        max-width: 100%;
        padding-right: 40px;
    }

    .magazine-article-content {
        .magazine-article-content__intro {
            font-weight: bold;
            margin-bottom: 50px;
        }
    }

.magazine-article-content__author {
    @include typography(typo-14, 600, $root-font-family);
    margin-top: 30px;
    margin-bottom: 30px;
}

.magazine-article-content__author:before {
    content: "";
    display: block;
    width: 85%;
    padding-bottom: 20px;
    border-top: 1px solid color(dark-grey);
}

    .magazine-article-content {

        ul, ol {
            margin: 0 auto 40px 40px;
            padding: 0;
        }
    }
    //==========================================================================================
    //  Modifiers
    //==========================================================================================
    //==========================================================================================
    // Rich Text Promo
    // Displays a promo component alonside a ruche text component
    //==========================================================================================
.magazine-article-content--promo, .magazine-article-content--fullwidth {

    .magazine-article-content__content {
        max-width: none;
        padding: 0 40px;
        width: 100%;
    }



    .magazine-article-promo {
        a {
            @include typography(anchor, 600, $root-font-family);
            text-decoration: none;
        }
    }
}
    //==========================================================================================
    //  Media Queries
    //==========================================================================================
@include mq($from: screen720) {
    .magazine-article-content {

        .magazine-article-content__container {


            & + & {
                margin-top: 16px;
            }
        }

        .magazine-article-content__content {
            /*max-width: $content-max-width-768;*/
            padding: 0 40px;
            margin: 0;
        }
    }

    .magazine-article-content--promo {

        .magazine-article-content__content {
            padding: 0;
        }

        .magazine-article-content__content > * {
            /*max-width: $content-max-width-768;*/
            padding: 0 40px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

@include mq($from: screen960) {

    .magazine-article-content__container {
        padding: 0;
        max-width: $standard-max-width;
        /*margin: 0 auto;*/
    }


    .magazine-article-content--promo {

        .magazine-article-content__content {
            max-width: none !important;
            padding: 0 40px;
        }

        .magazine-article-content__content > * {
            /*max-width: $content-max-width-768;*/
            padding: 0 30px 0 0;
            margin: 0;
            /*margin-left: auto;
            margin-right: auto;*/
        }

        .magazine-article-content__author {
            @include typography(typo-14, 600, $root-font-family);
            margin-top: 30px;
            margin-bottom: 30px;
        }
    }
}

@include mq($from: screen992) {
    .magazine-article-content {



        .magazine-article-content-image {
            display: block;
        }

        .magazine-article-content__content {
            /*max-width: $content-max-width-768;*/
            padding-left: 0px;
            padding-right: 30px;
            /*margin: 0 auto;*/
        }
    }

    .magazine-article-content--fullwidth {
        padding: 0px 40px;

        .magazine-article-content__content {
            /*max-width: $content-max-width-768;*/
            padding-left: 0px;
            padding-right: 0px;
            /*margin: 0 auto;*/
        }
    }


    .magazine-article-content--promo {
        .magazine-article-content__content > * {
            /*max-width: $content-max-width-768;*/
            padding: 0 30px 0 0;
            margin: 0;
            /*margin-left: auto;
            margin-right: auto;*/
        }

        .magazine-article-content__author {
            @include typography(typo-14, 600, $root-font-family);
            margin-top: 30px;
            margin-bottom: 30px;
        }
    }
}