//==========================================================================================
//
//  Stats Panel
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================
.stats-panel {
    width: 100%;
    height: auto;
    display: block;
    padding: $section-vpadding-sm;
    overflow: hidden;
}

.stats-panel__wrapper {
    width: calc(80% - 20px);
    margin: 0 auto;
    display: block;
    height: auto;
    padding: 0 20px;
}

.stats-panel__container {
    width: 100%;
    height: auto;
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    padding: 15px 0;
    overflow: hidden;
}


.stats-panel__intro {
    width: 100%;
    text-align: center;
    max-width: $content-max-width-960;
    margin: 0 auto 15px auto;
    padding: $section-hpadding-sm;
}



.stats-panel__column {
    width: 100%;
    height: auto;
    padding: 15px 18px 30px;
    position: relative;
    text-align: center;
    display: flex;
    flex-flow: column wrap;
    align-items: center;

    &:after, &:before {
        content: "";
        display: block;
        width: 30%;
        height: 1px;
        position: absolute;
        opacity: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    &:before {
        top: 0;
    }

    &:after {
        bottom: 0;
    }

    /* Show the before and after of each second element */
    &:nth-of-type(2n) {
        &:before, &:after {
            opacity: 0.65;
        }
    }

    &:nth-child(even) {
        background-color: inherit;

        .stats-panel__content {
            order: 0.65;
        }
    }

    &:last-child {
        padding-bottom: 25px;
    }
}

.stats-panel__stat {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
}

.stats-panel__content {
    width: 100%;
    height: auto;
    position: relative;
    padding: 0;
    max-width: 350px;
    margin: 0 auto;
}

.stats-panel__number {
    @include typography(h1, bold, $root-font-family-serif);
    // Added mobile font size
    font-size: 60px;
    text-align: center;
    margin-bottom: 0;
    width: 100%;
}

.stats-panel__btn-wrapper {
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 0;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.stats-panel__btn {
    margin: 10px 10px 0px;
}

.stats-panel {
    .carousel-pagination {
        margin-top: 30px;
        margin-bottom: 40px;
    }
}

//==========================================================================================
//  Media Queries
//==========================================================================================

@include mq($from: screen768) {
    .stats-panel {
        padding: $section-vpadding-lg;
    }

    .stats-panel__number {
        // Force font size to 60 on tablet mode too
        font-size: 60px;
    }

    .stats-panel__btn-wrapper {
        margin-top: 10px;
    }

    .stats-panel__wrapper {
        width: 100%;
    }

    .stats-panel__intro {
        margin-bottom: 10px;
    }

    .stats-panel__title {
        margin-bottom: 30px;
    }

    .stats-panel__column {
        width: 33%;
        padding: 20px 35px;

        &:after, &:before {
            content: "";
            display: block;
            height: 30%;
            width: 1px;
            position: absolute;
            top: 50%;
            transform: translateX(0) translateY(-50%);
            opacity: 0;
            left: auto;
        }

        &:before {
            left: 0;
        }

        &:after {
            right: 0;
        }

    }

    .stats-panel__stat {
        width: 100%;
        /* Order set to place stat infornt of text */
        order: -1;
    }

    .stats-panel__content {
        width: 100%;

        &:after {
            display: none;
        }
    }
}

@include mq($from: screen1000) {

    .stats-panel__container {
        max-width: $standard-max-width;
    }

    .stats-panel__column {
        width: 33.3333%;
        padding: 20px 40px;

        /* Hide every second items before line */
        &:nth-of-type(2n) {
            &:before {
                opacity: 0;
            }
        }

        /* Show the before and after of each middle element */
        &:nth-of-type(3n-1) {
            &:before, &:after {
                opacity: 0.65;
            }
        }
    }

    .stats-panel__column {
        flex-flow: column wrap;
        padding: 20px 40px;
        .stats-panel__content {
            padding-left: 0;
            padding-right: 0;
        }

        &:nth-child(even) {
            .stats-panel__content {
                padding-left: 0;
                padding-right: 0;
            }

            .stats-panel__stat {
                padding: 0;
            }
        }
    }

    .stats-panel__text {
        text-align: center;
    }

    .stats-panel__stat {
        padding: 0;
    }
}

@include mq($from: screen1140) {
    .stats-panel__number {
        @include typography(h1, bold, $root-font-family-serif);
    }
}