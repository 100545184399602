//==========================================================================================
//
//  Buttons
//
//==========================================================================================

//==========================================================================================
//  Base Styles
//==========================================================================================
.btn,
button {
    @include typography(button, 600, $root-font-family);
    display: inline-flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    position: relative;
    width: auto;
    padding: 15px 25px;
    min-height: 30px;
    text-transform: uppercase;
    min-width: 180px;
    overflow: hidden;
    background-color: color(axa-blue);
    color: color(plain-white);
    z-index: 0;
    cursor: pointer;
    margin: 10px;
    text-decoration: none;
    border-style: none;
    text-align: center;

    &:after {
        content: "";
        width: 0;
        height: 1000%;
        position: absolute;
        display: block;
        opacity: 0;
        top:50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0) rotate(45deg);
        transition: width 0.3s, opacity 0.3s;
        background-color: color(dark-filter-axa-blue);
        color: color(plain-white);
        z-index: -1;
    }

    &:hover, &:focus {
        color: color(plain-white);
        &:after {
            width: 100%;
            opacity: 1;
        }
    }
}

//==========================================================================================
//  Modifiers
//==========================================================================================
//==========================================================================================
// Button Arrow
// Applies an arrow button style to the element
//==========================================================================================
.btn--arrow {
    padding-right: 55px;

    .btn__arrow {
        top: 2px;
        display: block;
        margin-left: 20px;
        position: relative;
        position: absolute;
        right: 25px;
        padding-top: 15px;
    }

    .btn__arrow-container {
        width: 1em;
        height: 1em;
        position: relative;
    }

    .btn__arrow-image{
        width: 1em;
        height: 1em;
        font-size: inherit;
    }
}

//==========================================================================================
// Button Icon
// Applies an icon button style to the element
//==========================================================================================
.btn--icon {
    padding-right: 75px;

    .btn__icon {
        top: 0;
        display: block;
        margin-left: 20px;
        position: absolute;
        right: 50px;
        padding-top: 15px;
        height: 100%;
        border-left: 2px color(btn-border-dark-blue) solid;

        &:hover, &:focus {
            border-color: color(btn-border-blue);
        }
    }

    .btn__icon-container {
        position: absolute;
        right: -35px;
        top: 50%;
        transform: translateY(-50%);
        width: auto;
        height: auto;
    }

    .btn__icon-image {
        width: 16px;
        height: 16px;
        fill: color(plain-white);
    }

}

//==========================================================================================
// Keyline
// Applies a keyline color pallete to the buttons
//==========================================================================================
.btn--keyline {
    background-color: transparent;
    color: color(axa-blue);
    border: 2px color(axa-blue) solid;
    transition: border 0.3s, color 0.3s;

    &:hover, &:focus {
        color: color(plain-white);
        border: 2px color(btn-border-blue) solid;
    }

    .btn__icon {
        border-left: 2px color(btn-border-dark-blue) solid;
    }
}

.btn--keyline, .btn--ghost {
    &.btn--large.btn--arrow {
        .btn__arrow {
            padding-top: 15px;
        }
    }
}

.btn--keyline, .btn--ghost {
    &.btn--medium.btn--arrow{
        .btn__arrow {
            padding-top: 10px;
        }
    }
}

//==========================================================================================
// Flamingo
// Applies a flamingo color pallete to the buttons
//==========================================================================================
.btn--flamingo {
    background-color: color(burnt-sienna);
    color: color(plain-white);

    &:after {
        background-color: color(dark-filter-burnt-sienna);
        color: color(plain-white);
    }

    &:hover,
    &:focus {
        color: color(plain-white);
    }
}

.btn--flamingo.btn--icon {
    &:hover,
    &:focus {
        .btn__icon {
            border-color: color(plain-white);
        }
    }

    .btn__icon {
        border-left: 2px solid color(plain-white);
    }
}

.btn--flamingo.btn--keyline {

    background-color: transparent;
    color: color(burnt-sienna);
    border: 2px color(burnt-sienna) solid;
    transition: border 0.3s, color 0.3s;

    &:hover, &:focus {
        color: color(plain-white);
        border: 2px color(dark-filter-burnt-sienna) solid;

        .btn__icon {
            border-color: color(btn-border-dark-orange);
        }
    }

    .btn__icon {
        border-left: 2px solid color(btn-border-orange);
    }
}

//==========================================================================================
// Ghost
// Applies a ghost color pallete to the buttons
//==========================================================================================
.btn--ghost {
    background-color: transparent;
    border: 2px solid color(plain-white);
    transition: border 0.3, color 0.3;

    &:after {
        background-color: color(plain-white);
    }

    &:hover, &:focus {
        color: color(axa-blue);
    }
}

.btn--ghost.btn--icon {
    .btn__icon {
        border-color: color(plain-white);
    }

    &:hover, &:focus {
        border-color: color(axa-blue);
        .btn__icon {
            border-color: color(axa-blue);
        }
    }
}

//==========================================================================================
// Small Button
// Applies a small button style to the element
//==========================================================================================
.btn--small {
    min-height: 30px;
    padding-top: 10px;
    padding-bottom: 10px;

    .btn__icon-container {
        width: 16px;
        height: 16px;
    }
}

.btn--small.btn--arrow {
    .btn__arrow {
        padding-top: 10px;
    }
}

.btn--small.btn--icon {
    .btn__icon-container {
        right: -32px;
    }
}

//==========================================================================================
// Medium Button
// Applies a medium button style to the element
//==========================================================================================
.btn--medium {
    @include typography(button-medium, 600, $root-font-family);
    min-height: 40px;
    padding-top: 10px;
    padding-bottom: 10px;

    .btn__icon-container {
        width: 16px;
        height: 16px;
    }
}

.btn--medium.btn--arrow {
    .btn__arrow {
        padding-top: 12px;
    }
}

.btn--medium.btn--icon {
    .btn__icon-container {
        right: -32px;
    }
}

//==========================================================================================
// Large Button
// Applies a large button style to the element
//==========================================================================================
.btn--large {
    @include typography(button-large, 600, $root-font-family);
    min-height: 40px;
}

.btn--large.btn--icon {
    .btn__icon-image {
        height: 20px;
        width: 20px;
    }
}

//==========================================================================================
// Button Reset
// Remove all button styles
// Used to clean button styles before overriding
//==========================================================================================
.btn--reset{
    margin: 0;
    padding: 0;
    min-width: 0;
    min-height: auto;
    text-transform: none;
    background: none;
    color: initial;

    &:hover, &:focus{
        color: initial;
    }

    &:before, &:after{
        display: none;
    }

}

//==========================================================================================
// Media Queries
//==========================================================================================
@include mq($from: screen600) {
    .btn+.btn {
        margin-left: $btn-margin;
    }
    .btn--medium {
        min-height: 40px;
    }

    .btn--large {
        min-height: 50px;
    }

    .btn--large.btn--arrow {
        .btn__arrow {
            padding-top: 17px;
        }
    }
}