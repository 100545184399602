//==========================================================================================
//
// Magazine
//
//==========================================================================================

.magazine--right{
    padding: 0;
}


.magazine-article-repository {
    display: block;
    margin: 0;
}

.magazine-article-wrapper {
    display: flex;
    flex-direction: column;
    background-color: color(plain-white);
}

.magazine-article-wrapper .magazine--right {
    order: 1;
}

.magazine--right .split-image-text-panel__wrapper {
    padding-top: 0;
}

    @media (min-width: 45em) {
        .magazine-wrapper {
            padding-top: 50px;
        }

        .magazine-article-wrapper {
            padding-top: 0px;
        }
    }

    @media (min-width: 62em) {

        .magazine-article-wrapper .magazine--right {
            order: 2;
        }


        .magazine {
            background: linear-gradient(90deg,#fafafa 10%, color(color-black) 25%);
            position: relative;
            overflow: hidden;
            /*margin: 30px 0;*/
        }

        .magazine-wrapper {
            max-width: 1200px;
            margin: 0 auto;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -webkit-flex-flow: row wrap;
            -ms-flex-flow: row wrap;
            flex-flow: row wrap;
            /*background-color: color(color-black);*/
            position: relative;
            padding-top: 50px;
        }

        .magazine-wrapper {
            display: flex;
        }

        .magazine-wrapper .secondary-navigation--left {
            /*background-color: color(mercury);*/
        }

        .magazine-article-wrapper {
            background-color: color(plain-white);
            -webkit-flex-flow: row-reverse wrap;
            -ms-flex-flow: row-reverse wrap;
            flex-flow: row-reverse wrap;
            padding-top: 0px;
        }

        .magazine-article-wrapper .secondary-navigation--left {
            /*background-color: color(wild-sand);*/
        }
        /* .content-wrapper.hero-banner-text-wrapper { display: block; } */
        .magazine-article-content__content, .magazine-article-content__content p, p.paragraphestandard span {
            font-size: 18px;
            line-height: 1.5em;
            letter-spacing: .01em;
        }

        .content-container {
            display: flex;
            flex-flow: initial;
            padding: 0 20px;
        }

        .magazine--right {
            width: 100%;
        }

        .magazine-repository .magazine--right {
            padding-left: 50px;
            width: 70%;
        }

        .side-content-mobile {
            display: none;
        }

        .magazine--right .split-image-text-panel__wrapper {
            padding-left: 0;
        }
    }


    @media (min-width: 87.5em) {
        .magazine-repository .magazine--right {
            width: 75%;
        }

        .magazine-repository {
            background: linear-gradient(90deg,#fafafa 20%,color(color-black) 25%);
        }
    }


