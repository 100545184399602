//==========================================================================================
//
//  TinyMce
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================
ul.ticklist--green {
    margin-left: 0;
}

ul.ticklist--green li {
    background: url(../images/icons/tick--green.png) no-repeat;
    list-style-type: none;
    position: relative;
    padding: 0px 0px 1px 10%;
    vertical-align: middle;
}

.richtext ul.ticklist--green li:before {
    content: none;
}
