//==========================================================================================
//
//  Search Panel
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================
.search-panel {
    position: relative;
    display: inline-block;
    width: 100%;
    padding:$section-hpadding-sm;
    padding-top: 90px;
    padding-bottom: 90px;
}

.search-panel__container {
    margin: 0 auto;
    max-width: $standard-max-width;
    text-align: center;
    position: relative;
    z-index: 1;
}

.search-panel__background-image{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    //Tint
    &:after{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        /* Important added to override scheme */
        background-color: rgba(color(axa-blue), .5) !important;
    }

}

//==========================================================================================
// Modifiers
//==========================================================================================
.search-panel--transparent{
    background: none;
}

//==========================================================================================
//  Media Queries
//==========================================================================================
@include mq($from: screen768) {
    .search-panel{
        padding-top: 65px;
        padding-bottom: 65px;
    }

    .search-panel__title{
        margin-bottom: 40px;
    }

    .search-panel__helper-text{
        margin-top: 40px;
    }
}

@include mq($from: screen960) {
    .search-panel {
        padding: $section-hpadding-lg;
        padding-top: 65px;
        padding-bottom: 65px;
    }
}