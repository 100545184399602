//==========================================================================================
//
//  Login Overlay
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================
.login-panel{
    padding: $component-padding-sm;
}

.login-panel__container{
    margin: 0 auto;
    max-width: $standard-max-width;
    margin-bottom: -2%; // account for children's paddings
}

.login-panel__title-small-print{
    @include typography(typo-16, 600, $root-font-family);
    display: block;
    margin-bottom:10px;
}

.login-panel__title{
    margin-top: 0;
    margin-bottom:30px;
}

.login-panel__list{
    display:flex;
    flex-direction: column;
    justify-content: space-between;
}

//==========================================================================================
//  Media Queries
//==========================================================================================
@include mq($from: screen768) {
    .login-panel__list{
        flex-flow: row wrap;
    }
}

@include mq($from: screen960) {
    .login-panel{
        padding: $component-padding-lg;
    }
}