/*
// ==========================================================================================
//  Documentation Styling
// ==========================================================================================
*/
.component-documentation {
    padding: 20px 0;
}

.component-documentation__content {
    padding: 0 20px;
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.component-documentaion__title {
    @include typography(typo-36, 600, $root-font-family);
    color:color(dark-grey);
    margin-bottom: 20px;
}

.component-documentaion__text {
    @include typography(typo-18, normal, $root-font-family);
}

.component-documentaion__preview {
    width: 100%;
    height: auto;
    display: block;
    overflow: hidden;
}

.component-documentation__markup {
    height: auto;
    display: block;

    code{
        width: 100%;
        height: auto;
        display: block;
    }

}

.component-documentation__modifiers {
    width: 100%;
    height: auto;
    overflow: hidden;
    li {
        @include typography(typo-18, normal, $root-font-family);

        a {
            color: color(axa-blue);
        }
    }
}

/*
// ==========================================================================================
//  Base Styles
// ==========================================================================================
*/

/*
// ==========================================================================================
//  Media Queries
// ==========================================================================================
*/