//==========================================================================================
//
//  Article Recommendations Component
//
//==========================================================================================

//==========================================================================================
//  Base Styles
//==========================================================================================
.article-recommendations {
    width: 100%;
    height: auto;
    padding: $component-padding-sm;
    overflow: hidden;
}

.article-recommendations__intro {
    width: 100%;
    max-width: $standard-max-width;
    margin: 0 auto;
}

.article-recommendations__intro-title {
    text-align: center;
    margin-bottom: 40px;
}

.article-recommendations-item__title-link{
    text-decoration:none;
}

.article-recommendations__content-wrapper {
    width: 100%;
    height: auto;
    max-width: $standard-max-width;
    margin: 0 auto;
}

.article-recommendations__content-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin-bottom: -30px; //Account for last children's bottom margin
}

.article-recommendations-item {
    width: 100%;
    padding: 0;
    margin-bottom: 50px;
}

.article-recommendations__content {
    width: 100%;
    height: auto;
}

.article-recommendations-item__image {
    width: 100%;
    height: auto;
    display: block;
    margin-bottom: 20px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    &:after {
        display: block;
        padding-top: 56.25%;
        content: "";
    }
}

.article-recommendations-item {
    .anchor{
        margin-top: 30px;
    }
}


//==========================================================================================
//  Media Queries
//==========================================================================================
@include mq($from: screen768) {

    .article-recommendations-item {
        width: 50%;

        padding:0 20px;

        &:nth-child(odd) {
            padding-left:0;
        }

        &:nth-child(even) {
            padding-right:0;
        }
    }

    .article-recommendations__intro-title {
        margin-top:0;
    }
}

@include mq($from: screen960){
    .article-recommendations {
        padding: $component-padding-lg;
    }
}

@include mq($from: screen1080) {
    .article-recommendations-item {
        width: 25%;

        &:nth-child(4n+1) {
            padding-left:0px;
            padding-right:30px;
        }

        &:nth-child(4n+2) {
            padding-left:10px;
            padding-right:20px;
        }

        &:nth-child(4n+3) {
            padding-left:20px;
            padding-right:10px;
        }

        &:nth-child(4n+4) {
            padding-left:30px;
            padding-right:0;
        }

    }
    .article-recommendations__base-link{
        text-align:right;
    }
}