/***************************************LOGIN BUTTON CSS***************************/

/*.meta-nav__login-btn {
    border: 2px solid color(axa-blue);
    padding-right: 10px;
    padding-left: 10px;
}*/



.mega-menu__item-login {
    font-family: $root-font-family;
    font-size: .875rem;
    letter-spacing: .02em;
    color: color(axa-blue);
    width: auto;
    height: 49px;
    text-transform: uppercase;
    line-height: 100%;
    padding-bottom: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-weight: 700;
    margin-top: 8px;
}

[data-primary-nav-item="login"] {
    line-height: 45px;
    display: inline-block;
    vertical-align: middle;
    padding: 0 10px;
    outline: 0;
    cursor: pointer;
    color: color(axa-blue);
}

[data-primary-nav-item="login"]:hover {
    color: color(dark-filter-axa-blue)
}

/***************************************LOGIN BUTTON CSS***************************/