//==========================================================================================
//
//  axa diagnostic tool
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================

.axa-diagnostic-tool {
    font-family: "SourceSansPro",Arial,sans-serif;
    font-weight: 400;
    font-size: .875rem;
    line-height: 1.5em;
    letter-spacing: .01em;
    margin: 20px auto;
    max-width: 100%;
    background: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 3px
}

@media (min-width:48em) {
    .axa-diagnostic-tool {
        font-size: 1.125rem;
        line-height: 1.5em;
        letter-spacing: .01em
    }
}

@media (min-width:62em) {
    .axa-diagnostic-tool {
        font-size: 1.125rem;
        line-height: 1.5em;
        letter-spacing: .01em
    }
}

@media (min-width:75em) {
    .axa-diagnostic-tool {
        font-size: 1.125rem;
        line-height: 1.5em;
        letter-spacing: .01em
    }
}

.axa-diagnostic-tool__step-title {
    margin-top: 0;
    margin-bottom: 0;
    padding: 20px 40px;
    font-family: 'Publico Headline',Georgia,serif;
    font-size: 24px
}

.axa-diagnostic-tool__step-content {
    padding: 0 40px 20px;
    font-family: "SourceSansPro",Arial,sans-serif;
    font-weight: 400;
    font-size: .875rem;
    line-height: 1.5em;
    letter-spacing: .01em;
    width: auto
}

@media (min-width:48em) {
    .axa-diagnostic-tool__step-content {
        font-size: 1.125rem;
        line-height: 1.5em;
        letter-spacing: .01em
    }
}

@media (min-width:62em) {
    .axa-diagnostic-tool__step-content {
        font-size: 1.125rem;
        line-height: 1.5em;
        letter-spacing: .01em
    }
}

@media (min-width:75em) {
    .axa-diagnostic-tool__step-content {
        font-size: 1.125rem;
        line-height: 1.5em;
        letter-spacing: .01em
    }
}

.axa-diagnostic-tool__step-content .btn {
    margin: 30px 0
}

.axa-diagnostic-tool__step-content + .axa-diagnostic-tool__selector-wrapper {
    padding-top: 0
}

.axa-diagnostic-tool__selector-wrapper:first-child, .axa-diagnostic-tool__step-content:first-child {
    border-top: 1px solid #00008f;
    padding: 20px 0;
    margin: 10px 40px;
    width: auto
}

.axa-diagnostic-tool__selector-button {
    font-family: "SourceSansPro",Arial,sans-serif;
    font-weight: 400;
    font-size: .875rem;
    line-height: 1.5em;
    letter-spacing: .01em;
    margin: 0 0 10px;
    width: 100%;
    height: 50px;
    background-color: #fff;
    border: 2px solid #00008f;
    border-radius: 3px;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #00008f;
    text-align: center;
    vertical-align: middle;
    padding: 10px 15px;
    text-decoration: none;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-transform: none;

    &:after {
        color: #fff;
        background: #00008f;
    }

    &:hover {
        background: #00008f;
        color: #fff;
    }

    &:focus {
        color: #00008f;
    }
}

    @media (min-width:48em) {
        .axa-diagnostic-tool__selector-button {
            font-size: 1.125rem;
            line-height: 1.5em;
            letter-spacing: .01em
        }
    }

    @media (min-width:62em) {
        .axa-diagnostic-tool__selector-button {
            font-size: 1.125rem;
            line-height: 1.5em;
            letter-spacing: .01em
        }
    }

    @media (min-width:75em) {
        .axa-diagnostic-tool__selector-button {
            font-size: 1.125rem;
            line-height: 1.5em;
            letter-spacing: .01em;
        }
    }

     .axa-diagnostic-tool__selector-wrapper {
        border: 0;
        padding: 20px 40px;
        margin: 0
    }

    .axa-diagnostic-tool__selector-wrapper:hover {
        background-color: #fafafa
    }

    .axa-diagnostic-tool__selector-btn-wrapper {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    .axa-diagnostic-tool__selector-question {
        font-family: "SourceSansPro",Arial,sans-serif;
        font-weight: 400;
        font-size: .875rem;
        line-height: 1.5em;
        letter-spacing: .01em;
        padding: 10px 0 15px
    }

    @media (min-width:48em) {
        .axa-diagnostic-tool__selector-question {
            font-size: 1.125rem;
            line-height: 1.5em;
            letter-spacing: .01em
        }
    }

    @media (min-width:62em) {
        .axa-diagnostic-tool__selector-question {
            font-size: 1.125rem;
            line-height: 1.5em;
            letter-spacing: .01em
        }
    }

    @media (min-width:75em) {
        .axa-diagnostic-tool__selector-question {
            font-size: 1.125rem;
            line-height: 1.5em;
            letter-spacing: .01em
        }
    }

    .axa-diagnostic-tool__section {
        position: relative;
        border: 1px solid #00008f;
        border-radius: 3px;
        font-family: "SourceSansPro",Arial,sans-serif;
        font-weight: 400;
        font-size: .875rem;
        line-height: 1.5em;
        letter-spacing: .01em
    }

    @media (min-width:48em) {
        .axa-diagnostic-tool__section {
            font-size: 1.125rem;
            line-height: 1.5em;
            letter-spacing: .01em
        }
    }

    @media (min-width:62em) {
        .axa-diagnostic-tool__section {
            font-size: 1.125rem;
            line-height: 1.5em;
            letter-spacing: .01em
        }
    }

    @media (min-width:75em) {
        .axa-diagnostic-tool__section {
            font-size: 1.125rem;
            line-height: 1.5em;
            letter-spacing: .01em
        }
    }

    .axa-diagnostic-tool__section p + p {
        margin-top: 5px
    }

    .axa-diagnostic-tool__section ul {
        padding: 10px 0;
        margin-bottom: 0
    }

    .axa-diagnostic-tool__section::after {
        content: "";
        position: absolute;
        top: -6px;
        left: calc(50% - 5px);
        width: 10px;
        height: 10px;
        background: inherit;
        background-color: #fff;
        border: 1px solid #00008f;
        border-top: 0;
        border-right: 0;
        border-radius: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        -webkit-transform: rotate(135deg);
        -ms-transform: rotate(135deg);
        transform: rotate(135deg)
    }

    .axa-diagnostic-tool__section .axa-diagnostic-tool__section .axa-diagnostic-tool__step-title {
        margin: 0 40px;
        border-top: 1px solid #00008f;
        padding: 20px 0
    }

    .axa-diagnostic-tool__section .axa-diagnostic-tool__section::after {
        top: -5px
    }

    .axa-diagnostic-tool__phone-icon {
        fill: #00008f
    }

    .axa-diagnostic-tool__icon {
        height: 100px;
        width: 100px;
        fill: #00008f
    }

    .axa-diagnostic-tool__section-divider {
        margin: 0 40px;
        border-top: 1px solid #00008f
    }

    .axa-diagnostic-tool__section-divider .axa-diagnostic-tool__step-title {
        padding: 20px 0
    }

    .axa-diagnostic-tool__section-divider + .axa-diagnostic-tool__selector-wrapper {
        padding-top: 0
    }

    section section {
        border: 0 !important
    }

    .axa-diagnostic-tool__selector-button--icon {
        min-width: unset;
        margin: 5px;
        width: 150px;
        min-height: 190px;
        height: auto;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        -webkit-justify-content: flex-start;
        justify-content: flex-start
    }

    .axa-diagnostic-tool__selector-button--icon:hover {
        background-color: #00008f
    }

    .axa-diagnostic-tool__selector-button--icon:hover .axa-diagnostic-tool__icon {
        fill: #fff
    }

.axa-diagnostic-tool__selector-button--selected {
    background: #00008f;
    color: #fff;
}

    .axa-diagnostic-tool__selector-button--selected:focus {
        color: #fff
    }

    .axa-diagnostic-tool__selector-button--selected .axa-diagnostic-tool__icon, .listicle-content__arrow svg {
        fill: #fff
    }

    @media (min-width:48em) {
        .axa-diagnostic-tool__step-title {
            font-size: 24px
        }

        .axa-diagnostic-tool__step-content, .axa-diagnostic-tool__step-title {
            padding-left: 60px;
            padding-right: 60px
        }

        .axa-diagnostic-tool__selector-wrapper:first-child, .axa-diagnostic-tool__step-content:first-child {
            margin: 10px 60px
        }

        .axa-diagnostic-tool__selector-wrapper {
            padding: 20px 60px
        }

        .telephone {
            font-size: 24px
        }

        .axa-diagnostic-tool__section-divider {
            margin: 0 60px
        }

        .axa-diagnostic-tool__selector-btn-wrapper {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-box-pack: start;
            -webkit-justify-content: flex-start;
            -ms-flex-pack: start;
            justify-content: flex-start
        }

        .axa-diagnostic-tool__selector-button {
            min-width: 130px;
            width: auto;
            margin-right: 20px;
            padding: 10px 15px;
            height: 50px;
            font-size: 16px
        }

        .axa-diagnostic-tool__selector-button--icon {
            margin-right: 5px;
            padding: 10px 15px;
            width: 150px;
            min-height: 190px;
            height: auto
        }

        .axa-diagnostic-tool__selector-button--icon:hover {
            background-color: #00008f
        }

        .axa-diagnostic-tool__selector-button--icon:hover .axa-diagnostic-tool__icon {
            fill: #fff
        }

        .axa-diagnostic-tool__section .axa-diagnostic-tool__section .axa-diagnostic-tool__step-title {
            margin: 0 60px
        }

        .axa-diagnostic-tool__section::after {
            left: 20%
        }
    }
