//==========================================================================================
//
//  Image Component
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================
.image-component {
    width: 100%;
    max-width: unset;
    margin: 50px auto;
    display: block;
    overflow: hidden;
}

.image-component__image {
    width: 100%;
    height: auto;
    max-width: $content-max-width-768;
    margin: 0 auto;
    display: block;
}

.image-component__caption {
    @include typography(typo-16, normal, $root-font-family);
    text-align: center;
    margin: 15px 0;
    color: color(dark-grey);
    max-width: $content-max-width-768;
    padding: 0 40px;
    margin: 15px auto 0 auto;
    display: block;
}

//==========================================================================================
//  Modifiers
//==========================================================================================
//==========================================================================================
// Gutters
// Styles image to be full width with gutter spacing
//==========================================================================================
.image-component--gutters {
    max-width: calc(100% - 60px);
    height: 0;
    padding-bottom: 50%;
    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    .image-component__image {
        opacity: 0;
        max-width: none;
        padding: 0;
    }
}

//==========================================================================================
// Full
// Styles image to be full width with no gutter spacing
//==========================================================================================
.image-component--full {
    max-width: 100%;
    height: 0;
    padding-bottom: 100%;
    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    .image-component__image {
        opacity: 0;
        max-width: none;
        padding: 0;
    }

    .image-component__caption {
        width: 100%;
        height: auto;
        margin: 0;
        position: absolute;
        bottom: 20px;
        color: color(plain-white);
        left: 50%;
        transform: translateX(-50%);
    }
}

//==========================================================================================
// Half
// Styles image to be full width with no gutter spacing
//==========================================================================================
.image-component--half {
    max-width: 100%;
    padding: 0;

    .image-component__container {
        width: 100%;
        height: auto;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
    }

    .image-component__item {
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        position: relative;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        display: block;
        margin-bottom: 3px;

        &:nth-child(2) {
            margin-bottom: 0;
        }
    }

    .image-component__image {
        opacity: 0;
        max-width: none;
        padding: 0;
    }

    .image-component__caption {
        width: 100%;
        height: auto;
        margin: 0;
        position: absolute;
        bottom: 20px;
        color: color(plain-white);
        left: 50%;
        transform: translateX(-50%);
    }
}

//==========================================================================================
// Full To Thin
// Styles image to be full width on desktop and 16*9 on mobile
//==========================================================================================
.image-component--full-to-thin {
    height: 0;
    /* Aspect ration added for 16:9 */
    padding-top: 56.25%;
    padding-left: 0;
    padding-right: 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;


    .image-component__image {
      display: none;
      max-width: none;
      padding: 0;
    }

}


//==========================================================================================
//  Media Queries
//==========================================================================================
@include mq($from: screen768) {

    .image-component {
        margin: 80px auto;
        padding-left: 40px;
        padding-right: 40px;
    }

    .image-component__image {
        padding-left: 40px;
        padding-right: 40px;
    }

    .image-component__caption {
        margin-top: 20px;
    }

    .image-component--gutters {
        background-image: unset;
        max-width: calc(100% - 100px);
        height: auto;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;

        .image-component__image {
            display: block;
        }
    }

    .image-component--full-to-thin {
        height: auto;
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;
        background-image: unset;

        .image-component__image {
            display: block;
        }
    }

    .image-component--half {
        max-width: none;
        padding-left: 0;
        padding-right: 0;
        background-image: unset;

        .image-component__item {
            height: auto;
            padding-bottom: 0;
            margin: 0 1px 0 0;
            width: calc(50% - 1px);

            &:after {
                content: "";
                display: block;
                padding-top: 70%;
            }

            &:nth-child(2) {
                width: 50%;
                margin: 0;
            }
        }

        .image-component__image {
            display: block;
            padding: 0;
        }
    }

    .image-component--full {
        padding-left: 0;
        padding-right: 0;
        background-image: unset;

        .image-component__caption {
            bottom: 35px;
        }
    }

}

@include mq($from: screen960) {

    .image-component__caption {
        margin-top: 15px;
    }

    .image-component--half {
        max-width: none;
    }

    .image-component--gutters {
        max-width: calc(100% - 80px);;
    }


    .image-component--full {
        max-width: none;
        height: auto;
        padding-bottom: 0;

        .image-component__image {
            height: auto;
            opacity: 1;
        }
    }
}

@include mq($from: screen1140) {
    .image-component {
        margin: 80px auto;
    }
}