//==========================================================================================
//
//  Pagination
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================
.pagination__items{
    overflow-x: auto;
    display: flex;
    max-width: 100%;
    padding: 0;
    margin: 0;
    border-bottom: 1px solid color(mercury);
}

.pagination__item{
    margin: 0;
    list-style: none;
    display: flex;
}

.pagination__link{
    @include typography(typo-13, 700, $root-font-family);
    color: color(light-grey);
    padding: 15px;
    min-width: 25px;
    text-align: center;

    &:hover, &:focus{
        color: color(burnt-sienna);
        border-bottom: 2px solid color(burnt-sienna);
    }
}

.pagination__link[aria-current]{
    color: color(burnt-sienna);
    border-bottom: 2px solid color(burnt-sienna);
}

.pagination__link[aria-disabled="true"]{
    color: color(mercury);
    pointer-events: none;
}

.pagination__icon-next, .pagination__icon-prev{
    padding: 0;
    margin: 0;
    width: 10px;
    height: 10px;
}

.pagination__icon-prev{
    transform: rotate(180deg);
}


//==========================================================================================
//  Modifiers
//==========================================================================================
//==========================================================================================
//  Pagination arrow styles
//==========================================================================================
.pagination__link--arrow{
    color: color(burnt-sienna);
    padding: 15px 0;
    margin: 0;

    &:hover, &:focus{
        border-bottom: 0;
    }
}

//==========================================================================================
//  Pagination arrow styles
//==========================================================================================
.pagination__link--previous{
    text-align:left;
}

.pagination__link--next{
    text-align:right;
}