//==========================================================================================
//
//  Document Table
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================
.doc-recommendations {
    width: 100%;
    height: auto;
    padding: 40px 20px;
    overflow: hidden
}

.doc-recommendations__intro {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto
}

.doc-recommendations__intro-title {
    text-align: center;
    margin-bottom: 40px
}

.doc-recommendations-item__title-link {
    text-decoration: none
}

.doc-recommendations__content-wrapper {
    width: 100%;
    height: auto;
    max-width: 1200px;
    margin: 0 auto
}

.doc-recommendations__content-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: -30px
}

.doc-recommendations-item {
    width: 100%;
    padding: 0;
    margin-bottom: 50px
}
.doc-recommendations-item-wrapper {
    width: 80%;
}


.doc-recommendations__content {
    width: 100%;
    height: auto;
}

    .doc-recommendations-item__image {
        height: auto;
        display: block;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        margin-bottom: 15px;
    }

    .doc-recommendations-item .anchor {
        margin-top: 0;
    }

    .document-library-block-download-icon-flamingo {
        fill: color(burnt-sienna);
        height: 18px;
        width: 18px;
        position: absolute;
        margin-top: 0;
        margin-left: 5px;
    }

@media (max-width:48em) {
    .doc-recommendations-item-wrapper {
        width: 100%;
    }
}

    @media (min-width:48em) {
        .doc-recommendations-item {
            width: 50%;
            padding: 0 20px
        }

        .doc-recommendations-item:nth-child(odd) {
            padding-left: 0
        }

        .doc-recommendations-item:nth-child(even) {
            padding-right: 0
        }

        .doc-recommendations__intro-title {
            margin-top: 0
        }
    }

    @media (min-width:60em) {
        .doc-recommendations {
            padding: $component-padding-lg;
        }
    }

    @media (min-width:67.5em) {
        .doc-recommendations-item {
            width: 25%
        }

        .doc-recommendations-item:nth-child(1) {
            padding-left: 0;
            padding-right: 30px
        }

        .doc-recommendations-item:nth-child(2) {
            padding-left: 10px;
            padding-right: 20px
        }

        .doc-recommendations-item:nth-child(3) {
            padding-left: 20px;
            padding-right: 10px
        }

        .doc-recommendations-item:nth-child(4) {
            padding-left: 30px;
            padding-right: 0
        }

        .doc-recommendations__base-link {
            text-align: right
        }
    }

    .doc__download__filetype {
        font-family: "SourceSansPro",Arial,sans-serif;
        font-weight: 400;
        font-size: .875rem;
        letter-spacing: .02em;
        text-transform: uppercase;
        color: #aeb1b1;
    }