//==========================================================================================
//
//  Repository
//
//==========================================================================================

.content--right {
    padding: 0;
}

.article-repository {
    display: block;
    margin: 0;
}

.article-wrapper {
    display: flex;
    flex-direction: column;
}

.article-wrapper .content--right {
    order: 1;
}

@media (min-width: 60em) {
   .article-wrapper .content--right {
        order: 2;
    }
}

@media (min-width: 62em) {


    .repository {
        background: linear-gradient(90deg,#fafafa 10%,#black 25%);
        position: relative;
        overflow: hidden;
        /*margin: 30px 0;*/
    }

    .twocol-wrapper {
        max-width: 1200px;
        margin: 0 auto;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        background-color: color(plain-white);
        position: relative;
        padding-top:50px;
    }

    .twocol-wrapper {
        display: flex;
    }
   

    .content-container {
        display: flex;
        flex-flow: initial;
        padding: 0 20px;
    }

    .content--right {
        width: 100%;
    }
    .repository .content--right {
        padding: 0 50px;
        width: 70%;
    }
}


@media (min-width: 87.5em) {
    .repository .content--right {
        width: 75%;
    }

    .repository {
        background: linear-gradient(90deg,#fafafa 20%,#ffffff 25%);
    }
}


