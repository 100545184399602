//==========================================================================================
//
//  Static Promo
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================
.static-promo{
    display:block;
    width:100%;
    margin-bottom:40px;

    &:last-child{
        margin-bottom:0;
    }
}

.static-promo__copy{
    padding:40px 25px;
}

.static-promo__image{
    background-size:cover;
    background-position: center;
    // maintain aspect ratio
    padding-top:52.65%;
    min-height:220px;
}

.static-promo__title{
    margin-bottom:13px;
}

.static-promo__desc{
    @include typography(typo-18, normal, $root-font-family);
    display:block;
    margin-bottom:30px;
    &+.btn {
        margin-left: 0;
    }
}

.static-promo__button{
    @include typography(typo-14, normal, $root-font-family);
}

//==========================================================================================
//  Media Queries
//==========================================================================================
@include mq($from: screen960) {

    .static-promo {
        margin-bottom:100px;
    }

    .static-promo__copy{
        padding:40px;
    }

    .static-promo__desc{
        margin-bottom:40px;
    }
}
