//==========================================================================================
//
//  Split Text Panel
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================
.split-text-panel {
    width: 100%;
    height: auto;
    position: relative;
}

.split-text-panel .intro-text {
    padding-top: 40px;
    padding-bottom: 40px;
}

.split-text-panel__background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: none;
    flex-flow: row wrap;
}

.split-text-panel__background-item {
    width: 50%;
    height: auto;
}

.split-text-panel__container {
    width: 100%;
    max-width: $standard-max-width;
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap;
}

.split-text-panel__item {
    padding: $component-padding-sm;
    width: 100%;
}

.split-text-panel__item-container{
    height:100%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.split-text-panel__btn-wrapper {
    margin-top: 15px;
    margin-left: -$btn-margin;

    // Need following flex styles so that buttons align regardless of content above
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .btn,
    .anchor {
        align-self: flex-start;
    }
}

//==========================================================================================
//  Media Queries
//==========================================================================================

@include mq($from: screen768) {
    .split-text-panel__item {
        width: 50%;

        &:first-child {
            padding-right:60px;
        }

        &:last-child {
            padding-left:60px;
        }
    }

    .split-text-panel__background {
        display: flex;
    }

    .split-text-panel__btn-wrapper {
        margin-top: 30px;
    }
}

@include mq($from: screen960) {
    .split-text-panel__item {
        padding: $component-padding-lg;
    }
}