//==========================================================================================
//
//  Article Image
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================
.article-image {
    width: 100%;
    height: auto;
    display: block;
    max-width: 768px;
    margin: 50px auto;
}

.article-image__caption {
    width: 100%;
    height: auto;
    margin: 0 auto;
    max-width: 600px;
    transform: translateY(-30px);
    text-align: center;
    display: block;
    padding: 0 40px;
}

//==========================================================================================
//  Modifiers
//==========================================================================================
/**
    Name: Full Width
    Description: Creates a full width image
**/
.article-image--full {
    width: 100vw;
    position: relative;
    margin-left: -50vw;
    max-width: none;
    left: 50%;
}

.article-image--gutters {
    width: 100vw;
    position: relative;
    margin-left: -50vw;
    max-width: none;
    left: 50%;
    padding: 0 40px;
}

.article-image--half {
    width: 49%;
    padding: 0;
    margin: 0;
    display: inline-block;
    position: relative;
    margin-left: -25vw;
    left: 25%;
    max-width: none;
}


//==========================================================================================
//  Media Queries
//==========================================================================================
@include mq($from: screen720) {
    .article-image {
        padding: 0 40px;
    }

    .article-image {
        margin: 80px auto;
    }

    .article-image--full {
        padding: 0;
        margin-left: -50vw;
    }

    .article-image--gutters {
        margin-left: -50vw;
    }

    .article-image--half {
        padding: 0;
    }

    .article-image__caption {
        transform: translateY(-50px);
    }

}

@include mq($from: screen960) {

    .article-image__caption {
        transform: translateY(-60px);
    }

}