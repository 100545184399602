//==========================================================================================
//
//  Media query config
//
//==========================================================================================

//==========================================================================================
//  SASS MQ library is used
//  http://sass-mq.github.io/sass-mq/
//  Axa Brand Guidelines: https://design.axa.com/web-guidelines/structure
//==========================================================================================
$mq-responsive: true;
$mq-breakpoints: (
    screen320: 320px, // Mobile
    screen400: 400px,
    screen480: 480px,
    screen600: 600px,
    screen640: 640px, // Tablesaw.js breakpoint
    screen720: 720px, // Tablet
    screen768: 768px,
    screen790: 790px,
    screen960: 960px, // Desktop
    screen992: 992px,
    screen1000: 1000px,
    screen1080: 1080px, // Added for mega nav
    screen1140: 1140px, // Full Desktop
    screen1200: 1200px,
    screen1400: 1400px,
    screen1600: 1600px,
    screen1800: 1800px
);
$mq-show-breakpoints: (screen400,screen480,screen600,screen720,screen768,screen790,screen960,screen992,screen1000,screen1080,screen1140,screen1200,screen1400,screen1600,screen1800);


//==========================================================================================
//  CSS hooks for breakpoints
//  These are retrieved in JS through use of our Component Library "getBreakpoint" module
//==========================================================================================
body::before {
    display: none;
}

@each $breakpoint, $size in $mq-breakpoints {
    @include mq($breakpoint) {
        body::before {
            content: "#{$breakpoint}";
        }
    }
}