//==========================================================================================
//
//  Contact Form Panel
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================

/* Styles for the container copied from split image text panel */
.contact-form-panel {
    width: 100%;
    height: auto;
    position: relative;
}

.contact-form-panel__background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: none;
    flex-flow: row wrap;
}

.contact-form-panel__background-item {
    width: 50%;
    height: auto;
}

.contact-form-panel__container {
    width: 100%;
    max-width: $standard-max-width;
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap;
    padding: 0 0;
    overflow: hidden;
}

.contact-form-panel__item {
    position: relative;
    width: 100%;
    padding: $section-vpadding-sm;
    display: flex;
    flex-direction: column;

    form {
        width: 100%;
    }

    &:first-child {
        z-index: 1;
    }

    &:last-child {
        padding-top:20px;
    }
}

.contact-form-panel__item-container {
    padding: $section-hpadding-sm;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    min-height:unset;

    & .Form__Title{
        @include typography(typo-18, normal, $root-font-family);
    }
}


/* Panels */
.contact-form-panel {
    width: 100%;
    height: auto;
    position: relative;
}

/*Hide EPI form elements that can't be omitted from the markup as per EPI team advice*/
.epi-form--hide-title .Form__Title{
    display:none;
}

.epi-form--hide-description .Form__Description{
    display:none;
}

//==========================================================================================
//  Media Queries
//==========================================================================================
@include mq($from: screen768) {
    .contact-form-panel__background {
        display: flex;
    }

    .contact-form-panel__item {
        padding: $section-vpadding-sm;
        width: 50%;

        &:last-child {
            padding-top:40px;
        }
    }

    .contact-form-panel__item-container{
        margin-top:auto;
        margin-bottom:auto;
        padding: $section-hpadding-lg;
    }

    .contact-form-panel__item {
        &:first-child {
            padding-right: 20px;
        }

        &:last-child {
            padding-left: 20px;
        }
    }
}

@include mq($from: screen960) {
    .contact-form-panel {
        padding: $section-hpadding-lg;
    }

    .contact-form-panel__item-container{
        padding: 0;
    }

    .contact-form-panel__item {
        &:first-child {
            padding-right: 80px;
        }

        &:last-child {
            padding-left: 80px;
        }
    }
}