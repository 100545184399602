//==========================================================================================
//
//  Page layout
//
//==========================================================================================

//==========================================================================================
//  Containers
//==========================================================================================
body {
    width:100%;
}

html {
    overflow-x: hidden;
}

.main {
    //  Account for possible borders with additional 2px
    width: 100%;
    /* Menu Size Offset for main wrapper */
    margin-top: 130px;
    position: relative;
}

.component {
    position: relative;
    margin: 0 auto;

    width: design-grid(12);
    padding: 80px 0;
}

.component + .component {
    padding: 0 0 80px;
}


//==========================================================================================
//  Modifiers
//==========================================================================================
.component--no-padding {
    //  Important required to avoid duplication in media queries
    padding: 0 !important;
}


.component--full-width {
    //  Important required to avoid duplication in media queries
    width: calc(100vw - (100vw - 100%));
    // margin-left: calc((100vw - 100%) / -2);
}

.component--wide {
    position: relative;
    width: 100%;
}

.component--narrow {
    // Styles not required for mobile
}


//==========================================================================================
//  Media queries
//==========================================================================================
@include mq($from: screen720) {
    .main {
        /* Menu Size Offset for main wrapper */
        margin-top: 130px;
    }
}

@include mq($from: screen768) {
    .main {
        /* Menu Size Offset for main wrapper */
        margin-top: 130px;
    }
}

@include mq($from: screen1140) {
    .main {
        /* Menu Size Offset for main wrapper */
        margin-top: 120px;
    }
}

@include mq(screen1200) {
    .component--narrow {
        width: design-grid(6);
    }
}

@include mq(screen1000) {
    .component {
        padding: design-grid(0.5) 0;
    }
    .component + .component {
        padding: 0 0 80px;
    }
    .component--narrow {
        width: design-grid(8);
    }
}

@include mq(screen768) {
    .component--narrow {
        width: design-grid(10);
    }
}
