//==========================================================================================
//
//  Contact Panel
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================

.contact-panel {
    .intro-text {
        margin-bottom: 30px;
    }
}

.contact-panel-item {
    display: block;
    text-decoration: none;
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
    overflow-wrap: break-word;
    word-wrap: break-word;
    padding: 30px 20px;
    margin-bottom: 10px;
    border-radius: 1%;
    -webkit-box-shadow: 0 -1px 6px rgba(0,0,0,.05),1px 0 6px rgba(0,0,0,.05),0 1px 6px rgba(0,0,0,.05),-1px 0 6px rgba(0,0,0,.05);
    box-shadow: 0 -1px 6px rgba(0,0,0,.05),1px 0 6px rgba(0,0,0,.05),0 1px 6px rgba(0,0,0,.05),-1px 0 6px rgba(0,0,0,.05);
    -webkit-transition: background-color .3s ease-in-out;
    transition: background-color .3s ease-in-out;
}

.contact-panel-item:focus, .contact-panel-item:hover {
    outline: 0;
}

.contact-panel-item__container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.contact-panel-item__container .anchor {
    font-size: 18px;
    font-weight: 100;
    text-transform: none;
}

.contact-panel-item__title {
    margin-bottom: 10px;
}

.contact-panel-item-image__container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.contact-panel-item__description {
    font-family: "SourceSansPro",Arial,sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5em;
    letter-spacing: 0;
    margin-bottom: 20px;
    margin-top: 40px;
}

@media (min-width:48em) {

    .contact-panel-item-image__container {
        height: auto;
    }

    .contact-panel-item-content__container {
        height: 100%;
        display:flex;
        flex-direction:column;
    }
    .contact-panel-item__description {
        font-size: 1.5rem;
        line-height: 1.2em;
        letter-spacing: 0;
    }

    .contact-panel-item__cta {
        margin-top: auto;
    }
}

@media (min-width:62em) {
    .contact-panel-item__description {
        font-size: 1.5rem;
        line-height: 1.2em;
        letter-spacing: 0;
    }
}

@media (min-width:75em) {
    .contact-panel-item__description {
        font-size: 1.5rem;
        line-height: 1.2em;
        letter-spacing: 0;
    }
}

.contact-panel-item-wide {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    overflow: hidden;
    overflow-wrap: break-word;
    word-wrap: break-word;
    padding: 40px 25px;
}

.contact-panel-item-wide__title {
    margin: 0 0 20px;
}

.contact-panel-item-wide__description {
    margin-bottom: 30px;
}

.contact-panel-item-wide__link, .contact-panel-item__link {
    margin: auto 0 0;
}

.contact-panel-item--blue {
    color: color(plain-white);
    background-color: color(axa-blue);

    &:hover,
    &:focus {
        color: color(plain-white);
        background-color: color(dark-filter-axa-blue);
    }
}

.contact-panel-item--white {
    color: color(axa-blue);
    background-color: color(plain-white);

    &:hover,
    &:focus {
        color: color(axa-blue);
        background-color: color(axa-silver);
    }
}

@media only screen and (max-width:768px) {
    .contact-panel-item__container {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .contact-panel-item-image__container {
        display: inline-block;
        width: 80px;
        -webkit-align-items: center;
        text-align: center;
        margin-right: 10px;
    }

    .contact-panel-item__image {
        width: 60px;
    }

    .contact-panel-item-content__container {
        display: inline-block;
        width: 190px;
    }

    .contact-panel-item__description {
        margin-top: 0;
        font-size: 1.3rem !important;
    }
}

@media (min-width:48em) {
    .contact-panel-item {
        width: calc(33.3333% - 10px);
        margin-left: 5px;
        margin-right: 5px;
        padding-top: 45px;
        padding-bottom: 35px;
        margin-bottom: 20px;
        -webkit-box-shadow: 0 -2px 10px rgba(0,0,0,.05),2px 0 10px rgba(0,0,0,.05),0 2px 10px rgba(0,0,0,.05),-2px 0 10px rgba(0,0,0,.05);
        box-shadow: 0 -2px 10px rgba(0,0,0,.05),2px 0 10px rgba(0,0,0,.05),0 2px 10px rgba(0,0,0,.05),-2px 0 10px rgba(0,0,0,.05);
    }

    .contact-panel-item-wide {
        width: 100%;
    }

    .contact-panel-item__title {
        margin-bottom: 25px;
    }

    .contact-panel-item__image {
        width: 100px;
        margin: auto;
    }
}

@media (min-width:75em) {
    .contact-panel-item {
        width: calc(25% - 20px);
        margin-left: 10px;
        margin-right: 10px;
    }

    .contact-panel-item-wide {
        width: calc(50% - 14px);
    }
}

.contact-panel {
    padding: 40px 20px;
}

.contact-panel__container {
    max-width: 1200px;
    max-width: calc(1200px + 10px);
    margin: 0 auto -2%;
}

.contact-panel__title-small-print {
    font-family: "SourceSansPro",Arial,sans-serif;
    font-weight: 600;
    font-size: .75rem;
    line-height: 1.5em;
    letter-spacing: .02em;
    display: block;
    margin-bottom: 10px;
}

@media (min-width:48em) {
    .contact-panel__title-small-print {
        font-size: 1rem;
        line-height: 1.5em;
        letter-spacing: .02em;
    }
}

@media (min-width:62em) {
    .contact-panel__title-small-print {
        font-size: 1rem;
        line-height: 1.5em;
        letter-spacing: .02em;
    }
}

@media (min-width:75em) {
    .contact-panel__title-small-print {
        font-size: 1rem;
        line-height: 1.5em;
        letter-spacing: .02em;
    }
}

.contact-panel__title {
    margin-top: 0;
    margin-bottom: 30px;
}

.contact-panel__list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@media (min-width:48em) {
    .contact-panel__list {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    }

    .contact-panel__title {
        margin-left: 10px;
    }
}

@media (min-width:60em) {
    .contact-panel {
        padding: 40px;

        .intro-text {
            margin-bottom: 40px;
        }
    }
}