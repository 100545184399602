//==========================================================================================
//
//  Article Tags Component
//
//==========================================================================================

//==========================================================================================
//  Base Styles
//==========================================================================================
.article-tags {
    width: 100%;
    margin: 50px auto;
    padding: 0 40px;
}

.article-tags__content {
    @include typography(typo-14, 700, $root-font-family);
    width: 100%;
    height: auto;
    text-transform: uppercase;
    display: flex;
    flex-flow: row wrap;
    margin: 0 auto;
    max-width: $content-max-width-768;


    a {
        text-decoration: none;
        margin-right: 10px;
        color: color(burnt-sienna);

        &:hover, &:focus {
            color: color(dark-filter-burnt-sienna);
        }
    }
}

.article-tags__title {
    margin-right: 10px;
    font-weight: normal;
}


//==========================================================================================
//  Media Queries
//==========================================================================================
@include mq($from: screen768) {
    .article-tags__content {
        padding: 0 40px;
    }
}