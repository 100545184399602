//==========================================================================================
//
//  Photo Reel
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================

.photo-reel {
    padding: $component-padding-sm;
    width: 100%;
    display: block;
    overflow: hidden;
}

.photo-reel__wrapper {
    width: 100%;
    padding: 0;
    position:relative;
    max-width: $standard-max-width;
    height: auto;
    display: block;
    overflow: visible;
    margin: 0 auto;
}

.photo-reel__container {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    align-items: center;
    overflow: visible !important;

    &:after {
        content: "";
        display: block;
        clear: both;
    }
}

.photo-reel__item {
    width: 100%;
    padding: 0 15px 20px 15px;
    position: relative;
    display: block;
}


.photo-reel__image-container {
    width: 100%;
    height: auto;
}

.photo-reel__image {
    width: 100%;
    padding-right: 0;
    /* Height 0 set for aspect ratio effect */
    height: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: block;
    padding-top: 56.25%;
}

.photo-reel__content {
    width: 100%;
    padding: 40px 0 0 0;
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    min-height: 250px;
    p+p {
        margin-top: 10px;
    }
}


.photo-reel__btn-wrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin-top: 30px;
    margin-left: -$btn-margin;
    .anchor--btn {
        margin: 0 $btn-margin;
    }
}


.photo-reel__paginate-item{

    &:first-child, &:last-child {
        display: none;
    }
}

.photo-reel__controls{
    position:relative;
}

.photo-reel__control{
    top:20%;
    position: absolute;
    z-index:50;
    min-width:10px;
    opacity:1;
    display: flex;
    align-content: center;
    justify-content: center;
    opacity: 0.4;

    &:hover{
        opacity:1;
    }
}

.photo-reel__control--prev{
    left:-10%;
    display:none;

}

.photo-reel__control--next{
    right:-10%;
    display:none;


}


.photo-reel__prev-svg{
    fill: color(plain-white);
    color: color(plain-white);
    height:25px;
    width: 25px;
    display:flex;
    align-content: center;
    justify-content: center;
}


//==========================================================================================
//  Modifiers
//==========================================================================================
//==========================================================================================
//  Flip Content
//==========================================================================================
.photo-reel--flip{
    .photo-reel__content {
        order:-1;
    }
}

//==========================================================================================
//  Media Queries
//==========================================================================================
@include mq($from: screen600) {
    .photo-reel {
        padding: $section-vpadding-lg;
    }

    .photo-reel__wrapper {
        padding: 0 30px;
    }

    .photo-reel__item {
        padding: 0 10px 20px 10px;
    }
}

@include mq($from: screen960) {
    .photo-reel {
        padding: $component-padding-lg;
    }
}

@include mq($from: screen1000) {


    .photo-reel__container {
        width:100%;
        overflow: hidden !important;
    }

    .photo-reel__wrapper {
        width: 100%;
        padding: 0;
        overflow: hidden;
    }

    .photo-reel__item{
        display:flex;
        flex-direction: row;
    }

    .photo-reel__image-container{
        width:50%;
        display:inline-block;
    }

    .photo-reel__content{
        width:50%;
        display:inline-block;
        padding: 20px 0 20px 60px;
    }

    .photo-reel--flip {
        .photo-reel__content {
            padding: 20px 60px 20px 0;
        }
    }

    .photo-reel__paginate-container{
        margin-top:20px;
    }

    .photo-reel__control{
        top:30%;
    }

    .photo-reel__control--prev{
        left:0;
    }

    .photo-reel__control--next{
        right:0;
    }
}