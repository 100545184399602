//==========================================================================================
//
//  Press Release Summary
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================
.press-release-summary__container{
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
    transition: all 0.3s ease-in-out;
    min-height: 110px;

    &:hover, &:focus{
        padding-left: 20px;
        background-color: color(medium-grey, 0.15);
        outline: none;
    }
}

.press-release-summary__date{
    @include typography(typo-14, normal, $root-font-family);
    text-transform: uppercase;
}

.press-release-summary__time{
    @include typography(typo-14, normal, $root-font-family);
    text-transform: uppercase;
}

.press-release-summary__title{
    margin-bottom: 5px;
}

.press-release-summary__description{
    @include typography(typo-16, normal, $root-font-family);
}

//==========================================================================================
//  Media Queries
//==========================================================================================
@include mq($from: screen768) {
    .press-release-summary__container{
        flex-direction: row;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .press-release-summary__date{
        @include typography(typo-16, normal, $root-font-family);
        width: 20%;
        min-width: 200px;
        padding: 0 30px;
        margin-top: 5px;
        text-transform:none;
    }

    .press-release-summary__title{
        margin-bottom: 15px;
    }

    .press-release-summary__description{
        @include typography(typo-14, normal, $root-font-family);
    }
}