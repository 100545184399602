//==========================================================================================
//
//  Mega Menu Mobile
//
//==========================================================================================

//==========================================================================================
//  Base Styles
//==========================================================================================
.mega-menu-mobile {
    /* Set to position absolute to help with the overflow issue of
    translateY preventing the user from clicking the first item on the page */
    position: absolute;
    top: -100%;

    width: 100%;
    background-color: color(plain-white);
    overflow: hidden;
    display: block;
    visibility: hidden;
    transform: translateY(-100%);
    z-index: 0;
    pointer-events: none;
    border-top: 1px color(mercury) solid;
    border-bottom: 1px color(mercury) solid;
    [data-primary-nav-item] > a{
        line-height: 35px;
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

[data-active].mega-menu-mobile {
    display: block;
    pointer-events: all;
    visibility: visible;
    /* Reset back to position relative */
    position: relative;
    top: 0;
}

.mega-menu-mobile__list {
    max-width: 750px;
    margin: 0 auto;
    list-style: none;
    padding-left: 0;
    padding: 40px 20px 60px 20px;
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
}

.mega-menu-mobile-tray__info {
    color: color(dark-grey);
    overflow: hidden;

    .anchor--axa-blue {
        display: inline-block;
        color: color(axa-blue);
        margin-top: 10px;
        /* fix for the navigation error */
        &::before {
            display: none;
            visibility: hidden;
        }
    }

    .anchor--flamingo {
        display: inline-block;
        color: color(burnt-sienna);
        margin-top: 10px;
        /* fix for the navigation error */
        &::before {
            display: none;
            visibility: hidden;
        }
    }
}

.mega-menu-mobile__list-item {
    @include typography(mega-menu-mobile, 500, $root-font-family-serif);
    width: 100%;
    height: auto;
    padding-right: 0;
    margin-left: 0;
    position: relative;
    border-bottom: 1px color(axa-silver) solid;
    padding-bottom: 10px;
    padding-top: 10px;
    margin-bottom: 0;
    margin-top: 0;
    display: block;
    overflow: hidden;

    a {
        color: color(axa-blue);



        padding-right: 40px;
        width: 100%;
        display: block;

        .mega-menu-mobile__list-item-plus {
            width: 25px;
            height: 25px;
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 0;
            top: 20px;
            opacity: 1;
            transition: 0.3s opacity ease;
        }

       .mega-menu-mobile__list-item-plus-icon {
           fill: color(axa-silver);
           width: 20px;
           height: 20px;
       }

        &:before {
            position: absolute;
            left: 0;
            top: 22px;
            display: block;
            content: "";
            width: 20px;
            height: 20px;
            opacity: 0;
            transition: 0.3s opacity ease;
            transition-delay: 0.1s;
            background-image: url(/assets/images/svg/mega-nav-arrow.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: 90% auto;
        }
    }
}

/* Active */
[data-active].mega-menu-mobile__list-item {
    border-bottom: none;
    a {

        &:before {
            transition-delay: 1s;
            opacity: 1;
        }
    }

    .mega-menu-mobile__list-item-plus {
        opacity: 0;
    }
}

.mega-menu-mobile__tray {
    width: 100%;
    height: auto;
    max-height: 0;
    visibility: hidden;
}

.mega-menu-mobile__tray-container {
    width: 100%;
    height: auto;
    display: block;
    padding: 0 0 0 0;
}

.mega-menu-mobile__tray-nav {
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    flex-flow: column wrap;
    list-style: none;
    padding-left: 0;
    margin: 0;
}

.mega-menu-mobile__tray-nav-item {
    @include typography(typo-16, normal, $root-font-family);
    font-size: 16px;
    width: auto;
    padding-left: 0;
    margin: 0;

    a {
        @include typography(typo-16, normal, $root-font-family);
        font-size: 16px;
        width: auto;
        display: table;
        text-transform: none;
        padding: 15px 0;
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;
        transition: 0.3s ease all;

        &:after, &:before {
            display: none;
        }
    }
}

[data-active].mega-menu-mobile__tray-nav-item {
    >:first-child {
        border-top: 1px solid color(axa-silver);
        border-bottom: 1px solid color(axa-silver);
    }
    svg {
        opacity: 0;
    }
}

.mega-menu-mobile__subnav {
    width: 100%;
    position: absolute;
    top: 30px;
    left: 0;
    list-style: none;
    min-height: 100%;
    padding-left: 0;
    margin-left: 0;
    transform: translateX(100%);
    visibility: hidden;
    opacity: 0;
}

.mega-menu-mobile__subnav-item {
    margin-left: 0;

    a {
        padding: 8px 0;
    }
}

/* Menu Base */
.mega-menu-mobile__base {
    width: 100%;
    height: auto;
    padding: 10px 20px;
    background-color: color(axa-silver);
}

.mega-menu-mobile__base-list {
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
}

.mega-menu-mobile__base-item {
    a {
        color: color(plain-white);
    }
}

/* Active */
[data-active].mega-menu-mobile__base-item {
    a {
        color: color(axa-blue);
    }
}

/* Media Queries */
@include mq($from: screen600) {
    .mega-menu-mobile__list {
        padding: 60px 40px;
    }

    .mega-menu-mobile__list-item-plus {
        top: 20px !important;
    }
}

@include mq($from: screen768) {

    .mega-menu-mobile__list-item {
        padding-bottom: 0px;
        padding-top: 0;
    }



    .mega-menu-mobile__list-item {
        a {
            &:before {
                top: 21px;
            }
        }
    }

}

@include mq($from: screen1140) {
    /* Disable as desktop nav will be shown */
    .mega-menu-mobile {
        display: none;
    }
}