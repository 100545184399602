//==========================================================================================
//
//  Article Banner
//
//==========================================================================================

//==========================================================================================
//  Base Styles
//==========================================================================================
.article-banner {
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-end;
    padding: 170px 0 0 0;
    position: relative;
    margin-bottom: 45px;
}

.article-banner__image {
    width: 100%;
    height: 170px;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
}

.article-banner__image-opacity {
    height: 100%;
    width: 100%;
    position: absolute;
    display: block;
    background-color: color(color-black);
    opacity: 0;
    display: none;
}

.article-banner__content {
    width: 100%;
    background-color: color(plain-white);
    height: auto;
    display: flex;
    flex-flow: row wrap;
    max-width: $standard-max-width;
    padding: 50px 20px 0 20px;
    position: relative;
    z-index: 1;
}

.article-banner__author {
    @include typography(typo-16, 600, $root-font-family);
    text-transform: uppercase;
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
    color: color(dark-grey);
}

.article-banner__link {
    color: color(axa-blue);
    text-decoration: none;
}

.article-banner__title {
    margin-top: 0;
    text-align: center;
    width: 100%;
    height: auto;
    margin-bottom: 45px;
}

.article-banner__base {
    width: 100%;
    height: auto;
    overflow: hidden;
    position: relative;
    z-index: 1;
    margin-bottom: -10px;

    &:before {
        width: 90%;
        height: 2px;
        background-color: color(axa-silver, 0.6);
        display: block;
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        z-index: 1;
    }
}
.article-banner__base-container{
    text-transform: uppercase;
    width: auto;
    display: table;
    height: auto;
    padding: 0 40px;
    margin: 0 auto;
    background-color: color(plain-white);
    z-index: 1;
    position: relative;
    align-self: flex-end;
    text-align: center;
}

.article-banner__subtitle {
    @include typography(typo-16, 600, $root-font-family);
    color: color(axa-blue);
    margin-bottom: 0;
}

.article-banner__date {
    @include typography(typo-16, 600, $root-font-family);
    text-transform: uppercase;
    margin-bottom: 0;
    color: color(dark-grey);
}

//==========================================================================================
//  Media Queries
//==========================================================================================
@include mq($from: screen720) {
    .article-banner {
        padding: 255px 20px 0 20px;
        margin-bottom: 90px;
    }

    .article-banner__image {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
    }

    .article-banner__image-opacity {
        display: block;
    }

    .article-banner__content {
        padding: 60px 40px 0 40px;
        width: 100%;
        margin-left: 20px;
        margin-right: 20px;
    }

    .article-banner__author {
        margin-bottom: 40px;
    }

    .article-banner__title {
        margin-bottom: 60px;
    }
}

@include mq($from: screen960) {
    .article-banner {
        padding-top: 320px;
        margin-bottom: 100px;
    }

    .article-banner__content {
        padding: 40px 60px 0 60px;
    }
}


@include mq($from: screen1140) {
    .article-banner {
        margin-bottom: 80px;
        padding-top: 12%;
    }

    .article-banner__content {
        padding: 60px 60px 0 60px;
    }

    .article-banner__title {
        margin-bottom: 70px;
    }
}