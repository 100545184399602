//==========================================================================================
//
// Two-column
//
//==========================================================================================

.two-column--right {
    padding: 0;
    order: 1;
}

@media (min-width: 45em) {
    .two-column-wrapper {
        padding-top: 50px;
    }
    }



    @media (min-width: 62em) {

        .two-column--right {
            order: 2;
        }

   
        .two-column {
            position: relative;
            overflow: hidden;
        }

        .two-column-wrapper {
            max-width: 1200px;
            margin: 0 auto;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -webkit-flex-flow: row wrap;
            -ms-flex-flow: row wrap;
            flex-flow: row wrap;
            position: relative;
            padding-top: 50px;
        }

        .two-column-wrapper {
            display: flex;
        }

        .content-container {
            display: flex;
            flex-flow: initial;
            padding: 0 20px;
        }

        .two-column--right {
            width: 100%;
        }

        .two-column--right {
            padding-left: 50px;
            width: 70%;
        }

        .side-content-mobile {
            display:none;
        }
    }


    @media (min-width: 87.5em) {
        .two-column--right {
            width: 75%;
        }

    }


