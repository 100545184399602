//==========================================================================================
//
//  magazine banner
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================


.magazine-banner {
    width: 100%;
    height: auto;
    clear: both;
    position: relative;
    overflow: hidden;
    /*background-color: color(dark-grey);*/
    /*border-bottom: 3px solid;*/
}

.magazine-banner-container {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: row wrap;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
}


.magazine-banner-image {
    width: 50%;
    height: auto;
    max-height: 80px;
}


.magazine-banner-description {
    /*color: color(light-grey);*/
    font-weight: 600;
    text-align: center;
    width: 100%;
}


//==========================================================================================
//  Media Queries
//==========================================================================================
@include mq($from: screen720) {
    .magazine-banner-image {
        width: 40%;
    }
}

@include mq($from: screen960) {
    .magazine-banner-image {
        width: 30%;
    }
}

@include mq($from: screen992) {

    .magazine-banner-container {
        max-width: 1200px;
        margin: 0 auto;
        max-width: 1200px;
        margin: 0 auto;
        /*-webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;*/
        /*background-color: color(color-black);*/
        position: relative;
        display: flex;
        align-items: baseline;
        justify-content: center;
        align-items: center;
        padding: 0px 50px;
    }


    .magazine-banner-image {
        width: 40%;
        height: auto;
        max-height: 80px;
    }

    .magazine-banner-logo {
        width: 60%;
    }


    .magazine-banner-description {
        width: 60%;
        text-align: right;
        font-weight: 600;
    }
}

@include mq($from: screen1200) {

    .magazine-banner-container {
        padding: 0px 70px;
    }
}
