//==========================================================================================
//
//  Helper classes
//
//==========================================================================================
.hidden,
.hide {
	display: none !important;
}

.visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px
}

.no-transform {
	transition: none !important;
}

.inactive {
	opacity: 0.6;
	pointer-events: none;
}

/*
    Remove all outlines on focus by default
*/
* {
	&:focus,
	&:active {
		outline-width: 0;
		outline-style: none;
	}
}
/*
    a11y trigger to show outlines only when the user is in a tabbed state
*/
[data-a11y] {
	* {
		&:focus {
			outline-width: 2px;
			outline-style: solid;
			outline-color: rgb(77, 144, 254);
		}
	}
}
