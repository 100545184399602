//==========================================================================================
//
//  Three Column Text
//
//==========================================================================================

//==========================================================================================
//  Base Styles
//==========================================================================================
.three-column-text {
    width: 100%;
    height: auto;
    display: block;
    padding: $component-padding-sm;
    overflow: hidden;
}

.three-column-text__container {
    width: 100%;
    height: auto;
    max-width: $standard-max-width;
    margin: 0 auto;
    margin-top:40px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
}


.three-column-text__column {
    width: 100%;
    height: auto;
    padding: 30px 20px;
    position: relative;

    &:nth-of-type(3n-1) {

        &:after, &:before {
            content: "";
            display: block;
            height: 1px;
            width: calc(100% - 80px);
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            opacity: 0.65;
        }

        &:before {
            top: 0;
        }

        &:after {
            bottom: 0;
        }
    }

    &:first-child{
        padding-top:0;
    }

    &:last-child{
        padding-bottom:0;
    }
}

.three-column-text__title {
    margin-bottom: 20px;
}

.three-column-text__text {
    @include typography(typo-16, normal, $root-font-family);
}

.three-column-text__link {
    margin-top: 30px;
}

//==========================================================================================
//  Media Queries
//==========================================================================================
@include mq($from: screen768) {
    .three-column-text {
        padding-left: 0;
        padding-right: 0;
    }
}

@include mq($from: screen960) {

    .three-column-text__column {
        padding: 0 50px;
        width: 33.3333%;

        /* Hide every second items before line */
        &:nth-of-type(2n) {
            &:before {
                opacity: 0;
            }
        }

        /* Show the before and after of each middle element */
        &:nth-of-type(3n-1) {
            &:before, &:after {
                opacity: 0.65;
                top: 50%;
                height: 30%;
                transform: translateY(-50%);
                width: 1px;
                left: 0;
            }

            &:after {
                right: 0;
                left: auto;
            }
        }

        &:nth-child(even) {
            background-color: inherit;
        }
    }
}

@include mq($from: screen960) {
    .three-column-text {
        padding: $component-padding-lg;
    }
}