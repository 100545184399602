//==========================================================================================
//
//  Anchors
//
//==========================================================================================

//==========================================================================================
//  Base Styles
//==========================================================================================
.anchor {
    @include typography(anchor, bold, $root-font-family);
    display: inline-block;
    text-decoration: none;
    transition: color 0.3s;
    text-transform: uppercase;
    cursor: pointer;
    color: color(axa-blue);

    &:hover, &:focus {
        color: color(dark-filter-axa-blue);
    }
}

.anchor__arrow {
    display: inline-block;
    width: 13px;
    height: 13px;
    vertical-align: middle;
    margin-left: 12px;
    position: relative;
    padding-bottom:15px;

    svg {
        width: 13px;
        height: 13px;
        display: block;
        font-size: inherit;
    }
}


.anchor--text {
    @include typography(typo-18, 600, $root-font-family);
    padding: 0 25px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    small {
        @include typography(typo-12, 600, $root-font-family);
        text-transform: none;
        color: inherit;
    }
}

//==========================================================================================
//  Modifiers
//==========================================================================================
//==========================================================================================
// Icon
// Adjusts the anchor to position the icon
//==========================================================================================
.anchor--icon {

    .anchor__icon{
        display: inline-block;
        width: 18px;
        height: 18px;
        margin-left: 0;
        margin-right: 11px;
        // Minus added to offset the icon -4px from the middle
        margin-top:-4px;
        vertical-align: middle;
        position: relative;
    }

    .anchor__icon-image {
        width: 18px;
        height: 18px;
        display: block;
    }
}

//==========================================================================================
// Flamingo
// Adjusts the anchor to have a flamingo color scheme
//==========================================================================================
.anchor--flamingo {
    color: color(burnt-sienna);

    &:hover, &:focus {
        color: color(dark-filter-burnt-sienna);
    }
}

//==========================================================================================
// Axa Blue
// Adjusts the anchor to have a axa blue color scheme
//==========================================================================================
.anchor--blue {
    color: color(axa-blue);

    &:hover, &:focus {
        color: color(dark-filter-axa-blue);
    }
}

//==========================================================================================
// White
// Adjusts the anchor to have a white color scheme
//==========================================================================================
.anchor--white {
    color: color(plain-white);
    transition: opacity 0.3s ease;

    &:hover, &:focus {
        color: color(plain-white);
        opacity: 0.65;
    }

    svg {
        color: color("plain-white");
    }
}

//==========================================================================================
// Large
// Adjusts the anchor to have a large style to match the large button
//==========================================================================================
.anchor--large {
    line-height: 40px;
}

//==========================================================================================
// Button
// Adjusts the anchor to have a button style
//==========================================================================================
.anchor--btn {
    display: inline-block;
    position: relative;
    width: auto;
    padding: 0 25px;
    line-height: 30px;
    min-width: 180px;
    overflow: hidden;
    z-index: 0;
    cursor: pointer;
    margin-bottom: 5px;
}

//==========================================================================================
//  Media Queries
//==========================================================================================
@include mq($from: screen600) {
    .anchor--large {
        line-height: 50px;
    }
}

.anchor--block-wrapper {
    text-decoration:none;
    color:inherit;
    display:block;

    &:hover{
        color:inherit;
    }
}

.anchor--cover-container {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 0;
}

[data-hero-carousel], [data-reel-carousel] ,[data-responsive-block-carousel] {
    & .anchor--cover-container {
        display:none;
    }
}
