//==========================================================================================
//
//  Cookie Warning
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================
.cookie-warning{
    max-width: 100%;
    background:color(medium-grey);
    display: none;
    z-index: 100;
    padding: 30px 0;
    position: fixed;
    top: 0;
    left: 0;
    bottom: auto;
    overflow: hidden;
    height: auto;
}

.cookie-warning__row{
    max-width: $standard-max-width;
    margin:0px auto;
    padding:0px 40px;
    display:flex;
    flex-flow: column wrap;
    align-items: top;
    width: 100%;
    overflow: hidden;
    justify-content: top;
}

.cookie-warning__col-small{
    width:100%;
    height: auto;
    display: block;
}

.cookie-warning__col-large{
    width:100%;
    height: auto;
    display: block;
}


.cookie-warning__title{
    @include typography(typo-13, 600, $root-font-family);
    margin-bottom:0px;
    text-transform: uppercase;
    color:color(plain-white);
    margin-bottom: 10px;
}

.cookie-warning__copy p {
    @include typography(typo-13, normal, $root-font-family);
    color:color(plain-white);
    a {
        color: inherit;
    }
    &+p {
        margin-top: 10px;
    }
}

.cookie-warning__cta{
    margin-top:20px;
    .btn{
        min-width:135px;
        margin-right:20px;
        margin-left: 0;
    }
}

//==========================================================================================
//  Cookie Wanring Show Based On JS
//==========================================================================================
.cookie-warning[data-cookie-warning="show"] {
    display: block;
}

//==========================================================================================
//  Media Queries
//==========================================================================================


@include mq($from: screen768) {

    .cookie-warning__row{
        flex-flow: row wrap;
    }


    .cookie-warning__col-small{
        width:40%;
        padding-left:30px;
    }

    .cookie-warning__col-large{
        width:60%;
    }

    .cookie-warning__cta{
        margin-top:0;
        text-align:right;
        .btn{
            min-width:90%;
            display:inline-block;
            margin-bottom:0;
            margin-right:0;
        }
    }
}

@include mq($from: screen960) {
    .cookie-warning{
        position: fixed;
        bottom:0;
        top: auto;
        width:100%;
    }

    .cookie-warning__col-small{
        width:30%;
        padding-left:30px;
    }

    .cookie-warning__col-large{
        width:70%;
    }

    .cookie-warning__cta{
        margin-top:20px;

        .btn{
            min-width:160px;
            margin-bottom:0;
            margin-right:0

        }
    }
}