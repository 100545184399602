//==========================================================================================
//
//  Text component
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================
.text-component {
    display: block;
    width: 100%;
    height: auto;
    padding: $component-padding-sm;
}

.text-component__container {
    width: 100%;
    height: auto;
    max-width: $content-max-width-960;
    margin: 0 auto;
    text-align: center;
}

.text-component__btn-wrapper {
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 30px;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    .text-component__btn  {
        margin: $btn-margins;
    }

    .anchor {
        margin: $btn-margins;
        margin-top:30px;
    }
}

//==========================================================================================
//  Media Queries
//==========================================================================================
@include mq($from: screen768) {
    .text-component__btn-wrapper {
        margin-top: 30px;

        .text-component__btn {
            margin-right:30px;
        }

        .anchor{
            margin-top:10px;
        }
    }

    .text-component__btn-wrapper {
        flex-flow: row wrap;
    }
}

@include mq($from: screen960){
    .text-component {
        padding: $component-padding-lg;
    }
}