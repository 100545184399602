//==========================================================================================
//
//  Sticky Header
//
//==========================================================================================
//==========================================================================================
//  Animations
//==========================================================================================
@keyframes animate-arrow-up {
    0% {
        transform: translate(0, 0) scale(1, 1);
        transform: translate3d(0, 0, 0) scale(1, 1);
        opacity: 1
    }

    50% {
        transform: translate(0, -20px) scale(1, 2);
        transform: translate3d(0, -20px, 0) scale(1, 2);
        opacity: 0
    }

    51% {
        transform: translate(0, 20px) scale(1, 2);
        transform: translate3d(0, 20px, 0) scale(1, 2);
        opacity: 0
    }

    100% {
        transform: translate(0, 0) scale(1, 1);
        transform: translate3d(0, 0, 0) scale(1, 1);
        opacity: 1
    }
}
//==========================================================================================
//  Base Styles
//==========================================================================================
.sticky-header__container {
    top: 0;
    width: 100%;
    background-color: color(plain-white);
    position: fixed;
    overflow: hidden;
    transform: translate3d(0, -72px, 0);
    visibility: hidden;
    transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

.sticky-header__container__displayed {
    visibility: visible;
    transform: translate3d(0, 0, 0);
    z-index: 13;
    transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.sticky-header__wrapper {
    width: 100%;
    height: auto;
    max-width: 1140px;
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 0 20px;
}

.sticky-header__logo {
    display: none;
    height: auto;
    align-self: flex-start;
    transition: opacity 0.3s ease;
    margin-bottom: 8px;
    padding-top: 0;
}

.sticky-header__logo__hidden {
    display: none;
}

.sticky-header__logo-image {
    max-width: 100%;
    height: auto;
    max-height: 50px;
}

.sticky-header__title__pipe {
    font-family: $root-font-family;
    font-size: 16px;
    margin: 0 10px;
    color: color(steel-blue);
    font-weight: 600;
}

.sticky-header__title {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sticky-header__title__hidden {
    width: 0;
    height: 0;
    visibility: hidden;
}

.sticky-header__buttons,
.sticky-header__share,
.sticky-header__back-to-top {
    display: flex;
    align-items: center;
}

.sticky-header__back-to-top {
    cursor: pointer;
    color: color(burnt-sienna);
    fill: color(burnt-sienna);
}

.sticky-header__back-to-top:hover,
.sticky-header__back-to-top:focus,
.sticky-header__back-to-top:active {
    color: color(flamingo);
    fill: color(flamingo);
}

.sticky-header__share {
    cursor: pointer;
    color: color(chambray);
    fill: color(chambray);
    margin-right: 15px;
    height: 62px;
}

.sticky-header__share:hover,
.sticky-header__share:focus,
.sticky-header__share:active {
    color: color(steel-blue);
    fill: color(steel-blue);
}

.sticky-header__share__icon,
.sticky-header__back-to-top__icon {
    margin-right: 5px;
}

.sticky-header__share__icon {
    width: 20px;
    height: 20px;
}

.sticky-header__back-to-top__icon {
    display: none;
    width: 30px;
    height: 30px;
}

.sticky-header__back-to-top:hover .sticky-header__back-to-top__icon {
    animation: animate-arrow-up 0.4s cubic-bezier(0.77, 0, 0.175, 1);
}

.sticky-header__share__title,
.sticky-header__back-to-top__title {
    font-family: $root-font-family;
    font-weight: 700;
    font-size: 14px;
    display: none;
}

.sticky-header__buttons__pipe {
    display: none;
    font-family: $root-font-family;
    color: color(gallery);
    margin: 0 13px 9px 13px;
    font-size: 30px;
}

.sticky-header__share-buttons {
    display: flex;
    height: 100%;
}

.sticky-header__share-buttons > div {
    display: none;
    margin-left: 1px;
    width: 62px;
    height: 62px;
    color: color(ocean-blue);
    font-size: 18px;
    text-align: center;
    cursor: pointer;
    transition: visibility 0s 0.5s, opacity 0.2s 0s, color 0.2s, background 0.2s;
}

.sticky-header__share-buttons > .sticky-header__share-buttons__displayed {
    display: block;
}

.sticky-header__share-buttons > div:hover,
.sticky-header__share-buttons > div:focus {
    background: color(botticelli);
    color: color(ocean-blue);
    visibility: visible;
    opacity: 1;
    transition: visibility 0s 0s, opacity 0.2s 0s, color 0.2s, background 0.2s;
}

.sticky-header__share-buttons > div:before {
    display: block;
    content: ' ';
    background-size: 18px 18px;
    height: 18px;
    width: 18px;
    margin-left: 22px;
    margin-top: 22px;
}

.sticky-header__share__facebook:before {
    background-image: url(../../Static/images/symbols/facebook--light-blue.svg);
}

.sticky-header__share__twitter:before {
    background-image: url(../../Static/images/symbols/twitter--light-blue.svg);
}

.sticky-header__share__linkedin:before {
    background-image: url(../../Static/images/symbols/linkedin--light-blue.svg);
}

.sticky-header__share__email:before {
    background-image: url(../../Static/images/symbols/email-icon--light-blue.svg);
}

.sticky-header__progress-bar__container {
    margin-top: -2px;
}

.sticky-header__progress-bar {
    bottom: 0;
    background-color: color(ocean-blue);
    height: 2px;
}

/* Media Queries */
@include mq($from: screen480) {
    .sticky-header__back-to-top__icon {
        display: block;
    }

    .sticky-header__buttons__pipe {
        display: block;
    }
}

@include mq($from: screen640) {
    .sticky-header__title__hidden {
        width: auto;
        height: auto;
        visibility: visible;
    }
}

@include mq($from: screen768) {
    .sticky-header__wrapper {
        padding-left: 40px;
        padding-right: 40px;
    }

    .sticky-header__logo {
        display: block;
    }

    .sticky-header__logo__hidden {
        display: none;
    }
}

@include mq($from: screen1140) {

    .sticky-header__wrapper {
        padding: 0 40px 0 40px;
    }

    .sticky-header__logo__hidden {
        display: block;
    }

    .sticky-header__share__title,
    .sticky-header__back-to-top__title {
        display: block;
    }
}