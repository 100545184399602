//==========================================================================================
//
//  Large Article Cards
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================

.large-article {
    padding: $component-padding-sm;
}

.large-article__container {
    max-width: $standard-max-width;
    margin: 0 auto;
    display:block;
}

.large-article__title {
    text-align:center;
    @include typography(h1, normal,  $root-font-family-serif);
}

.large-article__desc {
    text-align:center;
}

.large-article__row {
    display:flex;
    flex-direction: column;
    margin: 20px 0;
}

.large-article__card {
    @include typography(typo-14, normal, $root-font-family);
    transition: margin ease 300ms;
    width:100%;
    margin:10px 0;
    padding:0;
    border:none;
    position: relative;
    height:100%;
}

.large-article__image {
    width:100%;
    display:block;
}

.large-article__card-rank {
    @include typography(h5, bold, $root-font-family-serif);
    display:block;
    position: absolute;
    top:0;
    left:0;
    color:color(plain-white);
    background-color:color("ocean-blue");
    height:50px;
    width:50px;
    line-height: 50px;
    text-align: center;
    pointer-events: none;

}

.large-article__image-mask{
    background-color:rgba(0,0,0,0.3);
    position: absolute;
    top:0;
    left:0;
    bottom:0;
    right:0;
    pointer-events: none;
}

.large-article__content-wrapper {
    position: absolute;
    bottom:40px;
    padding:0 20px;
    pointer-events: none;

    & a{
        pointer-events:all;
    }
}

.large-article__cta-wrapper {
    padding-top:10px;
}

.large-article__card-tag {
    @include typography(typo-13, 600, $root-font-family);
    display:inline-block;
    color:color(plain-white);
    border-bottom:2px solid color(plain-white);
    padding-bottom:10px;
    margin-bottom:10px;
}

.large-article__card-title {
    margin:10px 0 20px 0;
    color:color(plain-white);

}

.large-article__card-desc {
    margin:5px 0 20px 0;
    color:color(plain-white);
}

//==========================================================================================
//  Media Queries
//==========================================================================================
@include mq($from: screen768) {

    .large-article__card {
        width:calc(50% - 10px);
        margin:10px 10px;

        &:nth-child(odd) {
            margin-left:0;
        }

        &:nth-child(even){
            margin-right:0;
        }
    }

    .large-article__row {
        margin: 0;
        display: flex;
        flex-direction: row;
        flex-flow: wrap;
        align-items: center;
        justify-content: left;
    }

    .large-article__card {
        max-height:450px;
        overflow:hidden;
    }

    .large-article__card-rank {
        height: 70px;
        width: 70px;
        line-height: 70px;
    }
}

@include mq($from: screen960) {
    .large-article {
        padding: $component-padding-lg;
    }

    .large-article__card {
        width:calc(33.3333% - 14px);

        &:nth-child(3n + 1) {
            margin-left:0;
            margin-right:10px;
        }

        &:nth-child(3n + 2) {
            margin-left:10px;
            margin-right:10px;
        }

        &:nth-child(3n + 3) {
            margin-left:10px;
            margin-right:0;
        }
    }
}