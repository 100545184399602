//==========================================================================================
//
//  Table Component 2
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================

.ihp-details-table__table {
    border: 1px solid color(axa-silver);
}

.tablesaw-stack td {
    padding: 10px 20px;
}

.tablesaw-stack {
    .tablesaw-cell-content {
        font-weight: 700;
    }
}

.tablesaw-stack td .tablesaw-cell-label, .tablesaw-stack th .tablesaw-cell-label
{
    overflow:hidden;
    text-overflow: ellipsis;
}

.ihp-details-table {
    width:100%;
    padding: $component-padding-sm;
}

.ihp-details-table__wrapper {
    max-width: $standard-max-width;
    margin:0 auto;
    height: auto;

    .intro-text {
        margin-bottom: 40px;
    }
}

.table__icon{
    width:15px;
    height:15px;
}

.table__footer{
    display:block;
    margin-top:20px;
    text-align:center;
}

.table__footer-copy{
    @include typography(typo-14, normal, $root-font-family);
}

.table__footer-phone{
    display:inline-block;
    margin:0 35px 0 5px;
    @include typography(typo-20, normal, $root-font-family);
}

//==========================================================================================
//  Media Queries
//==========================================================================================
@include mq($from: screen640) {
    .tablesaw-stack td {
        padding-left:0.7em;
    }

    .ihp-details-table__table {
        border-collapse: collapse;
    }

    .ihp-details-table__table td {
        border:1px solid color(axa-silver);
        width:20%;
        text-align:center;
    }

    .ihp-details-table__table {
        td:first-child {
            border-left:none;
            text-align:left;
        }

        td:last-child {
            border-right:none;
        }

        tr:last-child td{
            border-bottom: none;
        }
     }

    .ihp-details-table__table th{
        text-align:center !important;

        &:first-child{
            border-bottom:1px solid color(axa-silver);
        }
    }

    .ihp-details-table__header--standard {
        background-color:color(health-plan-standard);
        color:color(plain-white);
        padding: 20px 0;
    }

    .ihp-details-table__header--comprehensive {
        background-color:color(health-plan-comprehensive);
        color:color(plain-white);
        padding: 20px 0;
    }

    .ihp-details-table__header--prestige {
        background-color:color(health-plan-prestige);
        color:color(plain-white);
        padding: 20px 0;
    }

    .ihp-details-table__header--prestige-plus {
        background-color:color(health-plan-prestige-plus);
        color:color(plain-white);
        padding: 20px 0 !important;
    }

    .table__footer {
        margin-top:50px;
    }
}

@include mq($from: screen960) {
    .ihp-details-table {
        padding: $component-padding-lg;
    }
}