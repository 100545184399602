//==========================================================================================
//
//  Tab Panel
//
//==========================================================================================

.filter-container input[type="radio"] {
    display: none;
}

.filter-radios-inner {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 3;
    text-align: center;
}

.filter-header-wrapper > label {
    cursor: pointer;
}

.filter-label-inner {
    width: 100%;
    padding: 20px;
    background: color(mercury);
    transition: 0.2s;
    color: color(dark-grey);
}

.filter-label-inner-content {
    text-align: left;
    margin-bottom: 6px;
}

.filter-container input#filter-radio-one:checked ~ .filter-radios-parent .one,
.filter-container input#filter-radio-two:checked ~ .filter-radios-parent .two {
    background: color(ocean-blue);
    color: color(plain-white);
}

@media (min-width:40em) {
    .filter-header-wrapper {
        display: flex;
    }

    .filter-header-wrapper > label {
        width: 50%;
    }

    .filter-label-inner-content {
        display: flex;
        margin: 0;
    }

    .one > .filter-label-inner-content {
        flex-direction: row-reverse;
    }

    .two > .filter-label-inner-content {
        flex-direction: row;
    }

    .inside-left,
    .inside-right {
        max-width: 580px;
        flex-grow: 1;
    }
    
}

.tile {
    transition: 0.2s;
}


    .filter-container input#filter-radio-one:checked ~ .filter-radios-parent .tile:not(.type-one),
    .filter-container input#filter-radio-two:checked ~ .filter-radios-parent .tile:not(.type-two) {
        height: 0;
        opacity: 0;
    }


@media (min-width:60em) {
    .filter-container input#filter-radio-one:checked ~ .filter-radios-parent .tile:not(.type-one),
    .filter-container input#filter-radio-two:checked ~ .filter-radios-parent .tile:not(.type-two) {
        display: none;
    }
}

    .article-blocks__card--filtered {
        border-color: color(ocean-blue);
    }

    .article-blocks__card--midmarket {
        border-color: color(burnt-sienna);
    }

