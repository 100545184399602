//==========================================================================================
//
//  Styleguide Panel
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================
.styleguide {
    width: 100%;
    height: auto;
    margin: 0 auto;
    display: block;
    padding: 20px;
}

.styleguide__logo {
    width: 230px;
    height: auto;
    margin-bottom: 30px;
}

.styleguide__logo-image {
    width: 100%;
    height: auto;
}

.styleguide__components,
.styleguide__templates {
    width: 100%;
    height: auto;
    display: block;
    margin-bottom: 50px;
}

.styleguide__title {
    @include typography(typo-36, 600, $root-font-family);
    margin-bottom: 10px;
}

.styleguide__subtitle {
    @include typography(typo-24, 600, $root-font-family);
    margin-bottom: 10px;
    margin-top: 20px;
}

.styleguide__tagline {
    @include typography(typo-18, 600, $root-font-family);
}

.styleguide__list{
    width: 100%;
    height: auto;
    margin-top: 30px;

    li {
        @include typography(typo-18, normal, $root-font-family);
    }
}

//==========================================================================================
//  Media Queries
//==========================================================================================
