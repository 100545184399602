//==========================================================================================
// Color Scheme
//==========================================================================================
$colour-schemes: (
	/*
    "example": (
        color(wild-sand), // Background Color
        color(dark-grey), // Text Color
        color(axa-blue), // Highlight Text Color
        color(burnt-sienna), // Carousel active bullet color
    ),
*/
	"wild-sand": (
        color(wild-sand),
        color(dark-grey),
        color(axa-blue),
        color(axa-blue)
    ),
	"mercury": (
		color(mercury),
		color(dark-grey),
		color(axa-blue),
		color(axa-blue)
	),
	"white": (
		color(plain-white),
		color(dark-grey),
		color(axa-blue),
		color(axa-blue)
	),
	"deep-sapphire": (
		color(deep-sapphire),
		color(plain-white),
		color(plain-white),
		color(plain-white)
	),
	"axa-blue": (
		color(axa-blue),
		color(plain-white),
		color(plain-white),
		color(plain-white)
	),
	"st-tropaz": (
		color(st-tropaz),
		color(plain-white),
		color(plain-white),
		color(plain-white)
	),
	"azure": (
		color("azure"),
		color(plain-white),
		color(plain-white),
		color(plain-white)
	),
	"dark-indigo": (
		color(dark-indigo),
		color(plain-white),
		color(plain-white),
		color(plain-white)
	),
	"indigo": (
		color(axa-indigo),
		color(plain-white),
		color(plain-white),
		color(plain-white)
	),
	"azalea": (
		color(azalea),
		color(plain-white),
		color(plain-white),
		color(plain-white)
	),
	"aqua-green": (
		color(aqua-green),
		color(plain-white),
		color(axa-blue),
		color(plain-white)
	),
	"logan": (
		color(logan),
		color(plain-white),
		color(plain-white),
		color(axa-blue)
	),
	"greyjoy": (
		color(greyjoy),
		color(plain-white),
		color(axa-blue),
		color(axa-blue)
	),
	"viridian-green": (
		color(viridian-green),
		color(plain-white),
		color(plain-white),
		color(plain-white)
	),
	"pacific": (
		color(pacific),
		color(plain-white),
		color(plain-white),
		color(plain-white)
	),
	"teal": (
		color(axa-teal),
		color(plain-white),
		color(plain-white),
		color(plain-white)
	),
	"apache": (
		color(apache),
		color(plain-white),
		color(axa-blue),
		color(axa-blue)
	),
	"tosca": (
		color(tosca),
		color(plain-white),
		color(plain-white),
		color(plain-white)
	),
    "ocean-blue": (
		color(ocean-blue),
		color(plain-white),
		color(plain-white),
		color(plain-white)
	),
    "dark-grey": (
		color(dark-grey),
		color(light-grey),
		color(light-grey),
		color(light-grey)
	),
    "black": (
		color(color-black),
		color(plain-white),
		color(plain-white),
		color(plain-white)
	),
    "magazine": (
		color(dark-grey),
		color(plain-white),
		color(light-grey),
		color(plain-white)
	),
    "magazine-banner": (
		color(dark-grey),
		color(plain-white),
		color(plain-white),
		color(plain-white)
	)

);

// default bullet value before scheme overrides
.richtext li::before {
	background-color: color(dark-grey);
}

// LOOP EACH -
@each $theme-name, $theme-colors in $colour-schemes {
	// Set some local variables for clarity -
	// Background color
	$schemeBG: #{nth($theme-colors, 1)};

	// Text Variables
	$schemeText: #{nth($theme-colors, 2)};
	$schemeTextHighlight: #{nth($theme-colors, 3)};

	// Other
	$schemeCarouselActive: #{nth($theme-colors, 4)};

	// Generate scheme rules
    .scheme--#{$theme-name} {
        // Set base scheme properties
        background-color: $schemeBG;
        color: $schemeText;
        fill: $schemeText; //svgs
        button[data-pagination-item] {
            background: $schemeText;
        }

        button[data-pagination-item][data-active="true"] {
            background: $schemeCarouselActive;
        }
        // Component specifics
        [class*="__column"], // intends to select 1px separators
        [class*="__subtitle"], // subtitle underlines
        li //list bullets
        {
            &:after,
            &:before {
                color: $schemeText;
                background-color: $schemeText;
            }
        }

        [class*="__background-image"] // background image tinting
        {
            &:after,
            &:before {
                background-color: $schemeBG;
            }
        }
        // Importants
        .background--scheme {
            background: $schemeBG;
        }

        .typo--scheme {
            color: $schemeText;

            &:hover,
            &:focus {
                color: $schemeText;
            }
        }

        .typo-highlight--scheme {
            color: $schemeTextHighlight;

            &:hover,
            &:focus {
                color: $schemeTextHighlight;
            }
        }

        .svg-highlight--scheme {
            fill: $schemeTextHighlight;
        }   

        &.form-panel__chevron {
            &:after {
                background-color: $schemeBG;
            }
        }
        //IE11 inheriting placeholder text color to border colour without this
        & .country-selector__input {
            border-color: $schemeText;
        }
    }

	//Buttons? //Anchors?
}

.matching-scheme-top {
	padding-bottom: 40px !important;
}

.matching-scheme-bottom {
	padding-top: 40px !important;
}
