//==========================================================================================
//
//  Press Releases
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================
.press-releases{
    padding: $component-padding-sm;
}

.press-releases__container{
    max-width: $standard-max-width;
    margin: 0 auto;
}

.press-releases__header{
    text-align: center;
    padding-bottom: 20px;
    max-width: $content-max-width-768;
    margin: 0 auto;
}

.press-releases-header__title{
    margin: 0;
}

.press-releases-header__content{
    padding-top: 10px;
}

.press-releases-list{
    padding: 0;
    margin: 0;
}

.press-releases-list__item{
    list-style: none;
    margin: 0;
    border-bottom: 1px solid color(mercury);

    &:last-of-type{
        border: none;
    }
}

.press-releases__footer{
    padding-top: 20px;
    display: flex;
    flex-direction: column;
}

.press-releases__cta{
    margin: 0 auto;
}

.press-releases__pagination{
    margin: auto;
    max-width: 100%;
}
//==========================================================================================
//  Media Queries
//==========================================================================================
@include mq($from: screen768) {
    .press-releases__cta{
        margin: auto 0 auto auto;
    }

    .press-releases__header{
        padding-bottom: 40px;
    }

    .press-releases__footer{
        padding-top: 40px;
    }
}

@include mq($from: screen960) {
    .press-releases {
        padding: $component-padding-lg;
    }
}