//==========================================================================================
//
//  Side Navigation
//
//==========================================================================================

.secondary-navigation__title {
    display: block;
    color: color(dark-grey);
    font-family: "SourceSansPro",Arial,sans-serif;
    font-size: 18px;
    font-weight: 600;
}

.secondary-navigation--right {
    padding-top: 15px;
    background: color(alabaster);
}

.secondary-navigation__title-divider {
    border-top: 1px solid color(dark-grey);
    width: 12%;
    padding-bottom: 10px;
    position: relative;
    display: block;
}

.secondary-navigation__pages {
    background: color(alabaster);
    padding-top: 20px;
}

.secondary-navigation__pages-btn-wrapper, .secondary-navigation__seperator, .secondary-navigation__feefo {
    display: block;
}

.secondary-navigation__pages-btn-wrapper ul {
    list-style: none;
    cursor: pointer;
    margin: 10px 1%;
}

.secondary-navigation__pages-btn-wrapper ul li {
    padding: 20px 0;
    border-bottom: 2px solid color(wild-sand);
    margin: 0;
    background: color(alabaster);
}

.secondary-navigation__pages-btn-wrapper ul li a:last-of-type {
    width: 100%;
    border-right: 0;
}

.secondary-navigation__pages-btn-wrapper ul li.secondary-navigation__pages-active, .secondary-navigation__pages-btn-wrapper ul li:hover {
    border-right: none;
}

.secondary-navigation__pages-btn {
    color: color(emag-azure);
    font-family: "SourceSansPro",Arial,sans-serif;
    font-size: 16px;
    font-weight: 400;
    padding: 10px 0;
}

a.secondary-navigation__pages-btn.page-active {
    background-color: color(emag-active-page);
    font-weight: 600;
}

.secondary-navigation__pages-btn:hover {
    text-decoration: none;
    color: color(axa-blue);
}

.secondary-navigation__product-pages .secondary-navigation__pages-btn-wrapper ul li {
    background-color: color(wild-sand);
    display: inline-block;
    padding: 0;
    width: 100%;
    border-top: 1px solid color(plain-white);
}

.secondary-navigation__product-pages ul li a:hover {
    background-color: color(igloo);
}

.secondary-navigation__pages-btn-wrapper ul ul {
    overflow: hidden;
    transition: all .5s ease-in-out;
    height: 0;
    margin-left: 8%;
    display: none;
}

li.sidenav-scheme--emag a.secondary-navigation__pages-btn {
    border-right: 1px solid color(plain-white);
}

.secondary-navigation__pages-btn-wrapper ul li.sidenav-scheme--emag.subnav--0 a {
    color: color(emag-sub-nav-0);
}

.secondary-navigation__pages-btn-wrapper ul li.sidenav-scheme--emag.subnav--1 a {
    color: color(pacific);
}

.secondary-navigation__pages-btn-wrapper ul li.sidenav-scheme--emag.subnav--2 a {
    color: color(emag-sub-nav-2);
}

.secondary-navigation__pages-btn-wrapper ul li.sidenav-scheme--emag.subnav--3 a {
    color: color(emag-sub-nav-3);
}

.secondary-navigation__pages-btn-wrapper ul li.sidenav-scheme--emag.subnav--4 a {
    color: color(component-social-share);
}

.secondary-navigation__pages-btn-wrapper ul li.sidenav-scheme--emag.subnav--5 a {
    color: color(emag-sub-nav-5);
}

.secondary-navigation__pages-btn-wrapper ul li.sidenav-scheme--emag.subnav--6 a {
    color: color(dark-grey);
}

.secondary-navigation__pages-btn-wrapper ul.show-sub-menu li.sidenav-scheme--emag a.subnavtop.arrow--up {
    color: color(plain-white);
}

.secondary-navigation__pages-btn-wrapper ul.show-sub-menu li.sidenav-scheme--emag.subnav--0 a.subnavtop.arrow--up {
    background-color: color(emag-sub-nav-0);
}

.secondary-navigation__pages-btn-wrapper ul.show-sub-menu li.sidenav-scheme--emag.subnav--0 a.subnavtop.arrow--up:hover {
    background-color: color(emag-sub-nav-0-hover);
}

.secondary-navigation__pages-btn-wrapper ul.show-sub-menu li.sidenav-scheme--emag.subnav--1 a.subnavtop.arrow--up {
    background-color: color(pacific);
}

.secondary-navigation__pages-btn-wrapper ul.show-sub-menu li.sidenav-scheme--emag.subnav--1 a.subnavtop.arrow--up:hover {
    background-color: color(emag-sub-nav-1-hover);
}

.secondary-navigation__pages-btn-wrapper ul.show-sub-menu li.sidenav-scheme--emag.subnav--2 a.subnavtop.arrow--up {
    background-color: color(emag-sub-nav-2);
}

.secondary-navigation__pages-btn-wrapper ul.show-sub-menu li.sidenav-scheme--emag.subnav--2 a.subnavtop.arrow--up:hover {
    background-color: color(emag-sub-nav-2-hover);
}

.secondary-navigation__pages-btn-wrapper ul.show-sub-menu li.sidenav-scheme--emag.subnav--3 a.subnavtop.arrow--up {
    background-color: color(emag-sub-nav-3);
}

.secondary-navigation__pages-btn-wrapper ul.show-sub-menu li.sidenav-scheme--emag.subnav--3 a.subnavtop.arrow--up:hover {
    background-color: color(emag-sub-nav-3-hover);
}

.secondary-navigation__pages-btn-wrapper ul.show-sub-menu li.sidenav-scheme--emag.subnav--4 a.subnavtop.arrow--up {
    background-color: color(component-social-share);
}

.secondary-navigation__pages-btn-wrapper ul.show-sub-menu li.sidenav-scheme--emag.subnav--4 a.subnavtop.arrow--up:hover {
    background-color: color(emag-sub-nav-4-hover);
}

.secondary-navigation__pages-btn-wrapper ul.show-sub-menu li.sidenav-scheme--emag.subnav--5 a.subnavtop.arrow--up {
    background-color: color(emag-sub-nav-5);
}

.secondary-navigation__pages-btn-wrapper ul.show-sub-menu li.sidenav-scheme--emag.subnav--5 a.subnavtop.arrow--up:hover {
    background-color: color(emag-sub-nav-5-hover);
}

.secondary-navigation__pages-btn-wrapper ul.show-sub-menu li.sidenav-scheme--emag.subnav--6 a.subnavtop.arrow--up {
    background-color: color(dark-grey);
}

.secondary-navigation__pages-btn-wrapper ul.show-sub-menu li.sidenav-scheme--emag.subnav--6 a.subnavtop.arrow--up:hover {
    background-color: color(emag-sub-nav-6-hover);
}

.secondary-navigation__pages-btn-wrapper ul.show-sub-menu li.sidenav-scheme--emag.subnav--0 a.subnavtop.arrow--up .sn-accordion-arrow-container {
    background-color: color(emag-sub-nav-0);
}

.secondary-navigation__pages-btn-wrapper ul.show-sub-menu li.sidenav-scheme--emag.subnav--1 a.subnavtop.arrow--up .sn-accordion-arrow-container {
    background-color: color(pacific);
}

.secondary-navigation__pages-btn-wrapper ul.show-sub-menu li.sidenav-scheme--emag.subnav--2 a.subnavtop.arrow--up .sn-accordion-arrow-container {
    background-color: color(emag-sub-nav-2);
}

.secondary-navigation__pages-btn-wrapper ul.show-sub-menu li.sidenav-scheme--emag.subnav--3 a.subnavtop.arrow--up .sn-accordion-arrow-container {
    background-color: color(emag-sub-nav-3);
}

.secondary-navigation__pages-btn-wrapper ul.show-sub-menu li.sidenav-scheme--emag.subnav--4 a.subnavtop.arrow--up .sn-accordion-arrow-container {
    background-color: color(component-social-share);
}

.secondary-navigation__pages-btn-wrapper ul.show-sub-menu li.sidenav-scheme--emag.subnav--5 a.subnavtop.arrow--up .sn-accordion-arrow-container {
    background-color: color(emag-sub-nav-5);
}

.secondary-navigation__pages-btn-wrapper ul.show-sub-menu li.sidenav-scheme--emag.subnav--6 a.subnavtop.arrow--up .sn-accordion-arrow-container {
    background-color: color(dark-grey);
}

li.sidenav-scheme--emag.subnav--0 a.secondary-navigation__pages-btn.page-active {
    background-color: color(emag-sub-nav-0, 0.15);
}

li.sidenav-scheme--emag.subnav--1 a.secondary-navigation__pages-btn.page-active {
    background-color: color(pacific, 0.15);
}

li.sidenav-scheme--emag.subnav--2 a.secondary-navigation__pages-btn.page-active {
    background-color: color(emag-sub-nav-2, 0.15);
}

li.sidenav-scheme--emag.subnav--3 a.secondary-navigation__pages-btn.page-active {
    background-color: color(emag-sub-nav-3, 0.15);
}
li.sidenav-scheme--emag.subnav--4 a.secondary-navigation__pages-btn.page-active {
    background-color: color(component-social-share, 0.15);
}
li.sidenav-scheme--emag.subnav--5 a.secondary-navigation__pages-btn.page-active {
    background-color: color(emag-sub-nav-5, 0.15);
}

li.sidenav-scheme--emag.subnav--6 a.secondary-navigation__pages-btn.page-active {
    background-color: color(dark-grey, 0.15);
}

li.sidenav-scheme--emag a.secondary-navigation__pages-btn.page-active:hover {
    background-color: color(igloo);
}
 
.secondary-navigation__pages-btn-wrapper ul ul.show-sub-menu {
    overflow: visible;
    height: auto;
    display: block;
}

.secondary-navigation__pages-btn-wrapper ul ul.show-sub-menu li {
    border-left: 3px solid color(axa-blue);
}

.secondary-navigation__pages-btn-wrapper ul ul.show-sub-menu li.sidenav-scheme--emag.subnav--0 {
    border-left: 3px solid color(emag-sub-nav-0);
}

.secondary-navigation__pages-btn-wrapper ul ul.show-sub-menu li.sidenav-scheme--emag.subnav--1 {
    border-left: 3px solid color(pacific);
}

.secondary-navigation__pages-btn-wrapper ul ul.show-sub-menu li.sidenav-scheme--emag.subnav--2 {
    border-left: 3px solid color(emag-sub-nav-2);
}

.secondary-navigation__pages-btn-wrapper ul ul.show-sub-menu li.sidenav-scheme--emag.subnav--3 {
    border-left: 3px solid color(emag-sub-nav-3);
}

.secondary-navigation__pages-btn-wrapper ul ul.show-sub-menu li.sidenav-scheme--emag.subnav--4 {
    border-left: 3px solid color(component-social-share);
}

.secondary-navigation__pages-btn-wrapper ul ul.show-sub-menu li.sidenav-scheme--emag.subnav--5 {
    border-left: 3px solid color(emag-sub-nav-5);
}

.secondary-navigation__pages-btn-wrapper ul ul.show-sub-menu li.sidenav-scheme--emag.subnav--6 {
    border-left: 3px solid color(dark-grey);
}

.secondary-navigation__pages-btn-wrapper ul ul.show-sub-menu li a {
    width: 82%;
}

.secondary-navigation__pages-btn-wrapper ul ul.show-sub-menu li a:last-of-type {
    width: 100%;
    border-right: none;
}

.secondary-navigation__pages-btn-wrapper ul ul.show-sub-menu.border-none li {
    border-left: 3px solid transparent;
}

.secondary-navigation__pages-btn-wrapper ul ul ul.show-sub-menu li {
    border-left: 3px solid color(axa-blue) !important;
}

.secondary-navigation__product-pages .secondary-navigation__pages-btn {
    padding: 10px 0 10px 15px;
    border: 0;
    width: 82.5%;
    display: inline-flex;
    border-right: 1px solid color(axa-silver);
}

.secondary-navigation__product-pages ul li a:nth-of-type(2) {
    border-top: 0;
    display: inline;
    border: none;
}

.secondary-navigation__product-pages .sn-accordion-arrow-container {
    position: relative;
    top: 0px;
}

.secondary-navigation__product-pages li.secondary-navigation__sub-page-btn {
    border-left: 3px solid color(emag-azure);
    margin-left: 5%;
}

.secondary-navigation__product-pages li.secondary-navigation__sub-sub-page-btn {
    border-left: 3px solid color(emag-azure);
    margin-left: 10%;
}

.secondary-navigation__other-products {
    padding: 30px 0 30px 65px;
}

.secondary-navigation__other-products li {
    padding: 5px 0 !important;
}

.secondary-navigation__other-products li:hover a {
    text-decoration: none;
    color: color(axa-blue);
}

.secondary-navigation__product-pages .secondary-navigation__pages-btn-wrapper .secondary-navigation__title {
    background-color: color(axa-teal);
}

.secondary-navigation__product-pages .secondary-navigation__pages-btn-wrapper .secondary-navigation__title a {
    color: color(plain-white);
    border-top: none;
    padding: 15px;
    display: block;
    text-decoration: none;
}

.secondary-navigation__product-pages .secondary-navigation__pages-btn-wrapper .secondary-navigation__title:hover, .secondary-navigation__product-pages .secondary-navigation__pages-btn-wrapper .secondary-navigation__title a:hover {
    background-color: color(emag-hover);
}

.secondary-navigation__seperator {
    border-top: 1px solid color(emag-separator);
    position: relative;
    left: 5%;
    width: 90%;
}

.secondary-navigation__feefo {
    padding: 0 20px;
    margin-top: 20%;
}

.secondary-navigation__award {
    color: color(dark-grey);
    font-size: 18px;
    font-weight: 600;
}

.secondary-navigation__award-divider {
    border-top: 1px solid color(dark-grey);
    width: 20%;
    padding-bottom: 10px;
}

.secondary-navigation__award-terms {
    color: color(dark-grey);
    font-family: "SourceSansPro",Arial,sans-serif;
    font-size: 18px;
    font-weight: 400;
}

.secondary-navigation__ceo {
    color: color(medium-grey);
    font-family: "SourceSansPro",Arial,sans-serif;
    font-size: 16px;
    font-weight: 400;
}

.article-wrapper .secondary-navigation--left {
    order: 2;
}

.secondary-navigation--left {
    display: none;
    /*background: color(alabaster);*/
}

.secondary-navigation__other-products {
    display: none;
}

.sn-accordion-arrow-container {
    padding: 0;
    margin: 0;
    height: 47px;
    width: 17.1%;
    float: right;
}

.sn-accordion-arrow {
    fill: color(axa-blue);
    transform: rotate(180deg);
}

svg.sn-accordion-arrow{
    width: 25px;
    height: 25px;
    margin-top: 10px;
    margin-left: 12px;
}

a.arrow--up svg.sn-accordion-arrow {
    transform: none !important;
}

li.sidenav-scheme--emag.subnav--0 svg.sn-accordion-arrow {
    fill: color(emag-sub-nav-0);
}

li.sidenav-scheme--emag.subnav--1 svg.sn-accordion-arrow {
    fill: color(pacific);
}

li.sidenav-scheme--emag.subnav--2 svg.sn-accordion-arrow {
    fill: color(emag-sub-nav-2);
}

li.sidenav-scheme--emag.subnav--3 svg.sn-accordion-arrow {
    fill: color(emag-sub-nav-3);
}

li.sidenav-scheme--emag.subnav--4 svg.sn-accordion-arrow {
    fill: color(component-social-share);
}

li.sidenav-scheme--emag.subnav--5 svg.sn-accordion-arrow {
    fill: color(emag-sub-nav-5);
}

li.sidenav-scheme--emag.subnav--6 svg.sn-accordion-arrow {
    fill: color(dark-grey);
}

li.sidenav-scheme--emag a.arrow--up svg.sn-accordion-arrow {
    fill: color(plain-white);
}

@media (min-width: 62em) {

    .secondary-navigation--left {
        display: block;
    }

    .article-wrapper .secondary-navigation--left {
        order: 1;
    }
    .secondary-navigation__container {
        margin-bottom: 40px;
    }

    .secondary-navigation--left {
        padding: 0;
        width: 30%;
    }

    .secondary-navigation__pages {
        padding-top: 80px;
        padding-left: 50px;
    }

    .secondary-navigation__other-products {
        padding-top: 30px;
    }

    .secondary-navigation__pages-btn-wrapper {
        display: block !important;
    }

    .secondary-navigation__pages-btn-wrapper ul {
        padding: 0;
        border-bottom: none;
        margin: 0;
    }

    .secondary-navigation__pages-btn-wrapper ul li {
        margin: 0;
        padding: 15px 0;
        border-bottom: none;
    }

    .article-repository .secondary-navigation__pages-btn-wrapper ul li.secondary-navigation__pages-active, .article-repository .secondary-navigation__pages-btn-wrapper ul li:hover {
        border-right: none;
    }

    .secondary-navigation__seperator, .secondary-navigation__feefo {
        display: block;
    }

    .secondary-navigation__title-divider, .secondary-navigation__award-divider {
        width: 12%;
    }

}

@media (min-width: 87.5em) {
    .repository .secondary-navigation.left {
        width: 30%;
    }

    .secondary-navigation--left {
        width: 25%;
    }
}

/*IE*/
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .sn-accordion-arrow-container {
        height: 46.5px;
    }
}

