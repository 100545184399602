//==========================================================================================
//
//  Product List
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================
.scroll-to-product{
    padding: $component-padding-sm;
    display: flex;
}

.scroll-to-product__container{
    width: 100%;
    max-width: $standard-max-width;
    margin: 0 auto;
    display: inline-block;
}

.scroll-to-product__select{
    @include typography(typo-18, 700, $root-font-family);
    // Reset borders before applying border bottom style
    border-width:0;
    border-bottom:1px solid color(axa-silver);
    padding:0 30px 0 3px;
    background-color: color(plain-white);
    background-repeat:no-repeat;
    background-position: 100% center;
    background-size:20px 20px;
    background-image:url('/assets/images/symbols/arrow-down--select.svg');
}

.scroll-to-product__label{
    @include typography(typo-18, normal, $root-font-family);

}

//==========================================================================================
//  Media Queries
//==========================================================================================
@include mq($from: screen960) {
    .scroll-to-product{
        padding: $component-padding-lg;
    }
}