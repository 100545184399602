//==========================================================================================
//
//  Fonts
//
//==========================================================================================

//==========================================================================================
//  Publico Banner
//==========================================================================================
@font-face {
	font-family: "Publico Headline";
	src: url("/assets/fonts/PublicoHeadline-Bold-Web.woff") format("woff"),
	url("/assets/fonts/PublicoHeadline-Bold-Web.ttf") format("ttf"),
	url("/assets/fonts/PublicoHeadline-Bold-Web.otf") format("otf");

}

//==========================================================================================
//  SourceSansPro Regular
//==========================================================================================
@font-face {
	font-family: "SourceSansPro";
	font-weight: 400;
	src: url("/assets/fonts/SourceSansPro-Regular.woff") format("woff"),
	url("/assets/fonts/SourceSansPro-Regular.ttf") format("ttf");
}

//==========================================================================================
//  SourceSansPro Semi Bold
//==========================================================================================
@font-face {
	font-family: "SourceSansPro";
	font-weight: 600;
	src: url("/assets/fonts/SourceSansPro-Semibold.woff") format("woff"),
	url("/assets/fonts/SourceSansPro-Semibold.ttf") format("ttf");
}

//==========================================================================================
//  SourceSansPro  Bold
//==========================================================================================
@font-face {
	font-family: "SourceSansPro";
	font-weight: 700;
	src: url("/assets/fonts/SourceSansPro-Bold.woff") format("woff"),
	url("/assets/fonts/SourceSansPro-Bold.ttf") format("ttf");
}