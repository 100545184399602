//==========================================================================================
//
//  Newsletter
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================
.news-letter {
    background-color: color(axa-sub-footer);
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    padding: 50px 20px;
}

.news-letter__title {
    color: color(plain-white);
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
}

.news-letter__form {
    width: auto;
    height: auto;
    display: flex;
    margin-top: 0;
    flex-flow: row wrap;
    justify-content: center;
}

.news-letter__form-email {
    border-style: none;
    height: 44px;
    min-width: 100%;
    padding-left: 10px;
    padding-bottom: 0;
    color: color(axa-blue);

    &::placeholder {
        font-size: 14px;
        font-family: $root-font-family;
        font-weight: normal;
        color: color(light-grey);
    }
}

.news-letter__form-submit {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 0;
    display: block;
}
//==========================================================================================
//  Modifiers
//==========================================================================================
//==========================================================================================
// Name
// Description
//==========================================================================================

//==========================================================================================
//  Media Queries
//==========================================================================================
@include mq($from: screen720) {

    .news-letter {
        padding: 30px 20px 40px 20px;
    }

    .news-letter__form {
        width: auto;
        margin-top: 0;
    }

    .news-letter__title {
        margin-right: 20px;
        width: auto;
        text-align: left;
    }


    .news-letter__form-email {
        min-width: 300px;
        padding-bottom: 0;
    }

    .news-letter__form-submit {
        margin-left: -5px;
        width: auto;
        margin-top: 0;
    }
}

@include mq($from: screen960) {
    .news-letter {
        padding: 20px;
    }

    .news-letter__title {
        margin-bottom: 0;
    }
}