//==========================================================================================
//
//  Image Component
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================

.back-to-top {
    position:fixed;
    bottom:25px;
    right:25px;
    width: 50px;
    height: 50px;
    display:flex;
    align-content: center;
    justify-content: center;
    opacity:0;
    transition: opacity 0.3s ease-in-out;
}

.back-to-top[data-back-to-top-show] {
    opacity:1;
}

.back-to-top__spacer {
    height:500px;
}

.back-to-top__button {
    min-width:20px;
    padding:0;
    display:flex;
    align-content: center;
    margin:0;
    width:100%;

    &:hover, &:focus {
        &:after{
            width: 150%;
        }
    }
}

.back-to-top__icon {
    fill: #fff ;
    width: 30px;
    height: 30px;
}
