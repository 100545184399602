//==========================================================================================
//
//  Hero Banner 3
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================
.container-fluid {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

.hero-banner-content-wrapper {
    padding: 0;
}

.hero-banner-image-wrapper {
    background-position: center;
    background-size: cover;
    height: 12em;
    width: 100%;
    position: static;

    img {
        height: 100%;
        object-fit: cover;
        width: 100%;
    }
}

.hero-banner-text-wrapper {
    position: relative;
    z-index: 1;
    width: 100%;
}

.hero-banner-content-wrapper .banner-content--overlay-text-box {
    background: rgba(255,255,255,.9);
    padding: 30px;
    width: 100%;
}

.banner-content--overlay-text-box h1 {
    margin-top: 0;
    text-align: center;
    margin-bottom: 25px;
}

.defaqto-logo-container-right--top, .defaqto-logo-container-left--top, .defaqto-logo-container-right--bottom, .defaqto-logo-container-left--bottom {
    position: absolute;
    top: -6em;
    left: 25px;
    width: 30%;
}

.defaqto-logo-container-right--top img, .defaqto-logo-container-left--top img, .defaqto-logo-container-right--bottom img, .defaqto-logo-container-left--bottom img {
    max-height: 100px !important;
}

.banner-content--overlay-text-box .btn, .cta-block-with-image-wrapper .btn {
    width: 100%;
    margin-left: 0;
}

.banner-content--overlay-text-box .btn:first-of-type, .cta-block-with-image-wrapper .btn:first-of-type {
    margin-top: 20px;
}

.content-wrapper.article-wrapper .content--right .accordion-group {
    padding: 0;
}

@media (min-width: 25em) {
    .defaqto-logo-container-right--top, .defaqto-logo-container-left--top, .defaqto-logo-container-right--bottom, .defaqto-logo-container-left--bottom {
        top: -10em;
    }
}

@media (min-width: 37.5em) {
    .defaqto-logo-container-right--top, .defaqto-logo-container-left--top, .defaqto-logo-container-right--bottom, .defaqto-logo-container-left--bottom {
        top: -10em;
    }

    .defaqto-logo-container-right--top img, .defaqto-logo-container-left--top img, .defaqto-logo-container-right--bottom img, .defaqto-logo-container-left--bottom img {
        max-height: 150px !important;
    }
}

@media (min-width: 48em) {
    .hero-banner-content-wrapper .banner-content--overlay-text-box {
        padding: 60px;
        width: 92%;
    }

    .hero-banner-image-wrapper {
        height: 100%;
        position: absolute;
    }

    .hero-banner-image {
        height: fit-content;
        height: -webkit-fill-available;
    }

    .banner-content {
        overflow: hidden;
        padding: 0;
        position: relative;
    }

    .banner-content--overlay--left, .banner-content--overlay--right {
        margin: 4% 40px;
    }

    .banner-content--overlay--right {
        margin: 4% 28%;
    }

    .banner-content--overlay--center {
        margin: 4% auto;
    }

    .defaqto-logo-container-right--top {
        top: 0;
        right: 2%;
        left: auto;
        width: auto;
    }

    .defaqto-logo-container-left--top {
        top: 0;
        right: 0;
        left: 40px;
        width: auto;
    }

    .defaqto-logo-container-right--bottom {
        top: 75%;
        right: 2%;
        left: auto;
        width: auto;
    }

    .defaqto-logo-container-left--bottom {
        top: 75%;
        right: 0;
        left: 40px;
        width: auto;
    }

    .banner-content--overlay-text-box h1 {
        text-align: left;
    }

    .defaqto-banner-content-wrapper .banner-content--overlay-text-box {
        padding: 60px;
        width: 70%;
    }

    .banner-content--overlay-text-box .btn {
        width: auto;
    }

    .content-wrapper.full-width .content--right {
        width: 100%;
        padding: 0;
    }

    .defaqto-logo-container-right--top img, .defaqto-logo-container-left--top img, .defaqto-logo-container-right--bottom img, .defaqto-logo-container-left--bottom img {
        max-height: 300px !important;
    }
}

@media (min-width: 60em) {
    .hero-banner-content-wrapper .banner-content--overlay-text-box {
        width: 50%;
    }

    .banner-content--overlay--right {
        margin: 4% 48%;
    }

    .banner-content--overlay--left {
        margin: 4% 40px;
    }

    .defaqto-logo-container-left--top {
        left: 40px;
    }

    .defaqto-logo-container-left--bottom {
        left: 40px;
    }
}

@media (min-width: 62em) {
    .content-wrapper {
        max-width: 1140px;
        margin: 0 auto;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    }

    .content-wrapper {
        display: flex;
    }

    .content-wrapper.hero-banner-text-wrapper {
        display: block;
    }

    .banner-content--overlay-text-box .product__small {
        font-size: 20px;
    }

    .banner-content--overlay-text-box h1 {
        font-size: 56px;
    }

    .content-wrapper.full-width {
        max-width: none;
    }
}

@media (min-width: 71.25em) {
    .banner-content--overlay--right {
        margin-left: 49%;
    }

    .defaqto-logo-container-right--top {
        right: 1%;
    }

    .defaqto-logo-container-right--bottom {
        right: 1%;
    }
}

@media (min-width: 87.5em) {
    .defaqto-logo-container-left--top {
        left: 40px;
    }

    .defaqto-logo-container-left--bottom {
        left: 40px;
    }
}
