//==========================================================================================
//
//  Block panels with text CTA
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================

[data-pagination] {
	display: flex;
	flex-flow: row wrap;
	width: 100%;
	justify-content: center;
}

[data-pagination-item] {
	min-width: 0;
	min-height: 0;
	width: 10px;
	height: 10px;
	display: block;
	padding: 0;
	margin: 0 7px 0 7px;
	border-radius: 100%;
	opacity: 0.4;

	&:after {
		display: none;
	}
}

[data-pagination-item][data-active="true"] {
	opacity: 1;
}

@include mq($from: screen960) {
	[data-pagination-item] {
		margin: 0 5px 0 5px;
	}
}

@include mq($from: screen1140) {
	[data-pagination-item] {
		margin: 0 8px 0 8px;
	}
}
