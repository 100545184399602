//==========================================================================================
//
//  Split Text Panel With Background Image
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================
.split-panel-bck-image {
    width: 100%;
    height: auto;
    position: relative;
}

.split-panel-bck-image .intro-text {
    padding-top: 40px;
    padding-bottom: 40px;
}

.split-panel-bck-image__container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap;
    overflow: hidden;
}

.split-panel-bck-image__item {
    padding: $component-padding-sm;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-color: rgba(0,0,0,0.2);
}

.split-panel-bck-image__item-container {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.split-panel-bck-image__btn-wrapper {
    margin-top: 15px;
    flex-grow: 1;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    .btn,
    .anchor {
        align-self: center;
    }
}

//==========================================================================================
//  Media Queries
//==========================================================================================

@include mq($from: screen768) {
    .split-panel-bck-image__item {
        width: 50%;
    }

    .split-panel-bck-image__btn-wrapper {
        margin-top: 30px;
    }
}

@include mq($from: screen960) {
    .split-panel-bck-image__item {
        padding: $component-padding-lg;
    }
}