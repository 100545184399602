//==========================================================================================
//
//  magazine article panel
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================


.magazine-article-panel {
    width: 100%;
    height: auto;
    clear: both;
    position: relative;
    overflow: hidden;
    /*padding-left:20px;
    padding-right:20px;
    padding-bottom: 20px;
    margin-bottom: 20px;*/
    padding: 20px;
}

.magazine-article-panel a {
    text-decoration: none;
    }


    .magazine-article-panel-image {
        width: 100%;
        height: auto;
        display: block;
        margin-bottom: 20px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        &:after {
            display: block;
            padding-top: 56.25%;
            content: "";
        }
    }


.magazine-article-panel-content {
    width: 100%;
    height: auto;
    display: block;
    max-width: 100%;
    color: color(dark-grey);
}


.magazine-article-panel-title {
    color: color(dark-grey);
}

.magazine-article-panel-link {
    @include typography(anchor, bold, $root-font-family);
    display: inline-block;
    text-decoration: none;
    transition: color 0.3s;
    text-transform: uppercase;
    color: color(dark-grey);

    &:hover, &:focus {
        color: color(axa-grey);
    }
}
    //==========================================================================================
    //  Media Queries
    //==========================================================================================
    @include mq($from: screen720) {
    }

    @include mq($from: screen960) {
    }

@include mq($from: screen992) {

    .magazine-article-panel {
        padding-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 40px;
    }

    .magazine-article-panel-content {
        color: color(dark-grey);
        padding-right: 60px;
    }

    .magazine-article-panel-title {
        color: color(dark-grey);
    }

    .magazine-article-panel-link {
        color: color(dark-grey);
    }
}