//==========================================================================================
//
//  Search results list
//
//==========================================================================================

.search-results-list-search__header {
    max-width: 1240px;
    margin: 0 auto;
    padding-left: 30px;
    padding-right: 30px;
}

.search-results-list-search__header .search-bar {
    margin: 0;
    padding-left: 0;
}

.search-results-list-search__header .search-reference {
    padding: 0;
}

.search-results-list-search__header .search-reference___header, a.search-results-list-header__link {
    color: color(dark-grey);
}

.search-results-list-search__header .search-reference___header.axa-blue {
    color: color(axa-blue);
}

.search-results-list-search__header .search-reference__container {
    max-width: 1280px;
}

.search-results-list-search__header .search-bar__container {
    max-width: 600px;
    border: 1px solid color(axa-silver);
    border-radius: 3px;
}

.search-results-list-search__header .search-bar__input {
    max-width: 600px;
    border: 0;
}

.search-results-list-search__header .search-bar-btn__submit {
    padding: 0;
    margin: 0;
    min-width: 60px;
}

.search-results-list-search__header .search-bar__submit-icon {
    fill: color(plain-white);
    opacity: 1;
    width: 24px;
    height: 24px;
}

.search-results-list-search__header .btn--search.FormFileUpload__Label .btn__search, .search-results-list-search__header .btn--search.FormResetButton .btn__search, .search-results-list-search__header .btn--search.FormSubmitButton .btn__search, .search-results-list-search__header .btn--search.btn .btn__search {
    top: 2px;
    display: block;
    margin-left: 20px;
    position: absolute;
}

.search-results-list-search__header .btn--search.FormFileUpload__Label .btn__search, .search-results-list-search__header .btn--search.FormResetButton .btn__search, .search-results-list-search__header .btn--search.FormSubmitButton .btn__search, .search-results-list-search__header .btn--search.btn .btn__search {
    padding-top: 12px !important;
    right: 28px;
}

.search-results-list-search__header .btn--search__content, .search-results-list-search__header .search-results-header__description, .social-share__item button:after, .social-share__trigger:after {
    display: none;
}

.btn--arrow .btn__arrow-container, .btn--arrow .search-results-list-search__header .btn__search-container, .search-results-list-search__header .btn--arrow .btn__search-container, .search-results-list-search__header .btn--search.FormFileUpload__Label .btn__arrow-container, .search-results-list-search__header .btn--search.FormFileUpload__Label .btn__search-container, .search-results-list-search__header .btn--search.FormResetButton .btn__arrow-container, .search-results-list-search__header .btn--search.FormResetButton .btn__search-container, .search-results-list-search__header .btn--search.FormSubmitButton .btn__arrow-container, .search-results-list-search__header .btn--search.FormSubmitButton .btn__search-container, .search-results-list-search__header .btn--search.btn .btn__arrow-container, .search-results-list-search__header .btn--search.btn .btn__search-container {
    width: 1em;
    height: 1em;
    position: relative;
}

.anchor__arrow.arrow--left {
    transform: rotate(180deg);
}

.arrow--right, .arrow--left {
    padding-bottom: 0;
    margin-top: 0;
    margin-left: 10px;
    background-repeat: no-repeat;
}

@media (min-width:48em) {
    .search-results-list-search__header {
        padding-left: 80px;
        padding-right: 80px;
    }

    .search-results-list-search__header .btn--search__content, .search-results-list-search__header .search-results-header__description {
        display: inline-block;
    }

    .search-results-list-search__header .search-bar__container {
        max-width: 600px;
        border: 0;
    }

    .search-results-list-search__header .search-bar__input {
        border: 1px solid color(axa-silver);
        border-radius: 3px;
        width: 500px;
    }

    .search-results-list-search__header .search-bar-btn__submit {
        margin: 0 0 0 20px;
        text-transform: none;
        border-radius: 3px;
        min-width: 140px;
        padding: 0 40px 0 20px;
    }

    .search-results-list-search__header .search-bar__submit-icon {
        fill: color(plain-white);
        opacity: 1;
        width: 15px;
        height: 15px;
    }

    .search-results-list-search__header .btn--search.FormFileUpload__Label .btn__search, .search-results-list-search__header .btn--search.FormResetButton .btn__search, .search-results-list-search__header .btn--search.FormSubmitButton .btn__search, .search-results-list-search__header .btn--search.btn .btn__search {
        padding-top: 20px !important;
        right: 32px;
    }
}

.search-results-list {
    padding: 40px 20px;
}

.search-results-list__container {
    max-width: 1160px;
    margin: 0 auto;
}

.search-results-list__header {
    text-align: center;
    padding-bottom: 20px;
    max-width: 768px;
    margin: 0 auto;
}

.search-results-list-header__title {
    margin: 0;
}

.search-results-list-header__content {
    padding-top: 10px;
}

.search-results-list-list {
    padding: 0;
    margin: 0;
}

.search-results-list-list__item {
    list-style: none;
    margin: 0;
    border-bottom: 1px solid color(mercury);
}

.search-results-list-list__item:last-of-type {
    border: 0;
}

.search-results-list__footer {
    padding-top: 20px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.search-results-list__cta {
    margin: 0 auto;
}

.search-results-list__pagination {
    margin: auto;
    max-width: 100%;
}

.search-results-list-download-icon {
    fill: color(axa-blue);
    height: 18px;
    width: 18px;
    position: absolute;
    margin-top: 0;
    margin-left: 5px;
}

.search-results-list-summary__container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    min-height: 110px;
    padding: 20px 40px;
}

.search-results-list-summary__container:focus, .search-results-list-summary__container:hover {
    background-color: rgba(95,95,95,.15);
    outline: 0;
}

.search-results-list-summary__date {
    font-family: "SourceSansPro",Arial,sans-serif;
    font-weight: 400;
    font-size: .75rem;
    line-height: 1.5em;
    letter-spacing: .02em;
    text-transform: uppercase;
}

@media (min-width:48em) {
    .search-results-list-summary__date {
        font-size: .875rem;
        line-height: 1.21em;
        letter-spacing: .02em;
    }
}

@media (min-width:62em) {
    .search-results-list-summary__date {
        font-size: .875rem;
        line-height: 1.21em;
        letter-spacing: .02em;
    }
}

@media (min-width:75em) {
    .search-results-list-summary__date {
        font-size: .875rem;
        line-height: 1.21em;
        letter-spacing: .02em;
    }
}

.search-results-list-summary__time {
    font-family: "SourceSansPro",Arial,sans-serif;
    font-weight: 400;
    font-size: .75rem;
    line-height: 1.5em;
    letter-spacing: .02em;
    text-transform: uppercase;
}

@media (min-width:48em) {
    .search-results-list-summary__time {
        font-size: .875rem;
        line-height: 1.21em;
        letter-spacing: .02em;
    }
}

@media (min-width:62em) {
    .search-results-list-summary__time {
        font-size: .875rem;
        line-height: 1.21em;
        letter-spacing: .02em;
    }
}

@media (min-width:75em) {
    .search-results-list-summary__time {
        font-size: .875rem;
        line-height: 1.21em;
        letter-spacing: .02em;
    }
}

.search-results-list-summary__title {
    margin-bottom: 5px;
}

.search-results-list-summary__description {
    font-family: "SourceSansPro",Arial,sans-serif;
    font-weight: 400;
    font-size: .75rem;
    line-height: 1.5em;
    letter-spacing: .02em;
    font-size: 16px !important;
}

@media (min-width:48em) {
    .search-results-list-summary__description {
        font-size: 1rem;
        line-height: 1.5em;
        letter-spacing: .02em;
    }
}

@media (min-width:62em) {
    .search-results-list-summary__description {
        font-size: 1rem;
        line-height: 1.5em;
        letter-spacing: .02em;
    }
}

@media (min-width:75em) {
    .search-results-list-summary__description {
        font-size: 1rem;
        line-height: 1.5em;
        letter-spacing: .02em;
    }
}

.search-results-list-summary__link {
    font-size: 16px !important;
}

@media (min-width:48em) {
    .search-results-list-summary__link {
        font-size: .875rem;
        line-height: 1.21em;
        letter-spacing: .02em;
    }
}

@media (min-width:62em) {
    .search-results-list-summary__link {
        font-size: .875rem;
        line-height: 1.21em;
        letter-spacing: .02em;
    }
}

@media (min-width:75em) {
    .search-results-list-summary__link {
        font-size: .875rem;
        line-height: 1.21em;
        letter-spacing: .02em;
    }
}

.search-results-list-summary__link {
    font-family: "SourceSansPro",Arial,sans-serif;
    font-weight: 400;
    font-size: .75rem;
    line-height: 1.5em;
    letter-spacing: .02em;
    color: color(axa-blue);
}

@media (min-width:48em) {
    .search-results-list-summary__link {
        font-size: 1rem;
        line-height: 1.5em;
        letter-spacing: .02em;
    }
}

@media (min-width:62em) {
    .search-results-list-summary__link {
        font-size: 1rem;
        line-height: 1.5em;
        letter-spacing: .02em;
    }
}

@media (min-width:75em) {
    .search-results-list-summary__link {
        font-size: 1rem;
        line-height: 1.5em;
        letter-spacing: .02em;
    }
}

a.search-results-list-content__link p {
    margin-top: 15px;
}

@media (min-width:48em) {
    .search-results-list__cta {
        margin: auto 0 auto auto;
    }

    .search-results-list__header {
        padding-bottom: 40px;
    }

    .search-results-list__footer {
        padding-top: 40px;
    }

    .search-results-list-summary__container {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .search-results-list-summary__date {
        font-family: "SourceSansPro",Arial,sans-serif;
        font-weight: 400;
        font-size: .75rem;
        line-height: 1.5em;
        letter-spacing: .02em;
        width: 20%;
        min-width: 200px;
        padding: 0 30px;
        margin-top: 5px;
        text-transform: none;
    }
}

@media (min-width:48em) and (min-width:48em) {
    .search-results-list-summary__date {
        font-size: 1rem;
        line-height: 1.5em;
        letter-spacing: .02em;
    }
}

@media (min-width:48em) and (min-width:62em) {
    .search-results-list-summary__date {
        font-size: 1rem;
        line-height: 1.5em;
        letter-spacing: .02em;
    }
}

@media (min-width:48em) and (min-width:75em) {
    .search-results-list-summary__date {
        font-size: 1rem;
        line-height: 1.5em;
        letter-spacing: .02em;
    }
}

@media (min-width:48em) {
    .search-results-list-summary__title {
        margin-bottom: 15px;
    }

    .search-results-list-summary__description {
        font-family: "SourceSansPro",Arial,sans-serif;
        font-weight: 400;
        font-size: .75rem;
        line-height: 1.5em;
        letter-spacing: .02em;
        font-size: 18px !important;
    }
}

@media (min-width:48em) and (min-width:48em) {
    .search-results-list-summary__description {
        font-size: .875rem;
        line-height: 1.21em;
        letter-spacing: .02em;
    }
}

@media (min-width:48em) and (min-width:62em) {
    .search-results-list-summary__description {
        font-size: .875rem;
        line-height: 1.21em;
        letter-spacing: .02em;
    }
}

@media (min-width:48em) and (min-width:75em) {
    .search-results-list-summary__description {
        font-size: .875rem;
        line-height: 1.21em;
        letter-spacing: .02em;
    }
}

@media (min-width:48em) {
    .search-results-list-summary__link {
        font-family: "SourceSansPro",Arial,sans-serif;
        font-weight: 400;
        font-size: .75rem;
        line-height: 1.5em;
        letter-spacing: .02em;
        font-size: 18px !important;
    }
}

@media (min-width:48em) and (min-width:48em) {
    .search-results-list-summary__link {
        font-size: .875rem;
        line-height: 1.21em;
        letter-spacing: .02em;
    }
}

@media (min-width:48em) and (min-width:62em) {
    .search-results-list-summary__link {
        font-size: .875rem;
        line-height: 1.21em;
        letter-spacing: .02em;
    }
}

@media (min-width:48em) and (min-width:75em) {
    .search-results-list-summary__link {
        font-size: .875rem;
        line-height: 1.21em;
        letter-spacing: .02em;
    }
}

@media (min-width:48em) {
    .search-results-list-summary__container:focus, .search-results-list-summary__container:hover {
        padding-left: 70px;
    }
}

@media (min-width:48em) and (min-width:60em) {
    .search-results-list {
        padding: 80px 40px;
    }
}