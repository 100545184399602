//==========================================================================================
//
//  Magazine related articles
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================


.magazine-related-articles {
    background-color: color(mercury);
    padding-top: 40px !important;
}

.magazine-related-articles-mobile {
    background-color: color(mercury);
    padding-top: 35px !important;
}

.magazine-related-articles-title {
    padding-left: 20px;
}

.magazine-related-articles-block {
    display: block;
    width: 100%;
    height: auto;
    padding: 20px;
    font-family: $root-font-family;
}

.magazine-related-articles-block__container {
    width: 100%;
    height: auto;
    margin: 0 auto;
    text-align: left;
}

.magazine-related-articles-block__container img {
    display: none;
}

.magazine-related-articles-block__btn-wrapper {
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 5px;
    margin-bottom: 10px;
    flex-flow: row wrap;
    align-items: flex-start;
    padding:10px 0;
}



.magazine-related-articles-block__btn-wrapper a.btn {
    margin-left: 0px;
}

.magazine-related-articles-block__btn {
    min-width: 220px;
    margin: 0px;
}


//==========================================================================================
//  Media Queries
//==========================================================================================
@include mq($from: screen960) {
    .magazine-related-articles-block {
        padding: 40px;
    }
}

@include mq($from: screen992) {

    .magazine-related-articles-title {
        padding-left: 40px;
        /*font-size: 32px*/
    }

    .magazine-related-articles-block__container img {
        display: block;
    }

    .magazine-related-articles-mobile {
        display: none;
    }
}
