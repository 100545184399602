//==========================================================================================
//
//  Carousel Pagination Component
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================
.carousel-pagination {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    justify-content: center;

    .carousel-pagination__item {
        min-width: 0;
        min-height: 0;
        width: 10px;
        height: 10px;
        display: block;
        padding: 0;
        margin: 0 15px;
        border-radius: 100%;
        background: color(component-grey-background);

        &:after {
            display: none;
        }
    }
}