//==========================================================================================
//
// Magazine article
//
//==========================================================================================

.magazine-article-full-width {

}

.magazine-article-heading {
    text-align: center;
    padding-bottom: 30px;
    font-weight: bold;
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
}

.magazine-article-heading-title {
    margin-top: 0;
    text-align: center;
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.magazine-article-heading-subtitle {
    @include typography(typo-18, 600, $root-font-family);
    margin-bottom: 0;
    padding:0 20px;
}

.magazine-article-heading-date {
    @include typography(typo-16, 600, $root-font-family);
    margin-top:0;
    margin-bottom: 0;
}

@include mq($from: screen992) {

    .magazine-article-full-width {
        padding-left: 40px;
    }

    .magazine-article-repository-image {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-color: color(plain-white);
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
    }

    .magazine-article-repository-image-opacity {
        height: 100%;
        width: 100%;
        position: absolute;
        display: block;
        background-color: color(plain-white);
        opacity: 0;
        display: none;
    }

    .magazine-article-heading {
        padding-right: 50px;
        padding-left:0px;
    }
}


