//==========================================================================================
//
//  Breadcrumbs Component
//
//==========================================================================================

//==========================================================================================
//  Base Styles
//==========================================================================================
.breadcrumbs {
    width: 100%;
    position: absolute;
    z-index: 1;
    height: auto;
    top: 0;
    left: 0;
    padding: 10px 20px;
}

.breadcrumbs__container {
    max-width: $standard-max-width;
    margin: 0 auto;
    display: block;
    width: 100%;
    height: auto;
}

.breadcrumbs__list {
    padding: 0;
    list-style: none;
    margin: 0;
    display: flex;
    flex-flow: row wrap;
}

.breadcrumbs__item {
    @include typography(typo-14, normal, $root-font-family);
    margin: 0;
    display: flex;
    flex-flow: row wrap;

    &:before {
        content: "/";
        display: block;
        margin: 0 5px;
        color: color(plain-white);
    }

    &:nth-child(1) {
        &:before {
            display: none;
        }
    }

    a {
        color: color(plain-white);
    }
}

//==========================================================================================
//  Media Queries
//==========================================================================================
@include mq($from: screen480) {
    .breadcrumbs {
        padding: 10px 40px;
    }

}

//==========================================================================================
//  axa.co.uk style updates
//==========================================================================================

.breadcrumbs__wrapper {
    padding: 15px 0;
}

/*.breadcrumbs__wrapper:last-of-type {
    margin: 30px 0;
}*/

.breadcrumbs__container {
    max-width: 1200px;
    padding-left: 15px;
}

li.breadcrumbs__item a {
    font-family: "SourceSansPro",Arial,sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
    color: #00008F;
    text-align: left;
    line-height: 20px;
    text-decoration: underline;
}

li.breadcrumbs__item:last-of-type a {
    color: #636b72;
    text-decoration: none;
}

.breadcrumbs__item:before {
    content: "/";
    display: block;
    margin: 3px 10px;
    color: #818A91;
    font-size: 20px;
}

@media (min-width: 37.5em) {
    .breadcrumbs__container {
        max-width: 1200px;
        padding-left: 30px;
    }

    li.breadcrumbs__item a {
        font-size: 16px;
    }
}

@include mq($from: screen320) {
    .breadcrumbs__container {
        margin: 0;
        padding-left: 25px
    }
}

@include mq($from: screen720) {
    .breadcrumbs__container {
        max-width: 1200px;
        margin: 0;
        padding-left: 40px;
    }
    }

@include mq($from: screen960) {
    .breadcrumbs__container {
        max-width: 1200px;
        margin: 0;
        padding-left: 50px;
    }
}


    @include mq($from: screen1140) {
        .breadcrumbs__container {
            max-width: 1200px;
            margin: 0;
            padding-left: 70px;
        }

        .breadcrumbs__wrapper {
            padding-left: 50px;
        }

        .breadcrumbs__wrapper:first-of-type {
            display: block;
        }
        /*.breadcrumbs__wrapper:last-of-type {
        margin: 50px 0;
    }*/
    }
    @media (min-width: 62em) {
    .breadcrumbs__wrapper {
        padding-left: 0;
    }
}

@media (min-width: 71.25em) {
    .breadcrumbs__container {
        margin: 0 auto;
    }
}

@media (min-width:100em) {
    .breadcrumbs__container {
        margin: 0 auto;
    }
}

