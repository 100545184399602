//==========================================================================================
//
//  Navigation
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================
.main-navbar {
    width: 100%;
    height: auto;
    background-color: color(plain-white);
    display: flex;
    position: relative;
    z-index: 1;
    padding-bottom: 10px;
    border-bottom: solid 1px #e5e5e5;
}

.main-navbar__wrapper {
    width: 100%;
    height: auto;
    max-width: 1140px;
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap;
    padding: 10px 0 10px 25px;
}

.main-navbar__logo {
    height: auto;
    width: 155px;
    align-self: flex-start;
    transition: opacity 0.3s ease;
}

.main-navbar__logo-image {
    max-width: 100%;
    height: auto;
    max-height: 50px;
}

.main-navbar__container {
    margin-left: auto;
    display: flex;
    flex-flow: row;
    width: calc(100% - 155px);
    align-self: center;
    align-items: center;
    justify-content: flex-end;
    padding-top: 5px;
}

.mega-menu {
    width: calc(100% - 70px);
    display: none;
}

.mega-menu__list {
    display: flex;
    flex-flow: row wrap;
    list-style: none;
    justify-content: flex-end;
    padding-right: 5px;
    width: 100%;
    padding-left: 0;
    margin: 0;
    opacity: 1;
    transition: 0.3s opacity ease;
}

.mega-menu__item {
    @include typography(typo-14, normal, $root-font-family);
    color: color(axa-blue);
    width: auto;
    height: auto;
    text-transform: uppercase;
    line-height: 100%;
    padding-bottom: 8px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    font-weight: bold;
    margin: 0;

    &:last-of-type {
        padding-right: 10px;
    }

    .sn-accordion-arrow-container {
        display: none;
    }
}

.mega-menu__item.has-children {
    .sn-accordion-arrow-container {
        display: block;
        width: auto;
    }

    svg.sn-accordion-arrow {
        width: 18px;
        height: 18px;
        margin-top: 24px;
        margin-left: -2px;
    }
}

.mega-menu__tracker {
    width: 150px;
    height: 3px;
    background-color: color(flamingo);
    transform: translateX(-50%);
    margin-top: -5px;
    opacity: 0;
    transition: 0.3s transform ease, 0.3s width ease, opacity 0.3s ease;
    pointer-events: none;
}

.main-navbar__spacer {
    width: 1px;
    height: 30px;
    margin-right: 0;
    background-color: color(axa-silver);
}

/* Mobile Trigger */
.main-navbar__mobile-trigger {
    width: 25px;
    height: 20px;
    display: flex;
    margin-left: 20px;
    margin-right: 20px;
    position: relative;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    order: 3;
}

.main-navbar__mobile-trigger__icon {
    display: block;
    background: color(burnt-sienna);
    width: 100%;
    height: 2px;

    &:before, &:after {
        position: absolute;
        content: "";
        display: block;
        background: color(burnt-sienna);
        width: 100%;
        height: 2px;
        transition: transform 0.3s ease;
    }

    &:before {
        top: 0;
        left: 0;
    }

    &:after {
        bottom: 0;
        left: 0;
    }
}

/* Active Trigger */
[data-active].main-navbar__mobile-trigger {
    .main-navbar__mobile-trigger__icon {
        background-color: transparent;

        &:before {
            transform: rotate3d(0, 0, 1, 45deg) translateY(-50%);
            transform-origin: 50% 50%;
            top: 50%;
        }
        &:after {
            transform: rotate3d(0, 0, 1, -45deg) translateY(-50%);
            box-shadow: 0 0 0 2px #ffffff;
            transform-origin: 50% 50%;
            top: 50%;
        }
    }
}

//==========================================================================================
//  Modifiers
//==========================================================================================
//==========================================================================================
// Primary Navigation Items Tweaks
// updates main navigation item styling
//==========================================================================================
[data-primary-nav-item] {
    line-height: 65px;
    display: inline-block;
    vertical-align: middle;
    padding: 0 10px;
    outline: none;
    cursor: pointer;

    /* Design Amends */
    // letter-spacing: 0.1em;
    color: color(axa-blue);

    &:hover {
        color: color(dark-filter-axa-blue);
    }
}

/* Media Queries */
@include mq($from: screen480) {
    .main-navbar__logo {
        width: 60px;
        padding-top: 0;
        // margin-top: -10px;
    }
    .main-navbar__container {
        width: calc(100% - 60px);
    }
}

@include mq($from: screen768) {
    .main-navbar__wrapper {
        padding-left: 40px;
    }
}

@include mq($from: screen1140) {

    .main-navbar__wrapper {
        padding: 0 0 0 40px;
    }

    .main-navbar__logo {
        margin-top: 10px;
    }

    /* Show desktop nav */
    .mega-menu {
        display: block;
    }

    .main-navbar__spacer {
        margin-right: 10px;
    }

    .main-navbar__mobile-trigger {
        display: none;
    }

    .main-navbar {
        /* Required to prevent z-index overly on IE11 */
        z-index: auto;
        padding-bottom: 0;
    }

}