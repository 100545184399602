//==========================================================================================
//
//  Navigation Tray
//
//==========================================================================================

//==========================================================================================
//  Base Styles
//==========================================================================================
/* Navigation Tray */
.mega-menu-tray {
    width: 100vw;
    height: auto;
    background-color: color(plain-white);
    position: absolute;
    top: 100%;
    left: 0;
    visibility: hidden;
    overflow: hidden;
    z-index: -2;
    transform: translateY(-100%);
    display: block;
    border-top: 1px color(mercury) solid;
    border-bottom: 1px color(mercury) solid;
}

.mega-menu-tray__content {
    width: 100%;
    height: auto;
    max-width: 1140px;
    margin: 0 auto;
    display: block;
    padding: 60px 40px 60px 40px;
    overflow: hidden;
    position: relative;
}

.mega-menu-tray__container {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: row wrap;
    position: relative;
}

.mega-menu-tray__column {
    width: 36%;
    height: auto;
    padding: 0;
    display: block;

    /* Set the required width of the first column */
    &:nth-child(1) {
        width: 38%;
        padding-right: 2%;
    }

    /* Set the required width of the second column */
    &:nth-child(2) {
        width: 26%;
    }
}

.mega-menu-tray__link {
    // Add anchor styling and make the font regular version of the root font
    @include typography(tray-item, normal, $root-font-family);
}

/* Navigation tray info */
.mega-menu-tray__info {
    width: 100%;
    height: auto;
    display: block;
    padding: 0 10px 0 0;
    text-transform: none;
    color: color(dark-grey);

    .anchor {
        margin-top: 30px;
    }
}

.mega-menu__item .mega-menu-tray__info-link {
    @include typography(typo-14, normal, $root-font-family);
    padding: 0;
    text-transform: none;
}

/* Tray promo */
.mega-menu-tray__promo {
    width: 100%;
    height: auto;
    padding: 0 20px;

    a{
        padding: 0;
    }

}

.mega-menu-tray__promo-image {
    width: 100%;
    height: auto;
}

.mega-menu-tray__promo-title {
    @include typography(typo-24, bold, $root-font-family-serif);
    color: color(dark-grey);
    text-transform: none;
    margin-top: 20px;

    a {
        color: inherit;
    }
}

/* Tray navigation */
.mega-menu-tray__nav {
    width: 100%;
    list-style: none;
    position: relative;
    overflow: visible;
    margin: 0;
}

.mega-menu-tray__nav-item {
    @include typography(typo-16, normal, $root-font-family);
    text-transform: none;
    margin: 10px 0;

    .anchor {
        @include typography(typo-16, normal, $root-font-family);
        margin: 10px 0;
        text-transform: none;
        font-weight: normal;

        &:focus {
            /* Overide default anchor styling */
            opacity: 1;
        }
    }

}

/* Handle sub menu open */
[data-open].mega-menu-tray__column {

    [data-active] {

        &.anchor {
            opacity: 1;
            font-weight: bold;
        }

        .mega-menu-tray__subnav-item {
            .anchor {
                opacity: 1;
            }
        }
    }
}

/* Back Button */
.mega-menu-tray__back-button {
    width: 40px;
    height: 40px;
    background-color: color(plain-white);
    position: absolute;
    left: -53%;
    border-radius: 100%;
    top: 2%;
    cursor: pointer;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    border: 1px color(axa-blue) solid;

    .mega-menu-tray__back-button-icon {
        width: 15px;
        height: 15px;
        display: block;
        fill: color(axa-blue);
    }
}

/* Tray Subnavigation */
.mega-menu-tray__subnav {
    width: 130%;
    height: auto;
    min-height: 100%;
    border-left: color(mercury) 1px solid;
    position: absolute;
    top:-10px;
    left: 115%;
    opacity: 0;
    z-index: 2;
    display: flex;
    flex-flow: row wrap;
    display: none;
    list-style: none;
    padding-left: 0;
    margin: 0;
}

.mega-menu-tray__subnav-wrapper {
    width: 100%;
    height: auto;
    min-height: 100%;
}

.mega-menu-tray__subnav-container {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: row wrap;
}

.mega-menu-tray__subnav--extended {
    width: 260%;
    margin-left: 20%;
    padding-left: 30%;

    .mega-menu-tray__subnav-column {
        width: 48%;
        padding: 0 1%;
    }
}

.mega-menu-tray__subnav-column {
    width: 100%;
    // padding-left: 20px;
    list-style: none;
    margin: 0;
}

.mega-menu-tray__subnav-item {
    @include typography(typo-14, normal, $root-font-family);
    list-style: none;
    position: relative;
    overflow: visible;
    margin: 0 10px;
}

/* Animate In Sub Navigation */
.mega-menu-tray__subnav[data-nav-level-2="active"] {
    left: 110%;
    opacity: 1;
}

//==========================================================================================
//  Modifiers
//==========================================================================================
//==========================================================================================
// Subnav mi
// updates main navigation item styling
//==========================================================================================