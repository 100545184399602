//==========================================================================================
//
//  Forms Component
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================

.form-panel__chevron {
	&:after {
		content: "";
		display: block;
		width: 25px;
		height: 25px;
		transform: rotate(45deg);
		position: absolute;
		left: 40px;
		bottom: -10px;
		z-index: 1;
	}
}

.FormTextbox__Input {
	@include sharedFormStyles();
}



textarea.FormTextbox__Input {
	resize: vertical;
}

.FormSelection select {
	@include sharedFormStyles();
	background: url("/assets/images/svg/arrow--down-select-box.svg") no-repeat 98%
		color(plain-white);
	background-size: 25px 25px;
}

.Form__Element__Caption {
	display: block;
	padding-bottom: 10px;
	@include typography(typo-18, bold, $root-font-family);
	color: color(dark-grey);
}

.Form__Title {
	@include typography(typo-24, bold, $root-font-family);
	color: color(dark-grey);
	margin-bottom: 20px;
}

.Form__Description {
	@include typography(typo-18, normal, $root-font-family);
	color: color(dark-grey);
	margin-bottom: 10px;
}

.FormRange__Min,
.FormRange__Max {
	display: none;
}

div.Form__Element {
	padding-bottom: 20px;
}

.EPiServerForms {
	width: 100%;
}

.FormSubmitButton,
.FormResetButton {
	@extend .btn;
	margin: 25px 25px 0 0;
	display: inline;
	&:disabled {
		background-color: color(light-grey) !important;
		&::after {
			display: none;
		}
	}
}

.FormResetButton {
	@extend .btn--keyline;

	&:hover,
	&:focus {
		color: color(dark-grey);
	}
}

.Form__NavigationBar__Action.FormExcludeDataRebind.btnNext, .Form__NavigationBar__Action.FormExcludeDataRebind.btnPrev {
    &:hover {
        color: color(plain-white);
    }
}

    .ValidationFail {
        & > textarea,
        & > input[type="text"],
        & > input[type="number"],
        & > input[type="url"],
        & > select {
            border: 1px solid color(axa-red);
        }
    }

    .Form__NavigationBar {
        @include typography(typo-18, normal, $root-font-family);
        display: flex;
        align-items: center;
        margin-left: -10px;
    }

    @media (max-width: 37.5em) {
        .Form__NavigationBar {
            flex-direction: column;
            align-items: flex-start;
        }

        .Form__NavigationBar__ProgressBar {
            order: 1;
            width: 90% !important;
            margin-left: 10px !important;
        }
        .Form__NavigationBar__Action.FormExcludeDataRebind.btnPrev {
            order: 2;
        }
        .Form__NavigationBar__Action.FormExcludeDataRebind.btnNext {
            order: 3;
        }
    }

    //Error label style
    span.Form__Element__ValidationError,
    .Form__Status__Message {
        @include typography(typo-16, normal, $root-font-family);
        display: block;
        color: color(axa-red);
    }

    .Form__Status__Message {
        margin-bottom: 20px;
    }

    .Form__Status__Message.hide {
        display: none;
    }
    //==========================================================================================
    //  Radio/Checkbox styles - these are targeted towards Epi's provided form sample
    //==========================================================================================
    %checkbox-radio-label {
        padding-left: 30px;
        position: relative;
        @include typography(typo-18, normal, $root-font-family);
        color: color(dark-grey);
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:not(:last-child) {
            margin-bottom: 8px;
        }

        &:before {
            content: "";
            visibility: visible;
            width: 20px;
            height: 20px;
            left: 0;
            top: 0;
            border: 1px solid color(axa-silver);
            position: absolute;
        }
        //Need to have content even if invisible, otherwise safari requires a double tap on radio/chk (first one reveals the hover state)
        &:after {
            top: 6px;
            content: "";
            visibility: visible;
            left: 6px;
            width: 8px;
            height: 8px;
            position: absolute;
            background-color: rgba(255, 255, 255, 0);
            border: 1px solid rgba(255, 255, 255, 0);
        }

        &:not([disabled]):not(.input-checked):hover::before {
            border-color: color(axa-blue);
        }

        &:not([disabled]):not(.input-checked):hover::after {
            background-color: rgba(255, 255, 255, 0);
            border: 1px solid color(axa-blue);
        }

        &.input-checked::after {
            border: 1px solid color(axa-blue);
            background-color: color(axa-blue);
        }
    }

    .FormChoice:not(.FormChoice--Image) {
        input[type="radio"] {
            display: none;
        }

        label {
            @extend %checkbox-radio-label;

            &:before {
                border-radius: 50%;
            }

            &:after {
                border-radius: 50%;
            }
        }
    }

    .FormChoice__Input--Checkbox {
        display: none;
    }

    .checkbox-container {
        input[type="checkbox"] {
            display: none;
        }

        label {
            @extend %checkbox-radio-label;
        }
    }
    //==========================================================================================
    //  Range input styles
    //==========================================================================================
    .Form__Element.FormRange > span {
        position: relative;
        display: block;
        height: 45px;
    }

    .FormRange__Input {
        $track-w: 300px;
        $track-h: 4px;
        $thumb-d: 20px;
        $track-c: color(axa-silver);
        $filll-c: color(axa-blue);
        $dist: $track-w - $thumb-d;

        @mixin track($fill: 0) {
            box-sizing: border-box;
            border: none;
            width: $track-w;
            height: $track-h;
            background: $track-c;

            @if $fill == 1 {
                .js-enabled & {
                    background: linear-gradient($filll-c, $filll-c) 0 / var(--sx) 100% no-repeat $track-c;
                }
            }
        }

        @mixin fill() {
            height: $track-h;
            background: $filll-c;
        }

        @mixin thumb() {
            box-sizing: border-box;
            border: 2px solid color(plain-white);
            width: $thumb-d;
            height: $thumb-d;
            border-radius: 50%;
            background: $filll-c;
            background-clip: padding-box;

            &:active {
                background: color(plain-white);
                border-color: $filll-c;
            }
        }

        &,
        &::-webkit-slider-thumb {
            -webkit-appearance: none;
        }

        box-sizing: content-box;
        position: relative;
        border-left: 2px solid $filll-c;
        border-right: 2px solid color(light-grey);
        --range: calc(var(--max) - var(--min));
        --ratio: calc((var(--val) - var(--min)) / var(--range));
        --sx: calc(0.5 *#{$thumb-d} + var(--ratio) * (100% - #{$thumb-d}));
        margin: 0;
        padding: 0;
        width: $track-w;
        height: $thumb-d;
        background: transparent;

        &::-webkit-slider-runnable-track {
            @include track(1);
        }

        &::-moz-range-track {
            @include track;
        }

        &::-ms-track {
            @include track;
            color: transparent;
        }

        &::-moz-range-progress {
            @include fill;
        }

        &::-ms-fill-lower {
            @include fill;
        }

        &::-webkit-slider-thumb {
            margin-top: 0.5 * ($track-h - $thumb-d);
            @include thumb;
        }

        &::-moz-range-thumb {
            @include thumb;
        }

        &::-ms-thumb {
            margin-top: 0;
            @include thumb;
        }

        &::-ms-tooltip {
            display: none;
        }

        ~ .Range__current-value {
            @include typography(typo-16, normal, $root-font-family);
            color: color(dark-grey);
            display: block;
            position: absolute;
            bottom: 0;
        }
    }
    //==========================================================================================
    //  Image select styles - these are targeted towards Epi's provided form sample
    //==========================================================================================
    .FormChoice--Image__Item__Caption {
        display: none;
    }

    .FormChoice--Image__Item {
        width: 100px;
        height: 100px;
        display: inline-block;
        cursor: pointer;
        margin-right: 5px;
        margin-bottom: 5px;
        user-select: none;

        & img {
            height: 100%;
            width: auto;
            border: 1px solid color(mercury);
            display: block;
            pointer-events: none;
            user-select: none;

            &:hover {
                border-color: color(axa-blue);
            }
        }

        & input[type="radio"]:checked ~ img {
            border: 1px solid color(axa-blue);
        }
    }
    //==========================================================================================
    //  Form upload styles
    //==========================================================================================
    .FormFileUpload__Input {
        display: none;
    }

    .FormFileUpload__Wrapper {
        display: flex;
    }

    .FormFileUpload__Label {
        @extend .btn;
        margin: 0;
    }

    .FormFileUpload__SelectedFile {
        @include sharedFormStyles();
    }

//==========================================================================================
//  Form Captcha styles
//==========================================================================================
.FormCaptcha .Form__Element__Caption {
    width:100%;
}

.FormCaptcha .FormTextbox__Input {
    font-family: "SourceSansPro",Arial,sans-serif;
    font-weight: 400;
    font-size: .95rem;
    line-height: 1.5em;
    letter-spacing: .02em;
    display: inline-block;
    padding: 10px 20px;
    padding-left: 20px;
    border: 1px solid #ccc;
    outline-offset: -2px;
    vertical-align: top !important;
    max-width: 100%;
    width: 9rem !important;
    margin-left: 0px;
    margin-top: 10px;
    min-width: 22.3%;
}

.FormCaptcha .FormCaptcha__Refresh {
    max-width: 100% !important;
    vertical-align: top !important;
    margin-left: 0px !important;
}


/*.FormCaptcha .FormCaptcha__Refresh button {
    margin-left: 0px;
}*/
    //==========================================================================================
    //  Media Queries
    //==========================================================================================
@include mq($from: screen768) {
    .form-panel__chevron {
        &:after {
            top: 10%;
            right: -20px;
            bottom: auto;
            left: auto;
            height: 40px;
            width: 40px;
        }
    }

    .checkbox-container,
    .FormChoice:not(.FormChoice--Image) {
        label:not(:last-child) {
            margin-bottom: 4px;
        }

        label:after {
            top: 10px;
        }

        label:before {
            top: 4px;
        }
    }

    .FormCaptcha .FormTextbox__Input {
        margin-left: 10px;
    }

    .FormCaptcha .FormCaptcha__Image {
        width: 26%;
        padding-top: 10px;
    }
}


