//==========================================================================================
//
//  Social Share
//
//==========================================================================================

//==========================================================================================
//  Base Styles
//==========================================================================================
.social-share {
    position: absolute;
    top: -50px;
    right: 0;
    width: auto;
    height: auto;
    min-width: 0;
    display: flex;
    flex-flow: row nowrap;

     /* On hover show the social share list */
     &:hover {
        > .social-share__list {
            opacity: 1;
        }
    }

}

.social-share__trigger {
    width: 50px;
    height: 50px;
    min-width: 0;
    background-color: color(component-social-share);
    margin: 0;
    padding: 0;

    &:after {
        // Remove Default Swoosh
        display: none;
    }

    svg {
        height: 20px;
        fill: color(plain-white)
    }
}
.social-share__list {
    display: flex;
    /* Order -1 is used to position the list before the trigger on mobile */
    order: -1;
    width: auto;
    opacity: 0;
    transition: opacity 0.3s ease;
    margin: 0;
}

.social-share__list[aria-hidden="false"] {
    opacity: 1;
    transition: opacity 0.3s ease;
}

.social-share__item {
    width: 50px;
    margin: 0;
    height: 50px;
    margin-top: 0;
    overflow: hidden;
    border-right: 2px solid white;
    display: block;

    button {
        min-width: 0;
        margin: 0;
        width: 50px;
        height: 50px;
        background-color: color(mercury);
        transition: background-color 0.3s ease;
        padding: 0;

        &:focus, &:hover {
            background-color: color(component-social-share);

            svg {
                fill: color(plain-white);
            }
        }

        &:after {
            display: none;
        }

        svg {
            height: 20px;
            fill: color(component-social-share);
            transition: 0.3s fill ease;
        }
    }
}



//==========================================================================================
//  Media Queries
//==========================================================================================
@include mq($from: screen480) {

    .social-share {
        top: -70px;
    }

    .social-share__trigger {
        width: 70px;
        height: 70px;
    }

    .social-share__item {
        width: 70px;
        height: 70px;

        button {
            width: 70px;
            height: 70px;
        }
    }
}

@include mq($from: screen768) {

    .social-share {
        position: absolute;
        top: 0;
        right: 0;
        width: 70px;
        height: 70px;
        min-width: 0;
        display: block;
    }

    .social-share__list {
        width: 100%;
        padding: 0;
        list-style: none;
        height: auto;
        overflow: hidden;
        flex-flow: row wrap;
    }

    .social-share__item {
        border-right: none;
        margin-right: 0;
        margin-top: 2px;
    }
}