//==========================================================================================
//
//  Footer
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================
.site-footer {
    background-color: color(axa-footer);
    width: 100%;
    height: auto;
    display: block;
}

.site-footer__container {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: row wrap;
    max-width: $standard-max-width;
    margin: 0 auto;
    padding: 20px 20px 10px 20px;
}

.site-footer__column {
    padding: 20px;
    width: 100%;
}

.site-footer__section {
    width: 100%;
    height: auto;
    display: block;
}

.site-footer__title {
    @include typography(typo-16, 600, $root-font-family);
    font-size: 16px;
    color: color(plain-white);
    margin-bottom: 25px;
}

.site-footer__title-drop-mobile {
    display: none;
}

/* Link List */

.site-footer__link-list {
    list-style: none;
    width: 100%;
    padding:0;
    margin: 0;
    height: auto;
}

.site-footer__link-item {
    @include typography(typo-14, normal, $root-font-family);
    width: 100%;
    display: block;
    margin-left: 0;
    margin-top:20px;
    margin-bottom:20px;

    a {
        color: color(plain-white);
        text-decoration: none;
        transition: 0.3s color ease;

        &:hover, &:focus {
            color: color(plain-white, 0.65);
        }
    }
}

/* Social Media */
.site-footer__social {
    display: flex;
    flex-flow: row wrap;
}

.site-footer__social-item {
    width: 25px;
    height: 25px;
    margin-right: 20px;
    opacity: 1;
    transition: 0.3s opacity ease;

    &:hover, &:focus {
        opacity: 0.65;
    }
}

.site-footer__social-image {
    width: 100%;
    height: 100%;
    fill: white;
}

/* Footer Base */
.site-footer__sub-footer {
    width: 100%;
    height: auto;
    background-color: color(axa-sub-footer);
    padding: 10px 0 10px;
    border-top: 1px color(plain-white, 0.25) solid;
}

.site-footer__sub-footer-container {
    width: 100%;
    max-width: $standard-max-width;
    height: auto;
    display: flex;
    margin: 0 auto;
    padding: 0 40px;
    flex-flow: row wrap;
    justify-content: flex-start;
}

.site-footer__language {
    width: 100%;
    display: block;
    height: auto;
    text-align: center;
    padding: 0;
    margin: 0;
}

.site-footer__language-item {
    @include typography(typo-14, normal, $root-font-family);
    display: inline-block;
    margin-right: 5px;
    margin-left: 0;

    a {
        text-decoration: none;
        color: color(plain-white, 0.65);
        transition: color 0.3s ease;

        &:hover, &:focus {
            color: color(plain-white, 1);
        }
    }

    &:after {
        content: "|";
        display: inline-block;
        margin-left: 10px;
        color: color(plain-white, 0.65);
    }

    &:last-child {
        &:after {
            display: none;
            margin-left: 0;
        }
    }
}

.site-footer__terms {
    width: auto;
    display: block;
    margin: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding: 0;
}

.site-footer__terms-item {
    @include typography(typo-14, normal, $root-font-family);
    display: inline-block;
    margin-left: 5px;
    color: color(plain-white, 0.65);

    a {
        color: inherit;
        text-decoration: none;
        transition: 0.3s color ease;

        &:hover, &:focus {
            color: color(plain-white, 1);
        }
    }

}
//==========================================================================================
//  Modifiers
//==========================================================================================
//==========================================================================================
// Language Active
// Adds an active state the the language selector in the footer
//==========================================================================================
.site-footer__language-item--active {
    a {
        color:color(plain-white, 1);
    }

    &:after {
        color: color(plain-white, 1);
    }
}

//==========================================================================================
// Social Column
// Adds additional styles to the column used for social media
//==========================================================================================
.site-footer__column--social {
    order: -1;
    width: 100%;
}

//==========================================================================================
//  Media Queries
//==========================================================================================
@include mq($from: screen480) {
    .site-footer__column {
        width: 33%;
    }

    .site-footer__column--social {
        width: 100%;
    }

    .site-footer__social-item {
        width: 25px;
        height: 25px;
        margin-bottom: 15px;
    }

    .site-footer__language {
        width: auto;
        height: auto;
    }

    .site-footer__terms {
        width: auto;
        display: block;
        margin-left: auto;
        margin-right: 0;
    }
}

@include mq($from: screen960) {
    .site-footer__column {
        width: 25%;
    }

    .site-footer__title-drop-mobile {
        display: block;
    }

    .site-footer__column--social {
        order: 1;
    }
}