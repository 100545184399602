//==========================================================================================
//
//  Typography
//
//==========================================================================================

//==========================================================================================
//  Typography mixin & styles
//==========================================================================================
@mixin typography( $type-class: typo-18, $font-weight: normal, $font-family: $root-font-family ) {
	// H1
	@if $type-class == h1 {
		font-family: $font-family;
		font-weight: $font-weight;
		font-size: rem(46);
		line-height: 1.13em;
		letter-spacing: 0.02em;
		// Tablet
		@include mq($from: screen768) {
			font-size: rem(78);
			line-height: 1.05em;
			letter-spacing: 0.02em;
		}
		// Small Desktop
		@include mq($from: screen992) {
			font-size: rem(78);
			line-height: 1.05em;
			letter-spacing: 0.02em;
		}
		// Large Desktop
		@include mq($from: screen1200) {
			font-size: rem(78);
			line-height: 1.05em;
			letter-spacing: 0.02em;
		}
	}
	// H2
	@else if $type-class == h2 {
		font-family: $font-family;
		font-weight: $font-weight;
		font-size: rem(40);
		line-height: 1.1em;
		letter-spacing: 0.02em;
		// Tablet
		@include mq($from: screen768) {
			font-size: rem(48);
			line-height: 1.16em;
			letter-spacing: 0.02em;
		}
		// Small Desktop
		@include mq($from: screen992) {
			font-size: rem(62);
			line-height: 1.16em;
			letter-spacing: 0.02em;
		}
		// Large Desktop
		@include mq($from: screen1200) {
			font-size: rem(62);
			line-height: 1.16em;
			letter-spacing: 0.02em;
		}
	}
	// H3
	@else if $type-class == h3 {
		font-family: $font-family;
		font-weight: $font-weight;
		font-size: rem(30);
		line-height: 1.13em;
		letter-spacing: 0.02em;
		// Tablet
		@include mq($from: screen768) {
			font-size: rem(48);
			line-height: 1.13em;
			letter-spacing: 0.02em;
		}
		// Small Desktop
		@include mq($from: screen992) {
			font-size: rem(48);
			line-height: 1.13em;
			letter-spacing: 0.02em;
		}
		// Large Desktop
		@include mq($from: screen1200) {
			font-size: rem(48);
			line-height: 1.13em;
			letter-spacing: 0.02em;
		}
	}
	// H4
	@else if $type-class == h4 {
		font-family: $font-family;
		font-weight: $font-weight;
		font-size: rem(24);
		line-height: 1.17em;
		letter-spacing: 0.02em;
		// Tablet
		@include mq($from: screen768) {
			font-size: rem(36);
			line-height: 1.17em;
			letter-spacing: 0.02em;
		}
		// Small Desktop
		@include mq($from: screen992) {
			font-size: rem(36);
			line-height: 1.17em;
			letter-spacing: 0.02em;
		}
		// Large Desktop
		@include mq($from: screen1200) {
			font-size: rem(36);
			line-height: 1.17em;
			letter-spacing: 0.02em;
		}
	}
	// H5
	@else if $type-class == h5 {
		font-family: $font-family;
		font-weight: $font-weight;
		font-size: rem(20);
		line-height: 1.5em;
		letter-spacing: 0.02em;
		// Tablet
		@include mq($from: screen768) {
			font-size: rem(28);
			line-height: 1.14em;
		}
	}
	// H5
	// H6
	@else if $type-class == h6 {
		font-family: $font-family;
		font-weight: $font-weight;
		font-size: rem(20);
		line-height: 1.5em;
		letter-spacing: 0.02em;
		// Tablet
		@include mq($from: screen768) {
			font-size: rem(24);
			line-height: 1.14em;
		}
	}
	// H6
	@else if $type-class == mega-menu-mobile {
		font-family: $font-family;
		font-weight: $font-weight;
		font-size: 25px;
		line-height: 0.01em;
		// letter-spacing: 0.02em;
		// // Tablet
		// @include mq($from: screen768) {
		// font-size: rem(28);
		// }
	}
	// typo-78
	@else if $type-class == typo-78 {
		font-family: $font-family;
		font-weight: $font-weight;
		font-size: rem(46);
		line-height: 1.13em;
		letter-spacing: -0.01em;
		// Tablet
		@include mq($from: screen768) {
			font-size: rem(78);
			line-height: 1.05em;
			letter-spacing: -0.01em;
		}
		// Small Desktop
		@include mq($from: screen992) {
			font-size: rem(78);
			line-height: 1.05em;
			letter-spacing: -0.01em;
		}
		// Large Desktop
		@include mq($from: screen1200) {
			font-size: rem(78);
			line-height: 1.05em;
			letter-spacing: -0.01em;
		}
	}
	// typo-62
	@else if $type-class == typo-62 {
		font-family: $font-family;
		font-weight: $font-weight;
		font-size: rem(40);
		line-height: 1.1em;
		letter-spacing: -0.01em;
		// Tablet
		@include mq($from: screen768) {
			font-size: rem(62);
			line-height: 1.16em;
			letter-spacing: -0.01em;
		}
		// Small Desktop
		@include mq($from: screen992) {
			font-size: rem(62);
			line-height: 1.16em;
			letter-spacing: -0.01em;
		}
		// Large Desktop
		@include mq($from: screen1200) {
			font-size: rem(62);
			line-height: 1.16em;
			letter-spacing: -0.01em;
		}
	}
	// typo-48
	@else if $type-class == typo-48 {
		font-family: $font-family;
		font-weight: $font-weight;
		font-size: rem(30);
		line-height: 1.13em;
		letter-spacing: -0.01em;
		// Tablet
		@include mq($from: screen768) {
			font-size: rem(48);
			line-height: 1.13em;
			letter-spacing: -0.01em;
		}
		// Small Desktop
		@include mq($from: screen992) {
			font-size: rem(48);
			line-height: 1.13em;
			letter-spacing: -0.01em;
		}
		// Large Desktop
		@include mq($from: screen1200) {
			font-size: rem(48);
			line-height: 1.13em;
			letter-spacing: -0.01em;
		}
	}
	// typo-36
	@else if $type-class == typo-36 {
		font-family: $font-family;
		font-weight: $font-weight;
		font-size: rem(24);
		line-height: 1.17em;
		letter-spacing: -0.01em;
		// Tablet
		@include mq($from: screen768) {
			font-size: rem(36);
			line-height: 1.17em;
			letter-spacing: -0.01em;
		}
		// Small Desktop
		@include mq($from: screen992) {
			font-size: rem(36);
			line-height: 1.17em;
			letter-spacing: -0.01em;
		}
		// Large Desktop
		@include mq($from: screen1200) {
			font-size: rem(36);
			line-height: 1.17em;
			letter-spacing: -0.01em;
		}
	}
	// typo-28
	@else if $type-class == typo-28 {
		font-family: $font-family;
		font-weight: $font-weight;
		font-size: rem(20);
		line-height: 1.5em;
		letter-spacing: -0.01em;
		// Tablet
		@include mq($from: screen768) {
			font-size: rem(28);
			line-height: 1.14em;
			letter-spacing: -0.01em;
		}
		// Small Desktop
		@include mq($from: screen992) {
			font-size: rem(28);
			line-height: 1.14em;
			letter-spacing: -0.01em;
		}
		// Large Desktop
		@include mq($from: screen1200) {
			font-size: rem(28);
			line-height: 1.14em;
			letter-spacing: -0.01em;
		}
	}
	// typo-24
	@else if $type-class == typo-24 {
		font-family: $font-family;
		font-weight: $font-weight;
		font-size: rem(16);
		line-height: 1.5em;
		letter-spacing: 0em;
		// Tablet
		@include mq($from: screen768) {
			font-size: rem(24);
			line-height: 1.2em;
			letter-spacing: 0em;
		}
		// Small Desktop
		@include mq($from: screen992) {
			font-size: rem(24);
			line-height: 1.2em;
			letter-spacing: 0em;
		}
		// Large Desktop
		@include mq($from: screen1200) {
			font-size: rem(24);
			line-height: 1.2em;
			letter-spacing: 0em;
		}
	}
	// typo-20
	@else if $type-class == typo-20 {
		font-family: $font-family;
		font-weight: $font-weight;
		font-size: rem(16);
		line-height: 1.5em;
		letter-spacing: 0.01em;
		// Tablet
		@include mq($from: screen768) {
			font-size: rem(20);
			line-height: 1.5em;
			letter-spacing: 0.01em;
		}
		// Small Desktop
		@include mq($from: screen992) {
			font-size: rem(20);
			line-height: 1.5em;
			letter-spacing: 0.01em;
		}
		// Large Desktop
		@include mq($from: screen1200) {
			font-size: rem(20);
			line-height: 1.5em;
			letter-spacing: 0.01em;
		}
	}
	// typo-18
	@else if $type-class == typo-18 {
		font-family: $font-family;
		font-weight: $font-weight;
		font-size: rem(14);
		line-height: 1.5em;
		letter-spacing: 0.01em;
		// Tablet
		@include mq($from: screen768) {
			font-size: rem(18);
			line-height: 1.5em;
			letter-spacing: 0.01em;
		}
		// Small Desktop
		@include mq($from: screen992) {
			font-size: rem(18);
			line-height: 1.5em;
			letter-spacing: 0.01em;
		}
		// Large Desktop
		@include mq($from: screen1200) {
			font-size: rem(18);
			line-height: 1.5em;
			letter-spacing: 0.01em;
		}
	}
	// typo-16
	@else if $type-class == typo-16 {
		font-family: $font-family;
		font-weight: $font-weight;
		font-size: rem(12);
		line-height: 1.5em;
		letter-spacing: 0.02em;
		// Tablet
		@include mq($from: screen768) {
			font-size: rem(16);
			line-height: 1.5em;
			letter-spacing: 0.02em;
		}
		// Small Desktop
		@include mq($from: screen992) {
			font-size: rem(16);
			line-height: 1.5em;
			letter-spacing: 0.02em;
		}
		// Large Desktop
		@include mq($from: screen1200) {
			font-size: rem(16);
			line-height: 1.5em;
			letter-spacing: 0.02em;
		}
	}
	// typo-14
	@else if $type-class == typo-14 {
		font-family: $font-family;
		font-weight: $font-weight;
		font-size: rem(12);
		line-height: 1.5em;
		letter-spacing: 0.02em;
		// Tablet
		@include mq($from: screen768) {
			font-size: rem(14);
			line-height: 1.21em;
			letter-spacing: 0.02em;
		}
		// Small Desktop
		@include mq($from: screen992) {
			font-size: rem(14);
			line-height: 1.21em;
			letter-spacing: 0.02em;
		}
		// Large Desktop
		@include mq($from: screen1200) {
			font-size: rem(14);
			line-height: 1.21em;
			letter-spacing: 0.02em;
		}
	}
	// typo-13
	@else if $type-class == typo-13 {
		font-family: $font-family;
		font-weight: $font-weight;
		font-size: rem(12);
		line-height: 1.5em;
		letter-spacing: 0.02em;
		// Tablet
		@include mq($from: screen768) {
			font-size: rem(13);
			line-height: 1.38em;
			letter-spacing: 0.02em;
		}
		// Small Desktop
		@include mq($from: screen992) {
			font-size: rem(13);
			line-height: 1.38em;
			letter-spacing: 0.02em;
		}
		// Large Desktop
		@include mq($from: screen1200) {
			font-size: rem(13);
			line-height: 1.38em;
			letter-spacing: 0.02em;
		}
	}
	// Button Large
	@else if $type-class == button-large {
		font-family: $font-family;
		font-weight: $font-weight;
		font-size: 14px;
		letter-spacing: 2px;

		@include mq($from: screen600) {
			font-size: 14px;
			letter-spacing: 2px;
		}
	}
	// Button Medium
	@else if $type-class == button-medium {
		font-family: $font-family;
		font-weight: $font-weight;
		font-size: 13px;
		letter-spacing: 2px;

		@include mq($from: screen600) {
			font-size: 14px;
			letter-spacing: 2px;
		}
	}
	// Button
	@else if $type-class == button {
		font-family: $font-family;
		font-weight: $font-weight;
		font-size: 14px;
		letter-spacing: 2px;

		@include mq($from: screen600) {
			font-size: 14px;
			letter-spacing: 2px;
		}
	}
	// Anchor
	@else if $type-class == anchor {
		font-family: $font-family;
		font-weight: $font-weight;
		font-size: 14px;
		letter-spacing: 0.02em;

		@include mq($from: screen600) {
			font-size: 14px;
			letter-spacing: 0.02em;
		}
	}
	// Anchor
	@else if $type-class == tray-item {
		font-family: $font-family;
		font-weight: $font-weight;
		font-size: 16px !important;
		letter-spacing: 0.02em;

		@include mq($from: screen600) {
			font-size: 16px !important;
			letter-spacing: 0.02em;
		}
	}
	// Menu Item
	@else if $type-class == menu-item {
		font-family: $font-family;
		font-weight: $font-weight;
		font-size: 14px;
		letter-spacing: 0.02em;

		@include mq($from: 600) {
			font-size: 14px;
			letter-spacing: 0.02em;
		}
	}
}
// ==========================================================================================
// Typography declarations
// We adjust the HTML,BODY font sizes to scale typography linearly through use of REM units.
// ==========================================================================================
html,
body {
	text-size-adjust: 100%;
	font-size: px($root-font-size);
}
// ==========================================================================================
// Axa Guidlines styles
// https://design.axa.com/web-guidelines/typography
// ==========================================================================================
.typo-78 {
	@include typography(typo-78, 700, $root-font-family);
}
.typo-62 {
	@include typography(typo-62, 700, $root-font-family);
}
.typo-48 {
	@include typography(typo-48, 700, $root-font-family);
}
.typo-36 {
	@include typography(typo-36, 700, $root-font-family);
}
.typo-28,
h5 {
	@include typography(typo-28, 700, $root-font-family);
}
.typo-24,
h6 {
	@include typography(typo-24, 600, $root-font-family);
}
.typo-20 {
	@include typography(typo-20, 400, $root-font-family);
}
.typo-18 {
	@include typography(typo-18, 400, $root-font-family);
}
.typo-16 {
	@include typography(typo-16, 400, $root-font-family);
}
.typo-14,
small {
	@include typography(typo-14, 400, $root-font-family);
}
.typo-13 {
	@include typography(typo-13, 400, $root-font-family);
}
// ==========================================================================================
// Styles to override defaults
// ==========================================================================================
.typo-78-serif,
h1 {
	@include typography(h1, 700, $root-font-family-serif);
	margin-bottom: 21px; // Tablet
	@include mq($from: screen768) {
		margin-bottom: 28px;
	}
}
.typo-62-serif,
h2 {
	@include typography(h2, 700, $root-font-family-serif);
	margin-bottom: 16px; // Tablet
	@include mq($from: screen768) {
		margin-bottom: 26px;
	}
}
.typo-48-serif,
h3 {
	@include typography(h3, 700, $root-font-family-serif);
	margin-bottom: 13px; // Tablet
	@include mq($from: screen768) {
		margin-bottom: 18px;
	}
}
.typo-36-serif,
h4 {
	@include typography(h4, 700, $root-font-family-serif);
	margin-bottom: 11px; // Tablet
	@include mq($from: screen768) {
		margin-bottom: 17px;
	}
}
.typo-28-serif,
h5 {
	@include typography(h5, 700, $root-font-family-serif);
	margin-bottom: 16px; // Tablet
	@include mq($from: screen768) {
		margin-bottom: 13px;
	}
}

.typo-24-serif,
h6 {
	@include typography(h6, 700, $root-font-family-serif);
	margin-bottom: 16px; // Tablet
	@include mq($from: screen768) {
		margin-bottom: 13px;
	}
}

.typo-18-serif {
	@include typography(typo-18, 700, $root-font-family-serif);
}
p {
	@include typography(typo-18, normal, $root-font-family);
	& + p {
		margin-top: 1.5em;
	}
}
a {
	color: color(axa-blue);
	font-size: inherit;
	&:focus,
	&:hover {
		color: color(dark-filter-axa-blue);
	}
}
button {
	font-family: $root-font-family;
}
ul > li,
ol > li {
	@include typography(typo-18, normal, $root-font-family);
	margin: 5px;
	a {
		text-decoration: none;
	}
}
ul {
	list-style: disc;
	margin-left: 15px;
	margin-bottom: 10px;
	text-align: left;
}
ol {
	list-style: decimal;
	margin-left: 15px;
	margin-bottom: 10px;
	text-align: left;
}
article {
	width: 100%;
	height: auto;
}
blockquote {
	width: 100%;
	height: auto;
	@include typography(typo-20, normal, $root-font-family);
	margin-bottom: 30px;
}
code {
	width: 100%;
	height: auto;
}
pre {
	widows: 100%;
	height: auto;
	@include typography(typo-18, normal, $root-font-family);
}
time {
	@include typography(typo-16, normal, $root-font-family);
}
figure {
	width: 100%;
	height: auto;
}
figcaption {
	@include typography(typo-16, normal, $root-font-family);
}
dl {
	width: 100%;
	height: auto;
}
dt {
	@include typography(typo-18, 600, $root-font-family);
	margin: 10px 10px 0 10px;
}
dd {
	@include typography(typo-16, normal, $root-font-family);
	margin: 0 15px 20px 10px;
}
.typo--bold {
	font-weight: bold !important;
}
.typo--normal {
	font-weight: normal !important;
}
.typo--semibold {
	font-weight: 600 !important;
	&+p {
		margin-top: 0;
	}
}
