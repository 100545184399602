//==========================================================================================
//
//  List Component
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================

.list-component {
    padding: $component-padding-sm;
}

.list-component__container {
    max-width: $standard-max-width;
    margin: 0 auto;
}

.list-component__title{
    text-align:center;
    margin-bottom:30px;
}

.list-component__item-title{
    @include typography(typo-20, 600, $root-font-family);
}

.list-component__list {
    margin: 0;
    padding:0;
    padding-top: 10px;
    padding-bottom: 10px;

    li {
        list-style:none;
        position:relative;
        margin:0;
        padding: 0 10px 20px 35px;
        &:before{
            position: absolute;
        }
    }
}

// Default styling for li
.list-component__list--bullet {

    li:before{
        left:10px;
        top:7px;
        width:8px;
        height:8px;
        border-radius: 50%;
        content:"";
    }
}

//==========================================================================================
// Modifiers
//==========================================================================================
//==========================================================================================
// Decimal list type
// Overrides the default value which is disc for list style types
//==========================================================================================
.list-component__list--number {
    counter-reset: number;

    li:before{
        @include typography(typo-18, 600, $root-font-family);
        background:none;
        content: counter(number) '.'; //add dot after number
        counter-increment: number;
        top:0;
        left:10px;
        width:8px;
        height:8px;
    }
}

//==========================================================================================
// SVG Tick icons
// Similar to the SVG icon list but with a tick on each item
//==========================================================================================
%list-icon-base{
        content:"";
        background-repeat: no-repeat;
        background-position: center center;
        background-color: transparent !important;
        left:5px;
        top:-3px;
        width: 18px;
        height: 27px;
}

$icon-types:("solid-cross","tick");
$icon-colors:("--blue","--black","--white");

@each $icon-type in $icon-types {
    @each $icon-color in $icon-colors {
        .list-component__item--#{$icon-type}#{$icon-color}:before{
            @extend %list-icon-base;
            background-image: url('/assets/images/symbols/#{$icon-type}#{$icon-color}.svg') !important;
        }

        .list-component__list--#{$icon-type}#{$icon-color} li:before{
            @extend %list-icon-base;
            background-image: url('/assets/images/symbols/#{$icon-type}#{$icon-color}.svg');
        }
    }
}

//==========================================================================================
//  Media Queries
//==========================================================================================
@include mq($from: screen768) {
    .list-component__list--bullet {

        li:before{
            top:10px;
        }
    }

    .list-component__list{
        column-count: 2;
        column-gap: 20px;
        column-fill: balance;
    }

    @each $icon-type in $icon-types {
        @each $icon-color in $icon-colors {
            .list-component__item--#{$icon-type}#{$icon-color}:before{
                top: 2px;
            }

            .list-component__list--#{$icon-type}#{$icon-color} li:before{
                top: 2px;
            }
        }
    }
}

@include mq($from: screen960) {
    .list-component {
        padding: $component-padding-lg;
    }
}