//==========================================================================================
//
//  Mixins
//
//==========================================================================================

//==========================================================================================
//  Vertical alignment
//==========================================================================================
@mixin vertically-align($vertical-spacing: 0) {
    position: relative;
    display: block;
    font-size: 0;
    padding: $vertical-spacing 0;

    &:before {
        content: "";
        width: 0;
        display: inline-block;
        vertical-align: middle;
        height: 100%;
    }

    > div {
        position: relative;
        z-index: 2;
        display: inline-block;
        vertical-align: middle;
        width: 100%;
    }
}

//==========================================================================================
//  Vertical centered
//==========================================================================================
@mixin vertically-centered($position: relative, $offset: 0) {
    position: $position;
    top: 50% + $offset;
    transform: translateY(-50% - $offset);
}


//==========================================================================================
//  Inherit styles
//==========================================================================================
@mixin inherit-style() {
    font-size: inherit;
    color: inherit;
    line-height: inherit;
    letter-spacing: inherit;
}


//==========================================================================================
//  Clearfix
//==========================================================================================
@mixin clearfix() {
    *zoom: 1;

    &:before, &:after {
        content: " ";
        display: table;
    }

    &:after {
        clear: both;
    }
}


//==========================================================================================
//  Remove element default appearance
//==========================================================================================
@mixin remove-appearance() {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    text-indent: 0.01px;
    text-overflow: "";
    background-color: transparent;
}


//==========================================================================================
//  Remove element default appearance
//==========================================================================================
@mixin scrollbar($width: 8px, $track: #F0F1F0, $scrollbar: #1C2635, $other: #E6E6E6) {
    scrollbar-base-color: $track;
    scrollbar-face-color: $scrollbar;
    scrollbar-shadow-color: $scrollbar;
    scrollbar-highlight-color: $other;
    scrollbar-3dlight-color: $other;
    scrollbar-darkshadow-color: $scrollbar;
    scrollbar-track-color: $track;
    scrollbar-arrow-color: transparent;

    &::-webkit-scrollbar {
        background-color: $scrollbar;
        width: $width;
        cursor: pointer;
    }

    &::-webkit-scrollbar-track {
        background-color: $track;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $scrollbar;
        border: 1px solid none;
    }
}


//==========================================================================================
//  Aspect ratio
//==========================================================================================
@mixin aspect-ratio($percentual-height) {
    position: relative;
    display: inline-block;
    vertical-align: top;

    &:before {
        padding-top: $percentual-height;
        content: "";
        display: block;
    }
}

//==========================================================================================
//  Shared styles for form elements
//==========================================================================================
@mixin sharedFormStyles() {
    @include typography(typo-16, normal, $root-font-family);
    display:block;
    padding:10px 20px;
    border:1px solid #cccccc;
    width:100%;

    &:hover{
        border: 1px solid color(axa-blue);
    }

    &:focus{
        border: 1px solid color(axa-blue);
    }

    &--error{
        border: 1px solid color(axa-red);
    }
}

@mixin inputs(){
    @include typography(typo-16, normal, $root-font-family);
    display:block;
    margin-bottom:10px;

    input{
        @include typography(typo-16, normal, $root-font-family);
    }
}

//==========================================================================================
//  Placeholder text for inputs
//==========================================================================================
@mixin placeholder {
    &::-webkit-input-placeholder {@content}
    &:-moz-placeholder           {@content}
    &::-moz-placeholder          {@content}
    &:-ms-input-placeholder      {@content}
  }