//==========================================================================================
//
//  Side Content Block
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================
.side-content-block {
    display: block;
    width: 100%;
    height: auto;
    padding: 20px;
    font-family: $root-font-family;
}

.side-content-block__container {
    width: 100%;
    height: auto;
    margin: 0 auto;
    text-align: left;
}

.side-content-block__container img {
    display: none;
}

.side-content-block__btn-wrapper {
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 5px;
    margin-bottom: 10px;
    flex-flow: row wrap;
    align-items: flex-start;
}



.side-content-block__btn-wrapper a.btn {
    margin-left: 0px;
}

.side-content-block__btn {
    min-width: 220px;
    margin: 0px;
}


//==========================================================================================
//  Media Queries
//==========================================================================================
@include mq($from: screen960) {
    .side-content-block {
        padding: 40px;
    }
}

@include mq($from: screen992) {
    .side-content-block__container img {
        display: block;
    }
}
