//==========================================================================================
//
//  Full Width CTA component
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================
.full-width-cta {
    display: block;
    width: 100%;
    height: auto;
    padding: $component-padding-sm;
}

.full-width-cta__container {
    width: 100%;
    height: auto;
    margin: 0 auto;
    text-align: center;
}

.full-width-cta__btn-wrapper {
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 30px;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
}

.full-width-cta__btn {
    min-width:220px;
    margin: $btn-margins;
}


//==========================================================================================
//  Media Queries
//==========================================================================================
@include mq($from: screen768) {

    .full-width-cta__btn-wrapper {
        margin-top: 30px;
    }

    .full-width-cta__container {
        max-width: $content-max-width-960;
    }
}

@include mq($from: screen960) {
    .full-width-cta {
        padding: $component-padding-lg;
    }
}