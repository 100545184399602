//==========================================================================================
//
//  Article Promo Component
//
//==========================================================================================

//==========================================================================================
//  Base Styles
//==========================================================================================
.article-promo {
    margin: 0 auto;
    width: 100%;
    height: auto;
    padding: 0;
    margin-top: 50px;
    margin-bottom: 50px;

    &:before, &:after {
        width: 100%;
        height: 1px;
        background-color: color(axa-silver, 0.65);
        content: "";
        display: block;
    }


    &:before {
        margin-bottom: 40px;
    }

    &:after {
        margin-top: 40px;
        margin-bottom: 15px;
    }

    .article-promo__image {
        width: 100%;
        height: auto;
        margin: 0 auto;
        display: block;
        margin-bottom: 30px;
    }

    .article-promo__title {
        margin-bottom: 10px;
        margin-top: 0;
    }

    .article-promo__text {
        @include typography(typo-16, normal, $root-font-family);
        margin-bottom: 0;
    }
}

.article-promo__container {
    width: 100%;
    height: auto;
    display: block;
    padding: 0 20px;
}

.article-promo__btn-wrapper {
    margin-top: 20px;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-flow: row wrap;
}

.article-promo__btn {
    margin-top: 15px;
}


//==========================================================================================
//  Media Queries
//==========================================================================================
@include mq($from: screen768) {
    // Using Rich text promo modifier to meet specificity and not require importants
    .article-content--promo .article-content__content {
        .article-promo {
            float: right;
            max-width: 350px;
            margin-bottom: 0;
            margin-top: 20px;
            padding: 0 20px;
            margin-left: 20px;

            .article-promo__image {
                width: calc(100% - 40px);
            }

            .article-promo__title {
                padding: 0 20px;
            }

            .article-promo__text {
                padding: 0 20px;
            }
        }

        .article-promo__container  {
            padding: 0;
        }

        .article-promo__btn-wrapper {
            padding: 0 20px;
        }
    }
}

@include mq($from: screen1200) {
    // Using Rich text promo modifier to meet specificity and not require importants
    .article-content--promo .article-content__content {
        .article-promo {
            max-width: 350px;
        }

        .article-promo__content {
            padding: 0;
        }
    }
}
