//==========================================================================================
//
//  Header
//
//==========================================================================================

//==========================================================================================
//  Base Styles
//==========================================================================================
.header {
    width: 100%;
    height: auto;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    min-width: 320px;
}

//==========================================================================================
//  Modifiers
//==========================================================================================
//==========================================================================================
// Name
// Info
//==========================================================================================