//==========================================================================================
//
//  Intro Text Panel
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================
/* Intro */
.intro-text {
    width: 100%;
    height: auto;
    max-width: $content-max-width-960;
    margin: 0 auto;
    padding: $section-hpadding-sm;
}

.intro-text__title {
    text-align: center;
    margin-bottom: 20px;
}

.intro-text__text {
    @include typography(typo-18, normal, $root-font-family);
    text-align: center;
    margin-bottom: 30px;
}

//==========================================================================================
//  Media Queries
//==========================================================================================

@include mq($from: screen768) {
    .intro-text {
        padding: $section-hpadding-lg;
    }
}