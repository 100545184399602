//==========================================================================================
//
//  Accordion Promo
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================

.accordion-promo{
    padding: $component-padding-sm;
    overflow-x: hidden;
}

.accordion-promo__container{
    display: flex;
    flex-direction: column;
    max-width: $standard-max-width;
    margin: 0 auto;

    .accordion-group {
        padding-left: 0;
        padding-right: 0;
    }
}

.accordion-promo__col-large{
    width:100%;
}

.accordion-promo__col-small{
    width:100%;
    margin-top: 20px;
}

// When inside the accordion promo; take off the accordion
// groups' paddings left/right so we don't end up with an
// 80px padding each side.
.accordion-promo{
    .accordion-group__container{
        padding-left: 0;
        padding-right: 0;
    }

    .accordion-group:first-of-type{
        padding-top: 0;
    }
}

//==========================================================================================
//  Media Queries
//==========================================================================================

@include mq($from: screen960) {
    .accordion-promo{
        padding: $component-padding-lg;
    }

    .accordion-promo__container{
        flex-direction: row;
    }

    .accordion-promo__col-large{
        width:70%;
        padding-right:60px;
    }

    .accordion-promo__col-small{
        width:30%;
        padding-top: 70px; //massive padding to sit next to first accordion's title
        margin-top: 0;
    }
}