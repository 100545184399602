//==========================================================================================
//
//  Country Selector
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================
.country-selector {
    display: block;
    width: 100%;
    padding: 30px 0;
    margin:0 auto;
    padding: $component-padding-sm;
}

.country-selector__wrapper{
    max-width:830px;
    margin:0px auto;
    display:flex;
    flex-flow: row wrap;
    align-content: center;
    justify-content: center;
    padding-top: 50px;
    position: relative;
}

.country-selector__image-container {
    width: 100%;
    height: auto;
    display: block;
}

.country-selector__image-container--centred {
    width: 100%;
    height: auto;
    display: flex;
    align-content: center;
}

.country-selector__image{
    width: 100%;
    background-position: center center;
    background-size:cover;
    padding-top:56.25%;
}

.country-selector__content{
    width:100%;
    padding-left:0px;
    margin-top: 30px;
    display: block;
}

.country-selector__container{
    max-width: $standard-max-width;
    text-align: center;
    margin:0px auto;
}

.country-selector-details {
    position: relative;
    overflow: hidden;
}


.country-selector__input-container{
    text-align:center;
    padding:0;
    display:inline-block;
}

.country-selector__input{
    @include typography(typo-28, normal, $root-font-family);
    margin:0 10px;
    padding:10px 10px 5px 10px;
    background-color: transparent;
    // Remove border styling before adding custom styles
    border:0;
    border-bottom:2px solid;
    color: inherit;
    max-width:200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    @include placeholder {
        color: color(axa-grey);
    }

    &:focus{
        border-bottom-color: color(burnt-sienna);
    }
}

.country-selector__suggestions-wrapper {
    position: relative;
}

.country-selector__suggestions {
    position:absolute;
    top:0;
    max-width:200px;
    width:100%;
    text-align:left;
    margin:0 10px;
    z-index:10;
    padding:0 5px;
    background-color: color(plain-white);
    box-shadow: 0 0 4px color(axa-silver);

    &[aria-hidden="true"] {
        visibility: hidden;
    }

    &[aria-hidden="false"] {
        visibility: visible;
    }
}

.country-selector__search-result {
    @include typography(typo-18, normal, $root-font-family);
    background-color:color(plain-white);
    border-bottom:1px solid color(axa-silver);
    color:color(axa-grey);
    cursor:pointer;
    padding:6px 5px;
    margin:0;

    &:last-child{
        border-bottom:none;
    }

    &--selected, &:hover {
        font-weight: 600;
        color:color(axa-blue);
    }
}

.country-selector__error-container, .country-selector__loading-container {
    margin:0 auto;
    position: relative;
    transition:opacity 0.3s ease;
    opacity:0;
    text-align: center;

    &[aria-hidden = "false"] {
        opacity:1;
    }
}

.country-selector__error {
    @include typography(typo-18, normal, $root-font-family);
    position: absolute;
    left:0;
    right:0;
    margin:0 auto;
    padding:1em;
    z-index:2;
    background-color: inherit;
    border-radius: 12px;
    opacity: inherit;
}

.country-selector__loading {
    @include typography(typo-18, normal, $root-font-family);
    position: absolute;
    left:0;
    right:0;
    margin:0 auto;
    z-index:1;
    padding:1em;
    background-color: inherit;
    border-radius: 12px;
    opacity: inherit;
}

.country-selector__icon-container--centred {
    @extend .country-selector__image-container--centred;
    justify-content: center;
}

.country-selector__image--icon{
    width:60%;
}

//==========================================================================================
//  Media Queries
//==========================================================================================
@include mq($from: screen480){
    .country-selector__image-container {
        width: 50%;
    }

    .country-selector__image-container--centred{
        width: 50%;
    }

    .country-selector__image--icon{
        width:50%;
    }
}

@include mq($from: screen600) {
    .country-selector__input-container{
        padding: 0 5px;
    }
}

@include mq($from: screen768) {

    .country-selector__content{
        padding-left:0;
    }

    .country-selector__input-container{
        text-align:center;
        margin-bottom:0;
    }

    .country-selector__content{
        width:50%;
        padding-left:40px;
        margin-top: 0;
    }

    .country-selector__image{
        background-position: center center;
        background-size:cover;
    }

    .country-selector__image--icon{
        width:50%;
    }

    .country-selector__wrapper{
        flex-direction: row;
    }

    .country-selector__input, .country-selector__suggestions{
        max-width:250px;
    }
}

@include mq($from: screen960) {
    .country-selector{
        padding: $component-padding-lg;
    }

    .country-selector__content{
        width:50%;
    }
}