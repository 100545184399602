//==========================================================================================
//
//  Meta Navigation
//
//==========================================================================================

//==========================================================================================
//  Base Styles
//==========================================================================================
.meta-nav {
    width: 100%;
    height: auto;
    background-color: color(mercury);
    position: relative;
    z-index: 1;
    display: block;
}

.meta-nav__wrapper {
    width: 100%;
    height: auto;
    max-width: 1140px;
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 0;
}

.meta-nav__menu {
    width: 100%;
    overflow: auto;
    flex-basis: 100%;
}

.meta-nav__list {
    display: flex;
    width: 100%;
    height: auto;
    flex-flow: row nowrap;
    justify-content: space-between;
    overflow-x: scroll;
    padding-left:0;
    margin: 0;
}

.meta-nav__list-item {
    @include typography(typo-14, 600, $root-font-family);
    display: block;
    text-transform: uppercase;
    white-space: nowrap;
    margin: 0;
    transition: background-color 0.3s ease, color 0.3s ease;
    flex: 1;
    text-align: center;

    &:hover {
        background-color: color(axa-blue);

        a {
            color: color(plain-white);
        }
    }


    a {
        padding: 15px 15px 15px 15px;
        display: block;
        color: color(meta-nav-foreground);
    }
}

.meta-nav-left {
    display: none;
}

.meta-nav-right {
    background-color: color(burnt-sienna);
    margin-right:5px;
    

    a {
        color: color(plain-white);
    }
}

.meta-nav__login-btn {
    background-color: color(axa-blue);

    &:hover {
        
        background-color: color(axa-blue);
        cursor:pointer;
        a {
            color: color(plain-white);
        }
    }


    a {
        color: color(plain-white);
    }
}
    .meta-nav__group {
        display: none;
        flex-flow: row wrap;
        width: auto;
        height: auto;
        flex-basis: 25%;
    }

    .meta-nav__highlight {
        width: auto;
        height: auto;
        display: block;
        padding: 0;
        margin: 0;
    }

    .meta-nav__highlight-item {
        @include typography(typo-14, 600, $root-font-family);
        display: block;
        text-transform: uppercase;
        white-space: nowrap;
        margin: 0;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: white;

            a {
                color: color(dark-grey);
            }
        }

        &:last-child {
            position: relative;

            &:after {
                content: "";
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 1px;
                background-color: color(mercury);
                height: 50%;
                right: 7px;
            }
        }

        a {
            padding: 15px 25px 15px 25px;
            display: block;
            color: color(meta-nav-foreground);
        }
    }

    .meta-nav__language {
        align-self: flex-end;
        min-width: 45px;
        padding: 0;
        display: flex;
        position: relative;

        &:after {
            content: "";
            display: block;
            width: 12px;
            height: 18px;
            background-image: url("/assets/images/svg/arrow-select-down.svg");
            background-size: 12px 18px;
            background-position: center;
            background-repeat: no-repeat;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 10px;
            transition: transform 0.3s ease;
            cursor: pointer;
        }
    }

    .meta-nav__language-trigger {
        @include typography(typo-14, 600, $root-font-family);
        color: color(light-grey);
        width: 100%;
        height: auto;
        border-style: none;
        display: inline-block;
        vertical-align: middle;
        text-transform: uppercase;
        padding: 15px 25px 15px 10px;
        cursor: pointer;
        background: transparent;
        min-width: 0;
        margin: 0;

        &:hover, &:focus {
            color: color(light-grey);
        }

        &:after {
            display: none;
        }
    }

    .meta-nav__language-list {
        position: absolute;
        top: 44px;
        left: 0;
        right: 0;
        min-width: 60px;
        background-color: color(mercury);
        padding: 0;
        margin: auto;
        list-style: none;
        box-shadow: 0 3px 10px -2px rgba(0,0,0,.3);
        overflow: hidden;
        max-height: 0;
        transition: max-height 1.2s ease-in-out, visibility 0s ease-in-out;
    }

    .meta-nav__language-item {
        @include typography(typo-14, 600, $root-font-family);

        a {
            color: color(light-grey);
            padding: 5px 10px 5px 5px;
            display: block;
        }

        &:focus, &:hover {
            a {
                color: color(axa-blue);
            }
        }
    }
    /* active langauge item */
    [data-active].meta-nav__language-item {
        a {
            color: color(axa-blue);
        }
    }
    /* Closed State */
    [aria-hidden="true"].meta-nav__language {
        &:after {
            transform: translateY(-50%) rotate(0);
        }

        .meta-nav__language-list {
            max-height: 0;
            visibility: hidden;
            transition: max-height 0.8s cubic-bezier(0, 1, 0.3, 1), visibility 0s ease-in-out 0.8s; //faster transition on the close
        }
    }
    /* Open State */
    [aria-hidden="false"].meta-nav__language {
        &:after {
            transform: translateY(-50%) rotate(180deg);
        }

        .meta-nav__language-list {
            max-height: 9999px;
            visibility: visible;
        }
    }

@include mq($from: screen480) {

    .meta-nav__list {
        justify-content: flex-end;
    }

    .meta-nav__list-item {
        flex: none;
    }

    .meta-nav-left {
        margin-right: auto;
        display: flex;
    }

    .meta-nav__menu {
        flex-basis: 100%;
    }
}
    //==========================================================================================
    //  Modifiers
    //==========================================================================================
    //==========================================================================================
    // Active menu item
    // Adds a style for the active menu item
    //==========================================================================================
.meta-nav__list-item--active {
    background-color: color(plain-white);

    a {
        color: color(meta-nav-foreground);
    }
}
    //==========================================================================================
    //  Overflow Scroll
    //==========================================================================================
    @include mq($from: screen1140) {

        .meta-nav__list {
            overflow: auto;
        }

        .meta-nav__list-item {
            white-space: normal; // this is needed to fix a bug where height was affected on desktop - sill need nowrap on mobile so that nav items don't wrap when we get the scrollbar
        }


        .meta-nav__group {
            display: flex;
            justify-content: flex-end;
        }

        .meta-nav__wrapper {
            padding: 0 10px 0 25px;
        }
    }