//==========================================================================================
//
//  Text video panel
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================
.text-video-panel {
    display:block;
    margin: 0 auto;
    width:100%;
    padding: $component-padding-sm;
}

.text-video-panel__row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: $standard-max-width;
}

.text-video-panel__col {
    width:100%;
}

.text-video-panel__about {
    padding-bottom:25px;
}

.text-video-panel__video-wrapper {
    position: relative;
    // padding-top: 35px;
    height: auto;
    overflow: hidden;

    &:after {
        padding-top: 56.25%;
        content: "";
        display: block;
    }
}

.text-video-panel__iframe {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
}

//==========================================================================================
//  Media Queries
//==========================================================================================
@include mq($from: screen768) {
    .text-video-panel__row {
        flex-direction: row;
        max-width: $standard-max-width;
        margin: 0 auto;
    }

    .text-video-panel__about {
        padding-right:60px;
    }

    .text-video-panel__about-title {
        margin-bottom:25px;
    }

    .text-video-panel {
        display:block;
    }
}

@include mq($from: screen960) {
    .text-video-panel {
        padding: $component-padding-lg;
    }
}
