//==========================================================================================
//
//  Block panels with text CTA
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================
.magazine-block-panel {
    width: 100%;
    margin: 0 auto;
    height: auto;
    display: block;
    overflow: hidden;
    padding: $component-padding-sm;
    background-position: center;
    background-size: cover;
}

.magazine-block-panel__wrapper {
    width: 100%;
    display: block;
    overflow: hidden;
    margin-top: 50px;
}

.magazine-block-panel__container {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;

    &:after {
        content: "";
        display: block;
        clear: both;
    }
}

.magazine-block-panel__intro {
    text-align: center;
}

.magazine-block-panel__row {
    max-width:$standard-max-width;
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;

    &:after {
        content: "";
        display: block;
        clear: both;
    }
}

.magazine-block-panel__panel {
    width: 100%;
    padding: 0;
    position: relative;
    padding-bottom: 20px;
    border: 1px color(axa-silver) solid;
}

.magazine-block-panel__panel a {
    text-decoration: none;
}


.magazine-block-panel__panel-content{
    padding-left:20px;
    padding-right:20px;
}

.magazine-block-panel__panel-link {
    @include typography(anchor, bold, $root-font-family);
    display: inline-block;
    text-decoration: none;
    transition: color 0.3s;
    text-transform: uppercase;
   
}

.magazine-block-panel__panel-title {
    margin: 25px 0 10px 0;
}

.magazine-block-panel__panel-desc {
    margin-bottom:15px;
}

.magazine-block-panel__show-cta{
    display:block;
    margin-top:50px;
    width:100%;
    text-align:center;
}

.magazine-block-panel__pagination-container {
    margin-top: 30px;
}

.magazine-block-panel__pagination-item {
    width: 100%;
    display: block;

    &:first-child, &:last-child {
        display: none;
    }
}

.magazine-block-panel__panel-image {
    margin-bottom:20px;
}

//==========================================================================================
//  Media Queries
//==========================================================================================

@include mq($from: screen600) {
    .magazine-block-panel__show-mobile{
        display:none;
    }

    .magazine-block-panel__container {
        overflow: visible !important;
    }
}

@include mq($from: screen960) {
    .magazine-block-panel {
        padding: $component-padding-lg;
    }

    .magazine-block-panel__panel {
        width: 30%;
        margin-bottom:40px;

        &:nth-child(3n + 1) {
            margin-left: 0;
            margin-right: 20px;
        }

        &:nth-child(3n + 2) {
            margin-left: 10px;
            margin-right: 10px;
        }

        &:nth-child(3n + 3) {
            margin-left: 20px;
            margin-right: 0;
        }
    }



    .magazine-block-panel__container {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        margin: 0 auto;
        align-items: start;
    }

    .magazine-block-panel {
        .magazine-block-panel__pagination-container {
            display: none;
        }
    }
}

@include mq($from: screen1200) {

    .magazine-block-panel__container {
        max-width: $standard-max-width;
    }

}
