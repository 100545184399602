//==========================================================================================
//
//  Split Image Text Panel
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================
.split-text-panel {
    overflow-x: hidden;
}

.split-image-text-panel__wrapper { // This is what wraps the text and split images
    padding: $component-padding-sm;

    .split-image-text-panel {
        padding: 30px 0;

        &:last-child{
            padding-bottom: 0;
        }
    }
}

.split-image-text-panel {
    width: 100%;
    height: auto;
    display: block;
    padding: $component-padding-sm;
    overflow: hidden;
}

.split-image-text-panel__container {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: row wrap;
}

/* Panels */
.split-image-text-panel__image-container {
    width: 100%;
    height: auto;
}

.split-image-text-panel__image {
    width: 100%;
    padding-right: 20px;
    /* Height 0 set for aspect ratio effect */
    height: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: block;
    padding-top: 56.25%;
    margin-bottom: 20px;
}

.split-image-text-panel__content {
    position: relative;
    width: 100%;
    padding: 0;
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    align-items: center;
    h1,h2,h3,h4,h5,h6 {
        width: 100%;
    }
}

.split-image-text-panel__content-container {
    width: 100%; // IE11 fix
}

.split-image-text-panel__btn-wrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin-top: 30px;
    margin-left: -$btn-margin;

    .anchor--btn,
    .anchor {
        margin: $btn-margin;
    }
}


//==========================================================================================
//  Modifiers
//==========================================================================================
//==========================================================================================
// Flip
// Re-Orders the image to show a flipped style
//==========================================================================================
.split-image-text-panel--flip {
    .split-image-text-panel__image {
        padding-left: 20px;
        padding-right: 0;
    }
    .split-image-text-panel__content {
        order: 1;
    }
}

//==========================================================================================
// Square
// Styles the image to be square
//==========================================================================================
.split-image-text-panel--square {

    .split-image-text-panel__image-wrapper {
        width: 100%;
    }

    .split-image-text-panel__image {
        max-height: none;
        height: 0;
        padding-top: 100%;

        &:after {
            content: "";
            display: block;
            padding-bottom: 100%;
        }
    }

}

//==========================================================================================
// Square
// Styles the image to be square
//==========================================================================================
.split-image-text-panel--square-flip {
    /* Styles are in the media queries */
}

//==========================================================================================
//  Background image with icon
//  Used for country picker
//==========================================================================================
.split-image-text-panel__image--icon {
    background-size:50%;
    background-position:center center;
}

//==========================================================================================
//  Media Queries
//==========================================================================================
@include mq($from: screen768) {
    .split-image-text-panel__content {
        padding-top:30px;
    }
}

@include mq($from: screen960) {
    .split-image-text-panel__wrapper {
        padding: $component-padding-lg;
    }

    .split-image-text-panel {
        padding: $component-padding-lg;
    }
}

@include mq($from: screen1000) {
    .split-image-text-panel__container {
        max-width: $standard-max-width;
        margin: 0 auto;
    }

    .split-image-text-panel__image-container {
        width: 50%;
    }

    .split-image-text-panel__image {
        width: 100%;
    }

    .split-image-text-panel__content {
        width: 50%;
        padding-left: 60px;
        padding-right: 0;
        padding-top:0;
    }

    /* Flip Modifier */
    .split-image-text-panel--flip {
        .split-image-text-panel__content {
            order: -1;
            padding-left: 0;
            padding-right: 60px;
        }
    }

    /* Square Modifier */
    .split-image-text-panel__wrapper {
        .intro-text {
            margin-bottom: 40px;
        }

        .split-image-text-panel {
            padding-top: 0;
            padding-bottom: 60px;
        }

        .split-image-text-panel--square {
            padding: 0;
        }
    }

    .split-image-text-panel--square {

        .split-image-text-panel__image-container {
            width: 50%;

            .split-image-text-panel__image {
                    width: 100%;
                    max-height: none;
                    height: auto;
                    padding: 0;
                    min-height: none;

                    &:after {
                        content: "";
                        display: block;
                        padding-bottom: 100%;
                    }
                }
        }

        .split-image-text-panel__content {
            width: 50%;
            padding-left: 50px;
            padding-right: 0;
        }
    }

    /* Square Modifier Flip */
    .split-image-text-panel--square-flip {
        .split-image-text-panel__content {
            padding-left: 0;
            padding-right: 60px;
            order: -1;
        }

        .split-image-text-panel__image--icon {
            background-size:35%;
            background-position: 90% center;
        }
    }

    .split-image-text-panel__image {
        margin-bottom: 0;
    }

}
