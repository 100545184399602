//==========================================================================================
//
//  Press Releases Date Filter
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================
.date-filter__items{
    overflow-x: auto;
    display: flex;
    max-width: 100%;
    padding: 0;
    margin: 0;
    border-bottom: 1px solid color(mercury);
    border-top: 1px solid color(mercury);
}

.date-filter__item{
    margin: 0;
    list-style: none;
    display: flex;
}

.date-filter__link{
    @include typography(typo-13, 700, $root-font-family);
    color: color(light-grey);
    padding: 15px;
    text-align: center;
    border-bottom: 2px solid transparent;
    text-transform: uppercase;
    letter-spacing: 2px !important;

    &:hover, &:focus{
        color: color(burnt-sienna);
        border-bottom: 2px solid color(burnt-sienna);
    }
}

.date-filter__link[aria-current]{
    color: color(burnt-sienna);
    border-bottom: 2px solid color(burnt-sienna);
}

.date-filter__link[aria-disabled="true"]{
    color: color(mercury);
    pointer-events: none;
}