//==========================================================================================
//  Icon Link Component
//  Icon Link List Component
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================

.multi-list-block__header-bar {
  width: 100%;
  height: 100px;
  margin-bottom: -50px;
}

.multi-list-block {
    max-width: $standard-max-width;
    width: 100%;
    display: flex;
    margin: 0 auto;
    overflow: hidden;
    padding: 0;
}


.multi-list-block__wrapper {
  width: 100%;
  margin: 0;
  padding: 0;
}

.multi-list-block__item {
    display: block;
    width: 20%;
    padding: 0px 0px 35px 0px;
    margin: 0;
    text-decoration: none;

    :hover {
        cursor: pointer;
    }

    p {
        color: #3032c1;
        font-weight: 700;
    }
}

.multi-list__container {
  width: 100%;
  margin: 0 auto;
  height: auto;
  display: block;
  position: relative;
  max-width: $standard-max-width;
}

.hidden {
  display: none;
}

.multi-list__item-image {
  display: block;
  margin: 0 auto 20px auto;
  width: 100px;
  height: 100px;
}

//==========================================================================================
//  Media Queries
//==========================================================================================

@include mq($from: screen600) {

  .multi-list__container {
    overflow: visible !important;
  }
}

@include mq($from: screen960) {
  .multi-list__container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: stretch;

    &--distributed {
      justify-content: unset;
    }
    &--narrow {
      margin: 0;
      max-width: 1200px;
    }
  }
}

@media only screen and (max-width: 960px) {
  .multi-list-block__header-bar {
    width: 90px;
    height: 100%;
    position: absolute;
  }

  .multi-list-block__item {
    width: 100%;
    text-align: left;
    display: flex;
    padding: 30px 30px 0px 40px;
    z-index: 999;
    align-items: center;
    position: relative;

    img {
      margin-right: 30px;
    }

    p {
      text-align: left;
    }
  }

  .multi-list__item-image {
    margin: 20px auto 20px auto;
  }

  .multi-list-block__item p {
    font-size: 20px;
  }
}
