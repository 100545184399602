//==========================================================================================
//
//  Rich Text
//
//==========================================================================================
//==========================================================================================
//  Base Styles
//==========================================================================================
.article-content {
    width: 100%;
    height: auto;
    clear: both;
    position: relative;
    overflow: hidden;

    &:after {
        clear: both;
        content: "";
        display: block;
    }
    &+&,
    &+.article-content__container {
        margin-top: 1.5em;
    }
    &+.full-width-cta {
        margin-top: 2rem;
    }
}

.article-content__container {
    width: 100%;
    height: auto;
    max-width: 100%;
    position: relative;
    display: flex;
}

.article-content__content {
    width: 100%;
    height: auto;
    display: block;
    max-width: 100%;
    padding: 0 40px;
}
.article-content {
    .article-content__intro {
        font-weight: bold;
        margin-bottom: 50px;
    }
}

.article-content {

    ul, ol{
        margin: 0 auto 40px 40px;
        padding: 0;
    }
}
//==========================================================================================
//  Modifiers
//==========================================================================================
//==========================================================================================
// Rich Text Promo
// Displays a promo component alonside a ruche text component
//==========================================================================================
.article-content--promo {

    .article-content__content {
        max-width: none;
        padding: 0 40px;
        width: 100%;

    }



    .article-promo {
        a {
            @include typography(anchor, 600, $root-font-family);
            text-decoration: none;

        }
    }
}


//==========================================================================================
//  Media Queries
//==========================================================================================
@include mq($from: screen768) {
    .article-content {

        .article-content__container {
            padding: 0 40px;
            &+& {
                margin-top: 16px;
            }
        }

        .article-content__content {
            max-width: $content-max-width-768;
            padding: 0 40px;
            margin: 0 auto;
        }

    }

    .article-content--promo {

        .article-content__content {
            padding: 0;
        }

        .article-content__content > * {
            max-width: $content-max-width-768;
            padding: 0 40px;
            margin-left: auto;
            margin-right: auto;
        }

    }
}

@include mq($from: screen960) {

    .article-content__container {
        padding: 0;
        max-width: $standard-max-width;
        margin: 0 auto;
    }

    .article-content--promo {

        .article-content__content {
            max-width: none !important;
            padding: 0 0;

        }
    }
}

@include mq($from: screen1200) {
    .article-content {

        .article-content__content {
            max-width: $content-max-width-768;
            padding: 0 40px;
            margin: 0 auto;
        }

    }

    .article-content--promo {
        .article-content__content > * {
            max-width: $content-max-width-768;
            padding: 0 40px;
            margin-left: auto;
            margin-right: auto;
        }
    }

}